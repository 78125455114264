import { FC } from 'react'
import { useSelector } from 'react-redux'
import { cn } from '@bem-react/classname'
import { classnames as cx } from '@bem-react/classnames'

import { selectSubject } from 'modules/Subject'
import Link from 'components/Link'
import Fallback from 'components/Fallback'

import SubjectImage from './SubjectImage'
import ExamTitle from './ExamTitle'
import Profile from './Profile'
import './Header.scss'

import websiteLogo from 'components/Spinner/assets/websiteLogo.svg'
import { getDomain } from '../../../../utils'
import { selectorIsUAExam } from 'modules/Exam/selectors'
import ExamTitleUA from './ExamTitleUA'
import DarkSwitcher from '../DarkSwitcher/DarkSwitcher'

interface IHeaderProps {
  className?: string
}

export const cnHeader = cn('Header')

const Header: FC<IHeaderProps> = ({ className }) => {
  const subject = useSelector(selectSubject)
  const domain = useSelector(getDomain)
  const isUAExam = useSelector(selectorIsUAExam)
  let isMNT = domain.includes('nmt')
  let isZNO = domain.includes('zno')
  let isOlymp = domain.includes('olymp')
  let siteIsDev = false; //(domain.includes('urok') && !domain.includes('exam') && !domain.includes('physics'))

  const headerText = () => {
    if (isMNT) {
      return `Сайт для підготовки до національного мультипредметного тестування з математики`
    } else if (isZNO) {
      return `Освітній портал для підготовки до зовнішнього незалежного оцінювання`
    } else if (isOlymp) {
      return `Образовательный портал для подготовки к олимпиадам`
    } else {
      return `Образовательный портал для подготовки к экзаменам`
    }
  }


  return (
    <header className={cx(cnHeader(), className)}>
      <div className={cnHeader('Main')}>
        <Link className={cnHeader('LogoLink')} activeClassName={cnHeader('LogoLink')} to="/" exact>
            <img
            className={cnHeader('Logo')}
            src={websiteLogo}
            alt="Логотип Сдам ГИА"
          />  
          
        </Link>

        <div className={cnHeader('Info')}>
          <div className={cnHeader('WebsiteInfo')}>
            {isUAExam ? <ExamTitleUA/> : <h1 className={cnHeader('Title')}>
              <span className={cnHeader('SystemName')}>
                <Link
                  className={cx('Link_black', cnHeader('Link'))}
                  href="https://sdamgia.ru"
                >
                  СДАМ ГИА: 
                </Link>
              </span>&ensp;

              <ExamTitle />
            </h1>}

            <div className={cnHeader('WebsiteDesc')}>
              {headerText()}
            </div>
          </div>

          {subject ? (
            <div className={cnHeader('SubjectTitle')}>{!isMNT && !siteIsDev && subject.title} {siteIsDev && 'Портал в разработке'}
            {subject.title_extra?<div style={{fontWeight: 400, fontSize: ".75rem", verticalAlign: "top", color: "#f60", marginTop: 0, lineHeight: "14px", display: "inline-block"}}>{subject.title_extra}</div>:""}</div>
          ) : (
            <Fallback
              className={cnHeader('SubjectTitle', {
                fallback: true,
              })}
            />
          )}
        </div>
      </div>

      <SubjectImage className={cnHeader('SubjectImage')} />

      <div className={cnHeader('IsMobile')}>
        <DarkSwitcher/>
      
        <Profile />
      </div>
    </header>
  )
}

export default Header
