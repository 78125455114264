import React, {
  useCallback,
  useRef,
  Dispatch,
  SetStateAction,
  RefObject,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { cn } from '@bem-react/classname'

import { shake } from 'utils'
import { fetchTestConstructor } from 'modules/TestConstructor'

import { ITopic } from 'components/ConstructorForm/Buttons'
import { ITestTotal } from 'components/ConstructorForm/ConstructorForm'
import { saveTopicsList } from 'components/ConstructorForm/ConstructorForm.utils'
import Input from 'components/Input'

import {
  checkListOnFilledPart,
  updateTopicValue,
  updateTestTotal,
} from './helpers'
import './Counter.scss'
import { selectorIsUAExam } from '../../modules/Exam'

interface ICounter {
  className?: string
  page: string
  name: string
  value: number
  list: ITopic[] | null
  index: number
  subtopicIndex?: number
  testTotal: ITestTotal
  setTestTotal: Dispatch<SetStateAction<ITestTotal>>
  part: string
  switchOnPart: (part: string) => void
  switchOffPart: (part: string) => void
}

const useCounter = (
  page: string,
  index: number,
  subtopicIndex: number | undefined,
  value: number,
  list: ITopic[] | null,
  testTotal: ITestTotal,
  setTestTotal: Dispatch<SetStateAction<ITestTotal>>,
  part: string,
  switchOnPart: (part: string) => void,
  switchOffPart: (part: string) => void,
  countInput: RefObject<HTMLInputElement | null>
) => {
  const dispatch = useDispatch()
  const isUAExam = useSelector(selectorIsUAExam)

  const handleCounterInputFocus = useCallback(() => {
    if (countInput && countInput.current) {
      countInput.current.setSelectionRange(0, 9999)
    }
  }, [countInput])

  const handleCounterInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let { value: newValue } = e.target
      if (newValue === '') {
        newValue = '0'
      }

      if (/\D/.test(newValue) || parseInt(newValue) > 999) {
        return shake(countInput)
      }
      if (!list) return

      const buildNewValue = () => parseInt(newValue)
      updateTopicValue({
        list,
        index,
        value,
        buildNewValue,
        subtopicIndex,
      })

      dispatch(fetchTestConstructor(list))
      saveTopicsList(page, list)

      const newTestTotalAmount = testTotal.amount + (parseInt(newValue)- value)
      const newTestTotal = updateTestTotal(page, newTestTotalAmount, isUAExam)
      setTestTotal(newTestTotal)

      if (Number(newValue)) {
        if (checkListOnFilledPart(list, part)) {
          switchOnPart(part)
        }
      } else {
        switchOffPart(part)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      list,
      index,
      value,
      subtopicIndex,
      page,
      testTotal.amount,
      countInput,
      part,
    ]
  )

  const decrement = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault()
      if (value <= 0 || !list) return

      const buildNewValue = (value: number) => value - 1
      const newValue = updateTopicValue({
        list,
        index,
        value,
        buildNewValue,
        subtopicIndex,
      })

      dispatch(fetchTestConstructor(list))
      saveTopicsList(page, list)

      const newTestTotalAmount = testTotal.amount - 1
      const newTestTotal = updateTestTotal(page, newTestTotalAmount, isUAExam)
      setTestTotal(newTestTotal)

      if (!newValue) switchOffPart(part)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value, list, index, subtopicIndex, page, testTotal.amount, part]
  )
  const increment = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault()
      if (!list) return

      const buildNewValue = (value: number) => value + 1
      updateTopicValue({ list, index, value, buildNewValue, subtopicIndex })
      dispatch(fetchTestConstructor(list))
      saveTopicsList(page, list)

      const newTestTotalAmount = testTotal.amount + 1
      const newTestTotal = updateTestTotal(page, newTestTotalAmount, isUAExam)
      setTestTotal(newTestTotal)

      if (checkListOnFilledPart(list, part)) {
        switchOnPart(part)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [list, index, value, subtopicIndex, page, testTotal.amount, part]
  )

  return {
    handleCounterInputFocus,
    handleCounterInputChange,
    increment,
    decrement,
  }
}

const Counter = ({
  className,
  page,
  name,
  index,
  subtopicIndex,
  value,
  list,
  testTotal,
  setTestTotal,
  part,
  switchOnPart,
  switchOffPart,
}: ICounter) => {
  const counter = cn('Counter')
  const countInput = useRef<HTMLInputElement>(null)
  const {
    handleCounterInputFocus,
    handleCounterInputChange,
    increment,
    decrement,
  } = useCounter(
    page,
    index,
    subtopicIndex,
    value,
    list,
    testTotal,
    setTestTotal,
    part,
    switchOnPart,
    switchOffPart,
    countInput
  )

  return (
    <div className={counter(null, [className])}>
      <button
        className={counter('Button', { disabled: value < 1 })}
        onClick={decrement}
      >
        −
      </button>

      <Input
        className={counter('Input')}
        type="tel"
        name={name}
        value={value.toString()}
        tabIndex={index + 1 || undefined}
        innerRef={countInput}
        handleClick={handleCounterInputFocus}
        handleChange={handleCounterInputChange}
      />

      <button className={counter('Button')} onClick={increment}>
        +
      </button>
      <span className={counter('Caption')}>шт.</span>
    </div>
  )
}

export default Counter
