export const COLOR_ALL_TEST_STAT = '#A4FFA4'
export const COLOR_TEACHER_TEST_STAT = '#AFAFF9'
export const COLOR_TEACHER_TEST_WITHOUT_LAST_MONTH = '#7cb5ec'

const STORE_KEY = 'INDIVIDUAL_PROFILE'

export const FETCH_STAT = `${STORE_KEY}/FETCH_STAT`

export const SET_STAT_DATA = `${STORE_KEY}/SET_STAT_DATA`

export const SET_IS_ALL_TEST_STAT = `${STORE_KEY}/SET_IS_ALL_TEST_STAT`
export const SET_IS_TEACHER_TEST_STAT = `${STORE_KEY}/SET_IS_TEACHER_TEST_STAT`
export const SET_IS_TEACHER_TEST_STAT_WITHOUT_LAST_MONTH = `${STORE_KEY}/SET_IS_TEACHER_TEST_STAT_WITHOUT_LAST_MONTH`
