const STORE_KEY = 'COMMENTS'

export const FETCH_COMMENTS = `${STORE_KEY}/FETCH_COMMENTS`
export const FETCH_NEW_COMMENTS = `${STORE_KEY}/FETCH_NEW_COMMENTS`
export const CLEAR_COMMENTS = `${STORE_KEY}/CLEAR_COMMENTS`

export const SET_COMMENTS_DATA = `${STORE_KEY}/SET_COMMENTS_DATA`
export const ADD_COMMENTS_DATA = `${STORE_KEY}/ADD_COMMENTS_DATA`

export const SET_COMMENTS_COUNT = `${STORE_KEY}/SET_COMMENTS_COUNT`
export const SET_COMMENTS_COUNT_ON_FIRST_PAGE = `${STORE_KEY}/SET_COMMENTS_COUNT_ON_FIRST_PAGE`

export const SET_COMMENTS_RIGHT_PAGE = `${STORE_KEY}/SET_COMMENTS_RIGHT_PAGE`

export const SET_COMMENTS_PREVIOUS_PAGE_POSITION_MAP = `${STORE_KEY}/SET_COMMENTS_PREVIOUS_PAGE_POSITION_MAP`
export const SET_COMMENTS_CURRENT_PAGE_POSITION = `${STORE_KEY}/SET_COMMENTS_CURRENT_PAGE_POSITION`
export const SET_COMMENTS_NEXT_PAGE_POSITION = `${STORE_KEY}/SET_COMMENTS_NEXT_PAGE_POSITION`

export const SET_COMMENTS_TOTAL_PAGES = `${STORE_KEY}/SET_COMMENTS_TOTAL_PAGES`

export const ADD_COMMENTS_FETCHED_PAGE = `${STORE_KEY}/ADD_COMMENTS_FETCHED_PAGE`
