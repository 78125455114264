import { cn } from '@bem-react/classname'

export const cnDrawer = cn('Drawer')
export const cnDrawerOverlay = cnDrawer('Overlay')
export const cnDrawerContent = cnDrawer('Content')
export const cnDrawerInnerContent = cnDrawer('InnerContent')
export const cnDrawerTitle = cnDrawer('Title')
export const cnDrawerHandle = cnDrawer('Handle')
export const cnDrawerCurtain = cnDrawer('Curtain')
export const cnDrawerDragObserver = cnDrawer('DragObserver')

export const DefaultAnimation = {
  tension: 230,
  friction: 24,
  disabled: false,
  dragImmediate: false,
}
