import { useState, useCallback } from 'react'

export const useHandleToggle = () => {
  const [saved, isSaved] = useState(false)

  const handleToggle = useCallback(() => {
    isSaved(!saved)
  }, [saved])

  return { saved, handleToggle }
}
