/**
 * Возвращает из урла хеш без решетки
 */
export const getCommentIdFromUrl = (): number | null => {
  let hash = null
  const hashWithSharp = window.location.hash

  if (hashWithSharp) {
    hash = Number(hashWithSharp.substring(1))
  }

  return hash
}
