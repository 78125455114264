import React, { FC, ReactNode, KeyboardEvent, MouseEvent } from 'react'
import { compose } from '@bem-react/core'
import { cn } from '@bem-react/classname'

import { getRandomInt } from 'utils'
import TitleBase, { withLevel2 } from 'components/Title'
import CloseButton from 'components/CloseButton'

import { useOverlay } from './Modal.hooks'
import './Modal.scss'

export type OnClose = (event: KeyboardEvent | MouseEvent | unknown) => void

const Title = compose(withLevel2)(TitleBase)

interface IModalProps {
  className?: string
  title?: string
  contentVerticalAlign?: 'middle'
  hasAnimation?: boolean
  visible?: boolean
  loading?: boolean
  children: ReactNode
  onClose?: OnClose
}

const cnModal = cn('Modal')

/**
 * Используется для создания всплывающих модальных окон
 * @param {IModalProps} props
 */
const Modal: FC<IModalProps> = ({
  className,
  title,
  contentVerticalAlign: align = 'middle',
  hasAnimation = true,
  visible,
  loading,
  children,
  onClose,
}) => {
  const labelI = getRandomInt()
  const labelId = cnModal(`Title${labelI}`)

  useOverlay(onClose!)

  return (
    <div
      className={cnModal(
        {
          visible,
          theme: 'normal',
          hasAnimation,
          loading,
        },
        [className]
      )}
    >
      <div className={cnModal('Table')}>
        <div className={cnModal('Cell', { align })}>
          <div
            className={cnModal('Content')}
            role="dialog"
            aria-modal
            aria-labelledby={labelId}
          >
            <header className={cnModal('Header')}>
              <Title className={cnModal('Title')} id={labelId} level="2">
                {title}
              </Title>
              <CloseButton
                className={cnModal('HeaderButton')}
                onClick={onClose}
              />
            </header>
            <main className={cnModal('Main')}>{children}</main>
          </div>
        </div>
      </div>

      <div className={cnModal('Overlay')} onClick={onClose!} />
    </div>
  )
}

export default Modal
