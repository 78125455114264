import { ISubjectType } from 'utils/data/subjectList'

export const isSubjectActive = (
  location?: string,
  name?: string,
  types?: Array<ISubjectType>
) => {
  if (!name && types) {
    return types.some((item) => item.name === location)
  }

  return name === location
}
