import { withBemMod } from '@bem-react/core'

import { cnButton } from '../Button'
import './Button_view_secondary.scss'

export interface IButtonViewSecondaryProps {
  /**
   * Внешний вид кнопки
   */
  view?: 'secondary'
}

/**
 * Модификатор, отвечающий за внешний вид кнопки
 * @param {IButtonViewSecondaryProps} props
 */
export const withViewSecondary = withBemMod<IButtonViewSecondaryProps>(
  cnButton(),
  {
    view: 'secondary',
  }
)
