import React, { FC, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { cn } from '@bem-react/classname'
import { compose } from '@bem-react/core'

import { selectLarinVariants } from 'modules/LarinVariants'
import TitleBase, { withLevel2 } from 'components/Title'
import Text from 'components/Text'
import Section from 'components/Section'
import Grid from 'components/Grid'
import VariantLink from 'components/VariantLink'
import LinkWrap from 'components/Link/_wrap'
import 'components/Link/_pseudo'
import 'components/VariantBox/VariantBox.scss'
import './LarinVariants.scss'

const Title = compose(withLevel2)(TitleBase)

export interface ILarinVariant {
  id: number
  number: number
}

const LarinVariants: FC = () => {
  const cnLarinVariants = cn('LarinVariants')
  const larinVariants = useSelector(selectLarinVariants)
  const [variantsAll, areVariantsAll] = useState(false)

  const toggleVariantsAll = useCallback(() => {
    areVariantsAll(!variantsAll)
  }, [variantsAll])

  return (
    <Section className={cnLarinVariants()}>
      <Title level="2">
        <span className={cnLarinVariants('TitleWord', { order: 1 })}>
          Все варианты{' '}
        </span>
        <span className={cnLarinVariants('TitleWord', { mobile: true })}>
          А. Ларина
        </span>
        <span className={cnLarinVariants('TitleWord', { desktop: true })}>
          Александра Ларина
        </span>
        <span
          className={cnLarinVariants('TitleWord', { order: 3 }, [
            'Title-Caption',
          ])}
        >
          с 2013 года
        </span>
      </Title>

      <Text
        className={cnLarinVariants('Desc', { desktop: true }, ['Section-Desc'])}
      >
        Для тех, кто ищет варианты посложнее, чем обычно предлагают на ЕГЭ,
        публикуем задания с развернутым ответом из тренировочных вариантов А.
        <span className="halfspace">&nbsp;</span>А. Ларина. Новые условия
        по субботам, решения по пятницам.
      </Text>

      <div className="VariantBox">
        <Grid
          className={cnLarinVariants('Variants', { cut: !variantsAll }, [
            'Variants',
          ])}
        >
          {!larinVariants &&
            Array(340)
              .fill('')
              .map((item: string, i: number) => (
                <VariantLink fallback key={i} />
              ))}

          {larinVariants &&
            larinVariants.map(({ id, number }: ILarinVariant) => (
              <VariantLink
                className={cnLarinVariants('Link')}
                to={`/test?id=${id}`}
                title={`Александр Ларин, тренировочный вариант ЕГЭ № ${number}`}
                key={id}
              >
                {number}
              </VariantLink>
            ))}
        </Grid>

        <LinkWrap
          className={cnLarinVariants('Control', [
            'Link_pseudo',
            'VariantBox-Control',
          ])}
          classNameU="Link_pseudo-U"
          handleClick={toggleVariantsAll}
        >
          {variantsAll ? 'Скрыть' : 'Показать все'}
        </LinkWrap>
      </div>
    </Section>
  )
}

export default LarinVariants
