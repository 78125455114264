import { withBemMod } from '@bem-react/core'

import { cnFormAlert } from '..'
import './FormAlert_view_error.scss'

export interface IFormAlertViewErrorProps {
  /**
   * Внешний вид сообщения
   */
  view?: 'error'
}

/**
 * Модификатор, отвечающий за внешний вид сообщения
 * @param {IFormAlertViewErrorProps} props
 */
export const withViewError = withBemMod<IFormAlertViewErrorProps>(
  cnFormAlert(),
  {
    view: 'error',
  }
)
