import React, { FC } from 'react'
import { compose } from '@bem-react/core'

import { useWindowWidth } from 'hooks'
import TitleBase, { withLevel2 } from 'components/Title'
import Text from 'components/Text'
import 'components/Form/Form.scss'
import Input from 'components/Input'
import ButtonBase, { withViewDefault } from 'components/Button'

import { useHelpers } from './hooks'
import { cnTeacherVariant } from '../TeacherVariant'
import { useSelector } from 'react-redux'
import { selectorIsUAExam } from '../../../../modules/Exam'

const Title = compose(withLevel2)(TitleBase)
const Button = compose(withViewDefault)(ButtonBase)

const CatalogSearch: FC = () => {
  const {
    formAction,
    inputRef,
    id,
    loading,
    error,
    handleChange,
    handleSubmit,
  } = useHelpers()
  const isUAExam = useSelector(selectorIsUAExam)

  const windowWidth = useWindowWidth()
  const placeholder = () => {
    if (windowWidth > 836) {
      if (isUAExam) {
        return 'Номер чи текст, атрибут завдання'
      } else return 'номер или текст, атрибут задания'
    } else {
      if (isUAExam) {
        return '№/текст/атрибут'
      } else return '№/текст/атрибут'
    }
  }

  return (
    <div className="CatalogSearch">
      <Title level="2">{isUAExam ? `Пошук у каталозі` : `Поиск в каталоге`}</Title>

      <Text className="Section-Desc">
        {isUAExam ? `Завдання демоверсій, пробних робіт та минулих іспитів із рішеннями.` : `Задания демоверсий, банков, пробных работ и прошедших экзаменов
        с решениями.`}

      </Text>

      <form
        className="Form"
        action={formAction}
        method="POST"
        onSubmit={handleSubmit}
      >
        <div className="Form-Row_oneLine">
          <Input
            className="Form-Input_oneLine"
            name="id"
            value={id}
            placeholder={placeholder()}
            innerRef={inputRef}
            handleChange={handleChange}
          />
          <Button
            className="CatalogSearch-Button"
            view="default"
            disabled={loading}
          >
            {isUAExam ? `Відкрити` : `Открыть`}
          </Button>
        </div>
        {error && <div className={cnTeacherVariant('Alert', [
          'Form-Alert',
          'Form-Alert_view_action',
        ])}>{error}</div>
        }
      </form>
    </div>
  )
}

export default CatalogSearch
