import React from 'react'
import { cn } from '@bem-react/classname'

import Section from 'components/Section'

import './ContentFallback.scss'

export const cnContentFallback = cn('ContentFallback')

const ContentFallback: JSX.Element = (
  <Section className={cnContentFallback()}> </Section>
)

export default ContentFallback
