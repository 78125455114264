import React from 'react'

import { cnContentAd } from 'pages/App/components/Ads/ContentAd'

import { cnContentTopAd } from '..'
import './ContentTopAdYandex.scss'

/**
 * Рекламные цсс-классы генерируются без @bem-react/classname,
 * чтобы получалось их захешировать
 */
const ContentTopAdYandex = () => (
  <div
    className={cnContentAd('Item', { development: true }, [
      cnContentTopAd('Yandex'),
    ])}
  />
)

export default ContentTopAdYandex
