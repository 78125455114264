import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { selectUser, getUserEmail } from 'modules/User'

/*
 * Такая логика нужна для следующего сценария.
 * Пользователь пытается войти на сайт, но пароль не подходит.
 * Поэтому пользователь открывает попап для восстановления,
 * а там уже введена его почта.
 */
export const useEmail = (): [string, Dispatch<SetStateAction<string>>] => {
  const [email, setEmail] = useState('')

  const user = useSelector(selectUser)
  const userEmail = getUserEmail(user)

  useEffect(() => {
    if (userEmail) {
      setEmail(userEmail)
    }
  }, [userEmail])

  return [email, setEmail]
}
