import axios from 'axios'

import { IUser } from '.'
import { getApiKey } from 'utils'

export interface IAuthParams {
  user: string
  password: string
  guest?: boolean
}

export interface IAuthStatus {
  status: boolean
  user?: IUser | null
}

const API_KEY = getApiKey()

export const getAuthStatus = async ({
  user,
  password,
  guest
}: IAuthParams): Promise<IAuthStatus> => {
  try {
    const response = await axios.post(
      `/newapi/login${API_KEY}`,
      {
        user,
        password,
        guest
      },
      { withCredentials: true }
    )

    if (response) return response.data
  } catch (error) {
    console.error(error)
  }

  return Promise.reject({ status: false })
}

export const logoutUser = async (): Promise<any> => {
  await axios(`/logout${API_KEY}`, {
    withCredentials: true,
  })
}
