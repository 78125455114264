import React from 'react'
import { useSelector } from 'react-redux'
import { cn } from '@bem-react/classname'

import { selectWriting } from 'modules/Writing'
import { formatHTML } from 'utils'
import InnerHTML from 'dangerously-set-html-content'

const Writing = () => {
  const cnWriting = cn('Writing')
  const content = useSelector(selectWriting)

  if (!content) {
    return null
  }

  const createMarkup = () => {
    return formatHTML(content)
  }

  return (
    <InnerHTML className={cnWriting()} html={createMarkup()} />
  )
}

export default Writing
