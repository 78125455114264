import React, { FC, ReactNode, MouseEvent, ElementType } from 'react'
import { cn } from '@bem-react/classname'

import './Button.scss'

export interface IButtonProps {
  className?: string
  type?: 'button' | 'submit' | 'reset'
  disabled?: boolean
  tabIndex?: number
  title?: string
  handleClick?: (e: MouseEvent<HTMLButtonElement>) => void
  'aria-label'?: string

  /**
   * HTML-атрибут `role`
   *
   * Нужен в редких случаях для тача
   */
  role?: string

  /**
   * Хтмл-атрибут для рендера заголовка
   * @default 'button'
   *
   * Нужен в редких случаях для тача
   */
  as?: ElementType

  children: ReactNode
}

export const cnButton = cn('Button')

/**
 * Компонент для создания кнопки
 * @param {IButtonProps} props
 */
const Button: FC<IButtonProps> = ({
  className,
  disabled,
  title,
  handleClick: onClick,
  as: Component = 'button',
  ...props
}) => (
  <Component
    {...props}
    className={cnButton({ disabled }, [className])}
    disabled={disabled}
    title={title}
    aria-label={props['aria-label'] || title}
    aria-disabled={disabled}
    aria-busy={disabled}
    onClick={onClick}
  />
)

export default Button
