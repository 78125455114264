export const getMonth = (date: Date): string => {
  const i = date.getMonth()

  const monthList = [
    'янв',
    'фев',
    'мар',
    'апр',
    'мая',
    'июня',
    'июля',
    'авг',
    'сен',
    'окт',
    'ноя',
    'дек',
  ]

  return monthList[i]
}
