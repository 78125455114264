import React, { FC, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { cn } from '@bem-react/classname'
import { compose } from '@bem-react/core'

import { selectOurVariants } from 'modules/OurVariants'
import TitleBase, { withLevel2 } from 'components/Title'
import Section from 'components/Section'
import ComplexHeader from 'components/ComplexHeader'
import Text from 'components/Text'
import VariantLink from 'components/VariantLink'

import { getCurrentMonthOfVariants } from './utils'
import OurVariantsPersonal from './Personal'
import ArchiveLink from './ArchiveLink'
import './OurVariants.scss'
import { selectorIsUAExam } from '../../../../modules/Exam'

const Title = compose(withLevel2)(TitleBase)
export const cnOurVariants = cn('OurVariants')

const OurVariants: FC = () => {
  const ourVariants = useSelector(selectOurVariants)
  const isUAExam = useSelector(selectorIsUAExam)
  const month = useMemo(() => getCurrentMonthOfVariants({ lang: isUAExam ? 'ua' : 'ru' }), [isUAExam])


  return (
    <Section className={cnOurVariants()}>
      <ComplexHeader className="Section-ComplexHeader">
        <Title level="2">
          <span className="OurVariants-TitleWord1">{isUAExam ? `Тренувальні ` : `Тренировочные `}</span>
          <span className="OurVariants-TitleWord2">{isUAExam ? `варіанти` : `варианты`}</span>
          <span className="Title-Caption">{isUAExam ? `нові` : `новые`} {month}</span>
        </Title>
        <div className="ComplexHeader-Link ComplexHeader-Link_desktop">
          <ArchiveLink>{isUAExam ? `Минулий місяць` : `Прошлые месяцы`}</ArchiveLink>
        </div>
      </ComplexHeader>

      <Text className="Section-Desc">
        {isUAExam ? `Щомісяця ми складаємо варіанти для самоперевірки. Варіанти складаються 
        комп'ютером із нових завдань та завдань, що виявилися найскладнішими за результатами
         попереднього місяця. Після закінчення роботи система перевірить ваші відповіді, покаже
          правильні рішення та виставить оцінку.` : `Каждый месяц мы составляем варианты для самопроверки. Варианты
        составляются компьютером из новых заданий и заданий, оказавшихся самыми
        сложными по результатам предыдущего месяца. По окончании работы система
        проверит ваши ответы, покажет правильные решения и выставит оценку.`}
      </Text>

      <div className="OurVariants-Grid">
        {!ourVariants &&
          Array(15)
            .fill('')
            .map((item: string, i: number) => (
              <VariantLink className="OurVariants-Link" fallback key={i} />
            ))}

        {ourVariants &&
          ourVariants.map((id: number, i: number) => (
            <VariantLink
              className="OurVariants-Link"
              data-wordClassName="OurVariants-Word"
              to={`/test?id=${id}`}
              key={id}
            >
              {++i}
            </VariantLink>
          ))}
      </div>

      <OurVariantsPersonal />

      <div className="ComplexHeader-Link ComplexHeader-Link_mobile">
        <ArchiveLink>{isUAExam ? `Варіанти минулих місяців` : `Варианты прошлых месяцев`}</ArchiveLink>
      </div>
    </Section>
  )
}

export default OurVariants
