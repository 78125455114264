/*interface INnameNumber<T> {
    [key: number]: T
}*/
export const nameNumbersRU: { [key: number]: string } = {
    1: 'одного',
    2: 'двух',
    3: 'трёх',
    4: 'четырёх',
    5: 'пяти',
    6: 'шести',
    7: 'семи',
    8: 'восьми',
    9: 'девяти',
    10: 'десяти'
}

export const nameNumbersUA: { [key: number]: string } = {
    1: 'одного',
    2: 'двох',
    3: 'трьох',
    4: 'чотирьох',
    5: `п'яти`,
    6: 'шести',
    7: 'семи',
    8: 'восьми',
    9: `дев'яти`,
    10: 'десяти'
}




