import {
  ActionFunctionAny,
  Action,
  ReduxCompatibleReducer,
  createAction,
  handleActions,
} from 'redux-actions'

import * as constants from './constants'

export interface IOurVariantsStatusAction {
  type: typeof constants.FETCH_OUR_VARIANTS
  payload: Array<number> | null
}

// Actions
export const fetchOurVariants: ActionFunctionAny<Action<Array<
  number
> | null>> = createAction(constants.FETCH_OUR_VARIANTS)

// Reducers
const ourVariants: ReduxCompatibleReducer<
  Array<number> | null,
  Array<number> | null
> = handleActions(
  {
    [constants.FETCH_OUR_VARIANTS]: (_state: Array<number> | null, action) =>
      action.payload,
  },
  null
)

export const initialState = null
export type ourVariantsState = ReturnType<typeof ourVariants>
export default ourVariants
