import { takeLatest, call, put, select, fork, takeEvery } from 'redux-saga/effects'
import { Action } from 'redux-actions'
import {
    TeacherTestState,
    fetchTests,
    IFetchTestsAction,
    IFetchTestAction,
    fetchTest,
    initAssigns,
    initGroups,
    initTest,
    initTests,
    initThemes,
    setTest,
    setTheme,
    markAllCheckTest
} from './duck'

import {
  IGetTestsResult,
  getTests,
  IGetTestResult,
  getTest,
  doDeleteTheme,
  doPatchAssigns,
  doPatchTest,
  doPatchTheme,
  doPutTheme,
  markCheckAllTest,
} from './api'
//import { selectComments } from './selectors'

import {
  initialState,
  ITeacherTest,
  ITeacherTestTheme,
  newTheme,
  removeTest,
  removeTestDone,
  removeTheme,
  selectTeacherTests,
  setAssign,
  setError,
} from '.'
import { FETCH_AUTH_STATUS_SUCCESS } from 'modules/Auth'

function* fetchTestsWatcher() { yield takeLatest(fetchTests, fetchTestsFlow) }
function* fetchTestWatcher()  { yield takeLatest(fetchTest, fetchTestFlow) }
function* putThemeWatcher()  { yield takeEvery(newTheme, putThemeFlow) }
function* patchTestWatcher()  { yield takeEvery(setTest, patchTestFlow) }
function* patchThemeWatcher()  { yield takeEvery(setTheme, patchThemeFlow) }
function* patchAssignsWatcher()  { yield takeEvery(setAssign, patchAssignsFlow) }
function* deleteThemeWatcher()  { yield takeEvery(removeTheme, deleteThemeFlow) }
function* deleteTestWatcher()  { yield takeEvery(removeTest, deleteTestFlow) }
function* userLoginWatcher() {yield takeLatest(FETCH_AUTH_STATUS_SUCCESS, userFetchTestsFlow)}
function* markAllCheckWatcher() {yield takeLatest(markAllCheckTest, markCheckAllTestFlow)}

function* userFetchTestsFlow(action: IFetchTestsAction) {
  const data: TeacherTestState = yield select(selectTeacherTests)
  if (data.error !== initialState.error){
    yield fetchTestsFlow(action)
  }
}

function* fetchTestsFlow(action: IFetchTestsAction) {
  const {tests, themes, groups, groupAssign, status, error }: IGetTestsResult = yield call(getTests)
  if (status !== undefined && status !== 0) {
    yield put(setError(error))
    return
  }
  yield put(initTests(tests))
  yield put(initThemes(themes))
  yield put(initGroups(groups))
  yield put(initAssigns(groupAssign))
  yield put(setError(''))
}

function* fetchTestFlow(action: IFetchTestAction) {
   const test: IGetTestResult = yield call(getTest, action.payload)
   yield put(initTest(test))
 }

function* putThemeFlow(action: Action<string>) {
  const data: ITeacherTestTheme = {id: 0, name: action.payload, archive:0, state:1, members:[]}
   const theme: ITeacherTestTheme = yield call(doPutTheme, data)
   yield put(setTheme(theme))
}

function* patchThemeFlow(action: Action<ITeacherTestTheme>) {
  yield call(doPatchTheme, action.payload)
}

function* deleteThemeFlow(action: Action<number>) {
  yield call(doDeleteTheme, action.payload)
}

function* deleteTestFlow(action: Action<number>) {
  const data: TeacherTestState = yield select(selectTeacherTests)
  for (let i=0; i<data.tests.length; i++){
    if (data.tests[i].id === action.payload){
      let pl = {...data.tests[i]}
      pl.public = 4
      yield call(doPatchTest, pl)
    }
  }
  yield put(removeTestDone(action.payload))
}
function* patchTestFlow(action: Action<ITeacherTest>) {
  yield call(doPatchTest, action.payload)
}
function* patchAssignsFlow() {
  const data: TeacherTestState = yield select(selectTeacherTests)
  yield call(doPatchAssigns, data.groupAssign)
}

function* markCheckAllTestFlow(action: IFetchTestsAction) {
  yield call(markCheckAllTest)
  yield userFetchTestsFlow(action)
}

export default function* sagas() {
  yield fork(fetchTestsWatcher)
  yield fork(fetchTestWatcher)
  yield fork(putThemeWatcher)
  yield fork(patchTestWatcher)
  yield fork(patchThemeWatcher)
  yield fork(patchAssignsWatcher)
  yield fork(deleteThemeWatcher)
  yield fork(deleteTestWatcher)
  yield fork(userLoginWatcher)
  yield fork(markAllCheckWatcher)
}
