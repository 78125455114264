import { ITopic } from 'components/ConstructorForm/Buttons'

export const checkListOnFilledPart = (list: ITopic[], part: string) => {
  for (let i = 0; i < list.length; i++) {
    const { type, value } = list[i]

    if (type === part && value! < 1) {
      return false
    }
  }

  return true
}
