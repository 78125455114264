import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { cn } from '@bem-react/classname'
import { compose } from '@bem-react/core'

import { CHANGE_PASSWORD_POPUP_TITLE } from 'pages/App/components/ChangePasswordPopup'
import {
  selectRecoveryEmailPopup,
  getRecoveryEmailPopupVisible,
  getRecoveryEmailPopupSent,
  isRecoveryEmailPopupVisible,
} from 'modules/RecoveryEmailPopup'
import { useMobileVersion } from 'hooks'
import Portal from 'components/Portal'
import DrawerBase, { withViewDefault } from 'components/Drawer'
import Modal from 'components/Modal'
import TitleBase, { withLevel2 } from 'components/Title'

import RecoveryEmailPopupForm from './Form'
import RecoveryEmailPopupConfirm from './Confirm'
import './RecoveryEmailPopup.scss'
import { selectorIsUAExam } from 'modules/Exam/selectors'

const Drawer = compose(withViewDefault)(DrawerBase)
const Title = compose(withLevel2)(TitleBase)

export const cnRecoveryEmailPopup = cn('RecoveryEmailPopup')

const RecoveryEmailPopup = () => {
  const isMobile = useMobileVersion()
  const popup = useSelector(selectRecoveryEmailPopup)
  const visible = getRecoveryEmailPopupVisible(popup)
  const sent = getRecoveryEmailPopupSent(popup)
  const isUAExam = useSelector(selectorIsUAExam)
  const dispatch = useDispatch()

  const onClose = useCallback(() => {
    dispatch(isRecoveryEmailPopupVisible(false))
  }, [dispatch])

  if (!visible) {
    return null
  }

  const content = (
    <div className={cnRecoveryEmailPopup('Content')}>
      <RecoveryEmailPopupForm visible={!sent} />
      <RecoveryEmailPopupConfirm visible={sent} />
    </div>
  )

  return isMobile ? (
    <Portal>
      <Drawer
        className={cnRecoveryEmailPopup({ platform: 'touch' })}
        view="default"
        visible={isMobile && visible}
        onClose={onClose}
      >
        <div className={cnRecoveryEmailPopup('DrawerContent')}>
          <Title level="2">{isUAExam ? CHANGE_PASSWORD_POPUP_TITLE.ua : CHANGE_PASSWORD_POPUP_TITLE.rus}</Title>
          {content}
        </div>
      </Drawer>
    </Portal>
  ) : (
    <Modal
      className={cnRecoveryEmailPopup({ platform: 'desktop' })}
      title={isUAExam ? CHANGE_PASSWORD_POPUP_TITLE.ua : CHANGE_PASSWORD_POPUP_TITLE.rus}
      onClose={onClose}
    >
      {content}
    </Modal>
  )
}

export default RecoveryEmailPopup
