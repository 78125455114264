import { combineReducers } from 'redux'
import { fork } from 'redux-saga/effects'

import user, { initialState as userInitialState } from './User'
import auth, {
  initialState as authInitialState,
  sagas as authSagas,
} from './Auth'
import exam, {
  initialState as examInitialState,
  sagas as examSagas,
} from './Exam'
import chat, {
  initialState as chatInitialState,
  sagas as chatSagas,
} from './Chat'
import subject, { initialState as subjectInitialState } from './Subject'
import subjectMenu, {
  initialState as subjectMenuInitialState,
} from './SubjectMenu'
import sidebarMenu, {
  initialState as sidebarMenuInitialState,
} from './SidebarMenu'
import ourVariants, {
  initialState as ourVariantsInitialState,
} from './OurVariants'
import larinVariants, {
  initialState as larinVariantsInitialState,
} from './LarinVariants'
import writing, { initialState as writingInitialState } from './Writing'
import testConstructor, {
  initialState as testConstructorInitialState,
} from './TestConstructor'
import ads, { initialState as adsInitialState } from './Ads'
import bots, { initialState as botsInitialState } from './Bots'
import news, { initialState as newsInitialState } from './News'
import thieves, { initialState as thievesInitialState } from './Thieves'
import apps, { initialState as appsInitialState } from './Apps'
import recoveryEmailPopup, {
  initialState as recoveryEmailPopupInitialState,
} from './RecoveryEmailPopup'

import comments, {
  initialState as commentsInitialState,
  sagas as commentsSagas,
} from './Comments'
import individualProfile, {
  initialState as individualProfileState,
  sagas as individualProfileSagas,
} from './IndividualProfile'
import teacherTests, {initialState as TeacherTestState, sagas as TeacherTestSagas} from './TeacherTest'
import teacherPupils, {initialState as TeacherPupilState, sagas as TeacherPupilSagas} from './TeacherPupil'
import catalog, {initialState as CatalogState, sagas as CatalogSagas} from './Catalog'
import teacherJournal, {initialState as JournalState, sagas as JournalSagas, teacherJournalHover, initalHoverState as JournalHoverState} from './TeacherJournal'
import test, {initialState as TestState, sagas as TestSagas} from './Test'

export const rootPreloadedState: RootState = {
  user: userInitialState,
  auth: authInitialState,
  exam: examInitialState,
  subject: subjectInitialState,
  subjectMenu: subjectMenuInitialState,
  sidebarMenu: sidebarMenuInitialState,
  ourVariants: ourVariantsInitialState,
  larinVariants: larinVariantsInitialState,
  writing: writingInitialState,
  testConstructor: testConstructorInitialState,
  ads: adsInitialState,
  bots: botsInitialState,
  news: newsInitialState,
  thieves: thievesInitialState,
  apps: appsInitialState,
  chat: chatInitialState,
  recoveryEmailPopup: recoveryEmailPopupInitialState,
  comments: commentsInitialState,
  individualProfile: individualProfileState,
  teacherTests: TeacherTestState,
  teacherPupils: TeacherPupilState,
  catalog: CatalogState,
  teacherJournal: JournalState,
  teacherJournalHover: JournalHoverState,
  test: TestState
}

const rootReducer = combineReducers({
  user,
  auth,
  exam,
  subject,
  subjectMenu,
  sidebarMenu,
  ourVariants,
  larinVariants,
  writing,
  testConstructor,
  ads,
  bots,
  news,
  thieves,
  apps,
  chat,
  recoveryEmailPopup,
  comments,
  individualProfile,
  teacherTests,
  teacherPupils,
  catalog,
  teacherJournal,
  teacherJournalHover,
  test
})

export type RootState = ReturnType<typeof rootReducer>
export default rootReducer

export function* rootSaga() {
  yield fork(authSagas)
  yield fork(examSagas)
  yield fork(chatSagas)
  yield fork(commentsSagas)
  yield fork(individualProfileSagas)
  yield fork(TeacherTestSagas)
  yield fork(TeacherPupilSagas)
  yield fork(CatalogSagas)
  yield fork(JournalSagas)
  yield fork(TestSagas)
}
