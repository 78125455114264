import React, { FC } from 'react'
import { cn } from '@bem-react/classname'
import cx from 'classnames'

import { useHelpers } from 'pages/StudentPage/components/CatalogSearch/hooks'
import SearchIcon from 'components/SearchIcon/SearchIcon'
import Input from 'components/Input'
import './SidebarSearch.scss'
import { useSelector } from 'react-redux'
import { selectorIsUAExam } from '../../../../../modules/Exam'

interface ISidebarSearchProps {
  className?: string
}

const SidebarSearch: FC<ISidebarSearchProps> = ({ className }) => {
  const sidebarSearch = cn('SidebarSearch')
  const {
    formAction,
    inputRef,
    id,
    loading,
    error,
    handleChange,
    handleSubmit,
  } = useHelpers()
  const isUAExam = useSelector(selectorIsUAExam)

  return (
    <form
      className={cx(sidebarSearch(), className)}
      action={formAction}
      method="POST"
      onSubmit={handleSubmit}
    >
      <label
        className={sidebarSearch('Label')}
        title="Для поиска нажмите клавишу Enter"
      >
        <SearchIcon className={sidebarSearch('Icon')} />
        <Input
          className={sidebarSearch('Input', { loading })}
          name="id"
          value={id}
          placeholder={isUAExam ? `№/текст/атрибут`: `№/текст/атрибут`}
          innerRef={inputRef}
          handleChange={handleChange}
        />
      </label>

      {error && <div className={sidebarSearch('Alert')}>Ничего не найдено</div>}
    </form>
  )
}

export default SidebarSearch
