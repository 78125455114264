import React, { FC } from 'react'
import { cn } from '@bem-react/classname'
import cx from 'classnames'

import { useData, useHandleRemove } from './hooks'
import Link from 'components/Link'
import Modal, { OnClose } from 'components/Modal'
import Text from 'components/Text'
import CloseButton from 'components/CloseButton'
import PinnedTasksPanel from './Panel'
import './PinnedTasks.scss'

export const cnPinnedTasks = cn('PinnedTasks')

interface IPinnedTasksProps {
  toggleVisibility: OnClose
}

const PinnedTasks: FC<IPinnedTasksProps> = ({ toggleVisibility }) => {
  const { data, setData } = useData()

  const handleRemove = useHandleRemove(data, setData)

  return (
    <Modal
      title="Создание варианта"
      loading={!Boolean(data)}
      onClose={toggleVisibility}
    >
      <div className={cnPinnedTasks()}>
        <Text>
          Не храните здесь задания постоянно, используйте для этого «Избранное».
        </Text>

        <ul className={cnPinnedTasks('List')}>
          {data?.map((id: number, i: number) => (
            <li className={cnPinnedTasks('ListItem')} key={id.toString()}>
              Задание{' '}
              <Link
                className="Link_black"
                href={`/problem?id=${id}`}
              >
                № {id}
              </Link>
              <CloseButton
                className={cx(
                  cnPinnedTasks('Button'),
                  cnPinnedTasks('ListItemButton')
                )}
                title="Убрать задание"
                onClick={handleRemove}
                data-i={i}
                data-id={id}
              >
                ×
              </CloseButton>
            </li>
          ))}
        </ul>

        <PinnedTasksPanel />
      </div>
    </Modal>
  )
}

export default PinnedTasks
