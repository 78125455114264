import { FC, ReactPortal, ReactNode, useEffect } from 'react'
import { createPortal } from 'react-dom'

const portalRoot = document.querySelector('.PortalRoot')

interface IPortalProps {
  children: ReactNode,
  clname?: string
}

const Portal: FC<IPortalProps> = (props): ReactPortal => {
  const el = document.createElement('div')
  if (props.clname) {
    el.className = props.clname
  }
  useEffect(() => {
    portalRoot!.appendChild(el)

    return () => {
      portalRoot!.removeChild(el)
    }
  }, [el])

  return createPortal(props.children, el)
}

export default Portal
