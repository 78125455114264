import { withBemMod } from '@bem-react/core'

import { cnFormAlert } from '..'
import './FormAlert_view_success.scss'

export interface IFormAlertViewSuccessProps {
  /**
   * Внешний вид сообщения
   */
  view?: 'success'
}

/**
 * Модификатор, отвечающий за внешний вид сообщения
 * @param {IFormAlertViewSuccessProps} props
 */
export const withViewSuccess = withBemMod<IFormAlertViewSuccessProps>(
  cnFormAlert(),
  {
    view: 'success',
  }
)
