import React, { FC } from 'react'
import cx from 'classnames'
import { compose } from '@bem-react/core'


import Checkbox from 'components/Checkbox'
import ButtonBase, { withViewDefault } from 'components/Button'
import Link from 'components/Link'

import { cnPinnedTasks } from '../PinnedTasks'
import { useHandleToggle } from './hooks'
import './PinnedTasks-Panel.scss'

const Button = compose(withViewDefault)(ButtonBase)

const PinnedTasksPanel: FC = () => {
  const { saved, handleToggle } = useHandleToggle()
  const buttons = [
    {
      text: 'Обычный вариант',
      pub: 0,
      hint: 'результаты выполнения будут в разделе «Моя статистика»',
    },
    {
      text: 'Домашняя работа',
      pub: 2,
      hint: 'вариант сохранится в разделе «Учителю»',
    },
    {
      text: 'Контрольная работа',
      pub: 1,
      hint: 'вариант сохранится в разделе «Учителю»',
    },
  ]

  return (
    <div className={cnPinnedTasks('Panel')}>
      <label
        className={cx('Label', cnPinnedTasks('Label'), cnPinnedTasks('Row'))}
      >
        <div>
          <Checkbox
            name="pinned-tasks-save"
            value={saved}
            onChange={handleToggle}
          />
        </div>
        <span className={cnPinnedTasks('LabelText')}>
          Сохранить этот список заданий здесь после составления варианта
        </span>
      </label>

      <div>
        {buttons &&
          buttons.map(({ text, pub, hint }, i) => (
            <div className={cnPinnedTasks('Row')} key={`${i}-${pub}`}>
              <Link
                className={cx('Link_black', 'Link_wrap', cnPinnedTasks('Link'))}
                href={`/cart?a=test&pub=${pub}&save=${Number(saved)}`}
              >
                <Button view="default">{text}</Button> <span>{hint}</span>
              </Link>
            </div>
          ))}
      </div>
    </div>
  )
}

export default PinnedTasksPanel
