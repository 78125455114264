import axios from 'axios'

import { getApiKey } from 'utils'

export const removePinnedTask = async (id: number): Promise<void> => {

  await axios.delete(`/newapi/pinned-tasks${getApiKey()}`, {
    params: {
      id,
    },
    withCredentials: true,
  })
}
