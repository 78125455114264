import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { cn } from '@bem-react/classname'

import { selectChatDialogOpen } from 'modules/Chat'
import ChatLayout from './ChatLayout'
import Dialog from './Dialog'
import Send from './Dialog/Send'
import Contacts from './Contacts'
import './ChatPopup.scss'

export const cnChatPopup = cn('ChatPopup')

// TODO: Rethink architecture
const ChatPopup: FC = () => {
  const dialogOpen = useSelector(selectChatDialogOpen)

  return (
    <ChatLayout sendForm={<Send />}>
      {dialogOpen ? <Dialog /> : <Contacts />}
    </ChatLayout>
  )
}

export default ChatPopup
