import React, {
  Dispatch,
  SetStateAction,
  Fragment,
  useState,
  useCallback,
  useLayoutEffect,
} from 'react'
import { useSelector } from 'react-redux'

import { ITopic } from '../Buttons'
import { ITestTotal, IParts } from '../ConstructorForm'
import { selectExam } from 'modules/Exam'
import { selectTestConstructor } from 'modules/TestConstructor'
import { saveExtraTopicsHidden, loadExtraTopicsHidden } from './utils'
import PartName from './PartName'
import Fallback from 'components/Fallback'
import ConstructorFormRow from './Row'

interface ITopicsByPartsProps {
  className?: string
  page: string
  parts: IParts
  setParts: Dispatch<SetStateAction<IParts>>
  testTotal: ITestTotal
  setTestTotal: Dispatch<SetStateAction<ITestTotal>>
}

const TopicsByParts: any = ({
  className,
  page,
  parts,
  setParts,
  testTotal,
  setTestTotal,
}: ITopicsByPartsProps) => {
  const [extraTopicsHidden, areExtraTopicsHidden] = useState(true)
  const exam = useSelector(selectExam)
  const topicsList = useSelector(selectTestConstructor)

  let currentType: string | null = null

  useLayoutEffect(() => {
    const extraTopicsHiddenSaved = loadExtraTopicsHidden()

    if (extraTopicsHiddenSaved !== null) {
      areExtraTopicsHidden(extraTopicsHiddenSaved)
    }
  }, [])

  const handleExtraPartNameClick = useCallback(() => {
    const newValue = !extraTopicsHidden

    areExtraTopicsHidden(newValue)
    saveExtraTopicsHidden(newValue)
  }, [extraTopicsHidden])

  return (
    <div className={className}>
      {!topicsList &&
        Array(25)
          .fill('')
          .map((item: string, i: number) => (
            <Fallback
              className="ConstructorForm-Row ConstructorForm-Row_fallback"
              key={`topicFallback${i}`}
            />
          ))}

      {topicsList?.map(
        (
          {
            id,
            issue,
            title,
            name,
            value,
            type,
            open,
            amount,
            subtopics,
          }: ITopic,
          i: number
        ) => {
          const isVprExtraPart = ['vpr', 'urok', 'ent'].includes(exam!.name) && type === 'extra'
          let withPartName = false

          if (
            (['oge', 'ege'].includes(exam!.name) || isVprExtraPart) &&
            type !== currentType
          ) {
            withPartName = true
            currentType = type
          }

          return (
            <Fragment key={`fragment${i}`}>
              {withPartName && (
                <PartName
                  part={type}
                  handleExtraPartNameClick={handleExtraPartNameClick}
                />
              )}

              <ConstructorFormRow
                i={i}
                page={page}
                extraTopicsHidden={extraTopicsHidden}
                parts={parts}
                setParts={setParts}
                testTotal={testTotal}
                setTestTotal={setTestTotal}
                id={id}
                issue={issue}
                title={title}
                name={name}
                value={value}
                type={type}
                open={open}
                amount={amount}
                subtopics={subtopics}
              />
            </Fragment>
          )
        }
      )}
    </div>
  )
}

export default TopicsByParts
