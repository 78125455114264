import React, { FC, useState } from 'react'

import { useUpdateEffect } from 'hooks'
import Link from 'components/Link'
import { cnHeader } from '../..'
import { useExamList } from './hooks'
import './Header-ExamSwitcher.scss'
import { useSelector } from 'react-redux'
// import { selectExam } from '../../../../../../modules/Exam'
import {  selectExam } from 'modules/Exam'

interface IExamSwitcherProps {
  visible: boolean
}

const ExamSwitcher: FC<IExamSwitcherProps> = ({ visible = false }) => {

  const exam = useSelector(selectExam)
  const isOlymp = exam?.name === 'olymp'
  const examList = useExamList()

  /**
   * Чтобы анимация играла после закрытия компонента
   * @todo (ilyasidorchik)
   * Вынести в компонент Popup
   */
  const [isFirstRender, forceUpdate] = useState(true)
  useUpdateEffect(() => {
    if (isFirstRender && visible) {
      forceUpdate(false)
    }
  }, [])
  if (!visible && isFirstRender) {
    return null
  }


  return (
    <ul
      className={cnHeader('ExamSwitcher', { 'visible': visible, 'olymp': isOlymp })}
      id="ExamSwitcher"
      role="listbox"
    >
      {examList.map(({ title, name, domain }, i) => (
        <li
          className={cnHeader('ExamSwitcherItem')}
          key={name}
          role="option"
          aria-selected={false}
          id={`ExamSwitcher-Option_${i}`}
        >
          <Link
            className={cnHeader('ExamSwitcherLink', [
              'Link_wrap',
              'Link_black',
            ])}
            href={`https://${domain}/`}
          >
            <u
              className={cnHeader('ExamSwitcherUnderline', [
                'Link-U Link_wrap-U Link_black-U',
              ])}
            >
              {title}
            </u>
          </Link>
        </li>
      ))}
    </ul>
  )
}

export default ExamSwitcher
