import {
  ActionFunctionAny,
  Action,
  ReduxCompatibleReducer,
  createAction,
  handleActions,
} from 'redux-actions'
import { combineReducers } from 'redux'

import { IAuthParams } from './api'
import * as constants from './constants'

export interface IAuthStatusAction {
  type: typeof constants.FETCH_AUTH_STATUS_REQUEST
  payload: IAuthParams
}

// Actions
export const startAuthLoading: ActionFunctionAny<Action<
  boolean
>> = createAction(constants.START_AUTH_LOADING)
export const finishAuthLoading: ActionFunctionAny<Action<
  boolean
>> = createAction(constants.FINISH_AUTH_LOADING)

export const fetchAuthStatusRequest: ActionFunctionAny<Action<
  IAuthParams
>> = createAction(constants.FETCH_AUTH_STATUS_REQUEST)
export const fetchAuthStatusSuccess: ActionFunctionAny<Action<
  boolean
>> = createAction(constants.FETCH_AUTH_STATUS_SUCCESS)
export const fetchAuthStatusFailure: ActionFunctionAny<Action<
  boolean
>> = createAction(constants.FETCH_AUTH_STATUS_FAILURE)

export const fetchAuthUserSuccess: ActionFunctionAny<Action<IUser | null>> = createAction(
  constants.FETCH_AUTH_USER_SUCCESS
)
export const fetchAuthUserFailure: ActionFunctionAny<Action<
  null
>> = createAction(constants.FETCH_AUTH_USER_FAILURE)

export const logout: ActionFunctionAny<any> = createAction(constants.LOGOUT)

// Reducers
const loading: ReduxCompatibleReducer<boolean, boolean> = handleActions(
  {
    [constants.START_AUTH_LOADING]: () => true,
    [constants.FINISH_AUTH_LOADING]: () => false,
  },
  false
)

const status: ReduxCompatibleReducer<boolean, boolean> = handleActions(
  {
    [constants.FETCH_AUTH_STATUS_REQUEST]: () => false,
    [constants.FETCH_AUTH_STATUS_SUCCESS]: (_state: boolean, action) =>
      action.payload,
    [constants.FETCH_AUTH_STATUS_FAILURE]: () => false,
  },
  false
)

export interface IUser {
  id?: number
  name?: string
  firstName: string
  lastName?: string
  city?: string
  pinnedTaskAmount?: number
  username?: string
}

const user: ReduxCompatibleReducer<IUser | null, IUser | null> = handleActions(
  {
    [constants.FETCH_AUTH_USER_SUCCESS]: (_state: IUser | null, action) =>
      action.payload,
    [constants.FETCH_AUTH_USER_FAILURE]: () => null,
  },
  null
)

export const initialState: AuthState = {
  loading: false,
  status: false,
  user: null,
}

const auth = combineReducers({
  loading,
  status,
  user,
})

export type AuthState = ReturnType<typeof auth>
export default auth
