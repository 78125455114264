import React, { FC } from 'react'
import { cn } from '@bem-react/classname'
import cx from 'classnames'

import SocialMedia from './SocialMedia'
import Apps from './Apps'
import './OurSources.scss'

interface IOurSources {
  className?: string
}

const OurSources: FC<IOurSources> = ({ className }) => {
  const ourSources = cn('OurSources')

  return (
    <div className={cx(ourSources(), className)}>
      <SocialMedia cnFormatter={ourSources} />
      <Apps cnFormatter={ourSources} />
    </div>
  )
}

export default OurSources
