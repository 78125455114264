import React from 'react'
import { withBemMod } from '@bem-react/core'

import { ITitleProps, cnTitle } from '../Title'
import './Title_level_4.scss'

export interface ITitleLevel4Props {
  /**
   * Уровень заголовка
   */
  level?: '4'
}

/**
 * Модификатор, отвечающий за уровень заголовка
 * @param {ITitleLevel4Props} props
 */
export const withLevel4 = withBemMod<ITitleLevel4Props, ITitleProps>(
  cnTitle(),
  {
    level: '4',
  },
  (WrappedComponent) => ({ ...props }) => (
    <WrappedComponent {...props} as="h4" />
  )
)
