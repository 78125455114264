import React from 'react'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'

import { selectExam } from 'modules/Exam'
import { selectSubject } from 'modules/Subject'
import { useConstructor } from 'utils'
// import { getDomain } from 'utils'
// import { getKeywords, getDescription } from './utils'
// import { findAllByAltText } from '@testing-library/react'

const HeadInfo = () => {
  const exam = useSelector(selectExam)
  const subject = useSelector(selectSubject)
  useConstructor(()=>{
    const css = document.createElement('link')
    css.rel = 'stylesheet'
    css.type = 'text/css'
    css.href = '/style_' + subject!.name + '.css'
    document.head.appendChild(css)
  })
  const date = new Date()
  const examDate = date.getMonth() > 8 ? date.getFullYear() + 1 : date.getFullYear()
  const examTitleWithDate = `${exam?.title}−${examDate}`
  const subjectTitle = subject ? `, ${subject.title}` : ''
  // const domain = useSelector(getDomain)
  // const domain = useMemo(() => getDomain(), [])
  // const description = getDescription(exam?.name, exam?.title, examTitleWithDate)
  // const keywords = getKeywords(exam?.name, exam?.title, examTitleWithDate)

  function getTitle() {
    if (exam && subject && subject.name) {
      if (!window.location.hostname.toString().includes(subject!.name)) {
        if (exam.domain?.includes('zno') || exam.domain?.includes('nmt')) {
          return `${examTitleWithDate}: завдання, відповіді, рішення`
        } else {
          return `${examTitleWithDate}: задания, ответы, решения`
        }
      } else {
        if (exam.domain?.includes('zno') || exam.domain?.includes('nmt')) {
          return `${examTitleWithDate}${subjectTitle}: завдання, відповіді, рішення`
        } else {
          return `${examTitleWithDate}${subjectTitle}: задания, ответы, решения`
        }
      }
    }
  }

  return (
    <Helmet>
      <title>
        {getTitle()}
      </title>
      {/*<meta name="description" content={description} />
      <meta name="keywords" content={keywords} />

      <meta property="og:title" content={`РЕШУ ${exam?.title}`} />
      <meta property="og:url" content={`https://${domain}`} />
      <meta
        property="og:image"
        content={`https://ege.sdamgia.ru/adv/reshu${exam?.name}-big.png`}
      />
      <meta
        property="og:description"
        content={`Тысячи заданий с решениями для подготовки к ${exam?.title}`}
      />
      {!exam!.domain?.includes('nmt') && <meta name="author" content="Гущин Дмитрий, reshuege@gmail.com" />}*/}
    </Helmet>
  )
}

export default HeadInfo
