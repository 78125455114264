import React, { FC } from 'react'

import { cnContentAd } from 'pages/App/components/Ads/ContentAd'

import { cnContentBottomAd } from '..'
import './ContentBottomAdGoogle.scss'

const ContentBottomAdGoogle: FC = () => (
  <div
    className={cnContentAd('Item', { development: true }, [
      cnContentBottomAd('Google'),
    ])}
  />
)

export default ContentBottomAdGoogle
