import { useState, useEffect } from 'react'

import { getPinnedTasks } from '../api'

export const useData = () => {
  const [data, setData] = useState<Array<number> | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      const newData = await getPinnedTasks()

      setData(newData)
    }

    fetchData()
  }, [])

  return { data, setData }
}
