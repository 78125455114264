import React, { FC, Fragment } from 'react'
import { cn } from '@bem-react/classname'
import LinkWrap from 'components/Link/_wrap'
import './Footer.scss'

// import spbsut from './assets/spbsut.png'

interface IFooterProps {
  className?: string
}

const Footer: FC<IFooterProps> = ({ className }) => {
  const cnFooter = cn('Footer')
  const data = [
    {
      href: '/about',
      text: 'О проекте'
    },
    {
      href: '/staff',
      text: 'Редакция'
    },
    {
      href: '/legal',
      text: 'Правовая информация'
    },
    {
      href: '/advert',
      text: 'О рекламе'
    },
  ]

  return (
    <footer className={cnFooter(null, [className])}>
      <nav className={cnFooter('TextItem', [cnFooter('Menu')])}>
        {data.map(({ href, text }, i) => (
          <Fragment key={href}>
            <LinkWrap
              className="Link_black"
              activeClassName="Link_selected"
              href={`${href}`}
            >
              {text}
            </LinkWrap>
            {i < data.length - 1 && ' · '}
          </Fragment>
        ))}
      </nav>

      {/*<a
        className={cnFooter('Partner')}
        href="http://priem.sut.ru/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={spbsut} alt="СПб ГУТ!" title="priem.sut.ru" width="160" />
      </a>*/}

      <div className={cnFooter('Copyright', [cnFooter('Menu')])}>
        ©&nbsp;Гущин Д. Д., 2011—{new Date().getFullYear()}
      </div>
    </footer>
  )
}

export default Footer
