import { ISubject } from './data/subjectList'

export const formatSubject = (subject: ISubject): ISubject => {
  if (subject.title === 'Математика профильного уровня') {
    return {
      ...subject,
      titleMobile: 'Математика профильная',
    }
  }

  if (subject.title === 'Математика базового уровня') {
    return {
      ...subject,
      titleMobile: 'Математика базовая',
    }
  }

  return subject
}
