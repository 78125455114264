import {
  ActionFunctionAny,
  Action,
  ReduxCompatibleReducer,
  createAction,
  handleActions,
} from 'redux-actions'

import * as constants from './constants'

export enum ExamName {
  ege,
  gve,
  oge,
  vpr,
  ct,
  urok,
  nmt,
  olymp,
  zno,
  ent,
  spo
}

export interface IExam {
  title: string
  name: keyof typeof ExamName
  domain?: string
}

export interface IExamStatusAction {
  type: typeof constants.FETCH_EXAM
  payload: IExam | null
}

// Actions
export const fetchExam: ActionFunctionAny<Action<IExam | null>> = createAction(
  constants.FETCH_EXAM
)
export const fetchExamFullData: ActionFunctionAny<Action<IExam | null>> = createAction(
  constants.FETCH_EXAM_FULL_DATA
)

// Reducers
const exam: ReduxCompatibleReducer<IExam | null, IExam | null> = handleActions(
  {
    [constants.FETCH_EXAM_FULL_DATA]: (_state: IExam | null, action) =>
      action.payload,
  },
  null
)

export const initialState = null
export type examState = ReturnType<typeof exam>
export default exam
