import React, { MutableRefObject, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  selectTestConstructor,
  fetchTestConstructor,
} from 'modules/TestConstructor'
import { saveTopicsList } from 'components/ConstructorForm/ConstructorForm.utils'

export const useCheckboxHandler = (
  page: string,
  subtopicLabel: MutableRefObject<HTMLLabelElement | null>
) => {
  const topicsList = useSelector(selectTestConstructor)
  const dispatch = useDispatch()

  const useCheckboxHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { topicI, subtopicI } = (e.target as any).dataset
      const list = [...topicsList!]
      const topic = list[topicI]
      const subtopic = topic.subtopics![subtopicI]
      const newValue = !subtopic.checked

      subtopic.checked = newValue
      dispatch(fetchTestConstructor(list))
      saveTopicsList(page, list)
    },
    [dispatch, page, topicsList]
  )

  return useCheckboxHandler
}
