import React from 'react'
import { useSelector } from 'react-redux'
import { compose } from '@bem-react/core'

import { selectTestConstructorWithTitleLinks } from 'modules/TestConstructor'
import TitleBase, { withLevel2 } from 'components/Title'
import Link from 'components/Link'
import { selectorIsUAExam } from '../../../../../modules/Exam'

const Title = compose(withLevel2)(TitleBase)

const ConstructorTitle = () => {
  const withTitleLinks = useSelector(selectTestConstructorWithTitleLinks)
  const isUAExam = useSelector(selectorIsUAExam)

  const links = withTitleLinks ? (
    <>
      {' '}
      <Link
        className="Link_static"
        href={`/prob-catalog`}
        to={`/prob-catalog`}
      >
        по типам
      </Link>{' '}
      и 
      <Link
        className="Link_static"
        href={`/sgia_catalog`}
      >
        по темам
      </Link>
    </>
  ) : (
    ''
  )

  return <Title level="2">{isUAExam ? `Конструктор варіанта` : `Конструктор варианта`}{links}</Title>
}

export default ConstructorTitle
