import { cn } from "@bem-react/classname";
import { FC, useEffect, useState } from "react";
import './DarkSwitcher.scss'

const DarkSwitcher: FC<{theme?:string}> = (props) => {
    const [myTheme, setTheme] = useState(localStorage.getItem('theme')||'light')
    
    let text = ''
    switch (myTheme){
        case 'auto':
            text = 'Тема: авто'
            break
        case 'dark':
            text = 'Тема: темная'
            break
        case 'light':
            text = 'Тема: светлая'
            break 
    }
    const cname = cn('DarkSwitcher')
    const toggle = () => {
        let newtheme = ''
        switch (myTheme){
            case 'auto':
                newtheme = 'dark'
                break
            case 'dark':
                newtheme = 'light'
                break
            case 'light':
                newtheme = 'auto'
                break
        }
        setTheme(newtheme)
    }
    
    useEffect(() => {
        document.body.setAttribute('data-theme', myTheme);
        try {
            localStorage.setItem('theme', myTheme);
        } catch (e) {}
      });

    return <div className={cname('switcher')} data-theme={myTheme} onClick={()=>toggle()}><img src={"/img/new_"+myTheme+".svg"} alt={text} title={text}/></div>
}

export default DarkSwitcher