import React from 'react'

import { isDevMode } from 'utils'
import { cnContentAd } from 'pages/App/components/Ads/ContentAd'
// import { useYandexAdScript } from 'pages/App/components/Ads/hooks'

import { cnContentBottomAd } from '..'
import './ContentBottomAdYandex.scss'

/**
 * Рекламные цсс-классы генерируются без @bem-react/classname,
 * чтобы получалось их захешировать
 */
const ContentBottomAdYandex = () => {
  const ID = 'R-A-154032-5'
  const development = isDevMode()

  // Убрал, потому что теперь реклама с АПИ
  // useYandexAdScript(ID)

  return development ? (
    <div
      className={cnContentAd('Item', { development }, [
        cnContentBottomAd('Yandex'),
      ])}
    />
  ) : (
    <div className="ContentBottomAd-Yandex" id={`yandex_rtb_${ID}`} />
  )
}

export default ContentBottomAdYandex
