import { useEffect } from 'react'

import { getComponentByClass } from 'utils'

import { cnDeskList } from '..'

export const useDeskListHideOnTouch = () => {
  const cnDeskListFixedOnTop = `${cnDeskList()}_fixedOnTop`

  useEffect(() => {
    const deskList = getComponentByClass(document, cnDeskList())

    deskList?.classList.add(cnDeskListFixedOnTop)

    return () => {
      deskList?.classList.remove(cnDeskListFixedOnTop)
    }
  }, [cnDeskListFixedOnTop])
}
