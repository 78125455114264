import React, { FC } from 'react'

import { isDevMode } from 'utils'
import { cnContentAd } from 'pages/App/components/Ads/ContentAd'
import { useYandexAdScript } from 'pages/App/components/Ads/hooks'

import { cnSidebarBottomAd } from '..'
import './SidebarBottomAdYandex.scss'

const SidebarBottomAdYandex: FC = () => {
  const ID = 'R-A-154032-4'
  const development = isDevMode()

  useYandexAdScript(ID)

  return development ? (
    <div
      className={cnContentAd('Item', { development }, [
        cnSidebarBottomAd('Yandex', { development }),
      ])}
    />
  ) : (
    <div className={cnSidebarBottomAd('Yandex')} id={`yandex_rtb_${ID}`} />
  )
}

export default SidebarBottomAdYandex
