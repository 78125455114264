import React, { FC, useState } from 'react'

import { cnChatPopup } from '../..'
import AddContactForm from './AddContactForm'
import AddContactToggle from './AddContactToggle'

const AddContact: FC = () => {
  const [open, isOpen] = useState(false)

  return (
    <div className={cnChatPopup('AddContact')}>
      {open ? <AddContactForm /> : <AddContactToggle isOpen={isOpen} />}
    </div>
  )
}

export default AddContact
