const STORE_KEY = 'TEACHERTEST'

export const FETCH_TESTS = `${STORE_KEY}/FETCH_TESTS`
export const FETCH_TEST = `${STORE_KEY}/FETCH_TEST`
export const INIT_TESTS = `${STORE_KEY}/INIT_TESTS`
export const INIT_TEST = `${STORE_KEY}/INIT_TEST`
export const INIT_GROUPS = `${STORE_KEY}/INIT_GROUPS`
export const INIT_THEMES = `${STORE_KEY}/INIT_THEMES`
export const INIT_THEME = `${STORE_KEY}/INIT_THEME`
export const INIT_ASSIGNS = `${STORE_KEY}/INIT_ASSIGNS`
export const MARK_ALL_CHECK_TEST = `${STORE_KEY}/MARK_ALL_CHECK_TEST`

export const SET_TEST = `${STORE_KEY}/SET_TEST`
export const SET_THEME = `${STORE_KEY}/SET_THEME`

export const REMOVE_THEME = `${STORE_KEY}/REMOVE_THEME`
export const REMOVE_TEST = `${STORE_KEY}/REMOVE_TEST`
export const REMOVE_TEST_DONE = `${STORE_KEY}/REMOVE_TEST_DONE`
export const SET_ASSIGN = `${STORE_KEY}/SET_ASSIGN`
export const NEW_THEME = `${STORE_KEY}/NEW_THEME`

export const SET_ORDER = `${STORE_KEY}/SET_ORDER`
export const SET_ERROR = `${STORE_KEY}/SET_ERROR`

