import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { cn } from '@bem-react/classname'

import { selectAuthUser } from 'modules/Auth'
import DeskCard from 'pages/App/components/DeskCard'
import Portal from 'components/Portal'

import { useHelpers } from './PinnedTasks-Button.hooks'
import PinnedTasks from '../Popup'
import './PinnedTasks-Button.scss'

import Icon from './PinnedTasks-Button.assets/briefcase.png'

const PinnedTasksButton: FC = () => {
  const cnPinnedTasks = cn('PinnedTasks')
  const user = useSelector(selectAuthUser)
  const { visible, toggleVisibility } = useHelpers()

  return (
    <>
      <DeskCard
        className={cnPinnedTasks('DescCard')}
        toggleVisibility={toggleVisibility}
      >
        <img
          className={cnPinnedTasks('Icon')}
          src={Icon}
          width="24px"
          height="24px"
          alt="Отложенные задания для создания своей работы"
          aria-hidden="true"
        />
        <span className={cnPinnedTasks('Caption')}>
          Заданий: {user!.pinnedTaskAmount}
        </span>
      </DeskCard>

      <Portal>
        {visible && <PinnedTasks toggleVisibility={toggleVisibility} />}
      </Portal>
    </>
  )
}

export default PinnedTasksButton
