import React, { FC } from 'react'
import { useForm } from 'react-hook-form'
import { compose } from '@bem-react/core'
import { useSelector } from 'react-redux'

import { useMobileVersion } from 'hooks'
import ButtonBase, { withViewDefault, withSizeL } from 'components/Button'

import { cnChangePasswordPopup } from '..'
import ChangePasswordPopupFieldPart from './Fieldset'
import ChangePasswordPopupFormAlert from './FormAlert'
import { useInputList, useOnSubmit } from './ChangePasswordPopup-Form.hooks'
import './ChangePasswordPopup-Form.scss'
import { selectorIsUAExam } from 'modules/Exam/selectors'

const Button = compose(withViewDefault, withSizeL)(ButtonBase)

interface IChangePasswordPopupFormProps {
  visible: boolean
}

const ChangePasswordPopupForm: FC<IChangePasswordPopupFormProps> = ({
  visible,
}) => {
  const {
    register,
    getValues,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm()
  const inputList = useInputList(register, getValues)
  const onSubmit = useOnSubmit(setError)
  const isUAExam = useSelector(selectorIsUAExam)

  const isMobile = useMobileVersion()
  const buttonSize = isMobile ? 'l' : undefined

  return (
    <form
      className={cnChangePasswordPopup('Form', { visible })}
      action="/newapi/password/"
      method="POST"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className={cnChangePasswordPopup('FieldPart')}>
        <ChangePasswordPopupFieldPart inputList={inputList} />

        <ChangePasswordPopupFormAlert
          errorText={
            errors['new-password']?.message ||
            errors['confirm-password']?.message
          }
          isSubmitSuccessful={isSubmitSuccessful}
        />
      </div>

      <div className={cnChangePasswordPopup('ButtonPart')}>
        <Button
          className={cnChangePasswordPopup('Button')}
          view="default"
          size={buttonSize}
          disabled={isSubmitting}
        >
          {isUAExam ? `Зберегти` : `Сохранить`}
        </Button>
      </div>
    </form>
  )
}

export default ChangePasswordPopupForm
