import { combineReducers } from 'redux'
import {
  ActionFunctionAny,
  Action,
  ReduxCompatibleReducer,
  createAction,
  handleActions,
} from 'redux-actions'

import { ICommentFromBackend } from './types'
import * as constants from './constants'

interface IFetchCommentsActionPayload {
  rightPage: number
  withScrollToComment?: boolean
}
export interface IFetchCommentsAction {
  type: typeof constants.FETCH_COMMENTS
  payload: IFetchCommentsActionPayload | undefined
}
export interface IFetchNewCommentsAction {
  type: typeof constants.FETCH_NEW_COMMENTS
  payload: IFetchCommentsActionPayload
}

// Actions
export const fetchComments: ActionFunctionAny<any> = createAction(
  constants.FETCH_COMMENTS
)
export const fetchNewComments: ActionFunctionAny<IFetchNewCommentsAction> = createAction(
  constants.FETCH_NEW_COMMENTS
)
export const clearComments: ActionFunctionAny<any> = createAction(
  constants.CLEAR_COMMENTS
)

export const setCommentsData: ActionFunctionAny<Action<Array<
  ICommentFromBackend
> | null>> = createAction(constants.SET_COMMENTS_DATA)
export const addCommentsData: ActionFunctionAny<Action<Array<
  ICommentFromBackend
> | null>> = createAction(constants.ADD_COMMENTS_DATA)

export const setCommentsCount: ActionFunctionAny<Action<number>> = createAction(
  constants.SET_COMMENTS_COUNT
)
export const setCommentsCountOnFirstPage: ActionFunctionAny<Action<
  number
>> = createAction(constants.SET_COMMENTS_COUNT_ON_FIRST_PAGE)

export const setCommentsRightPage: ActionFunctionAny<Action<
  number
>> = createAction(constants.SET_COMMENTS_RIGHT_PAGE)

interface ISetCommentsPreviousPagePositionMapPayload {
  page: number
  position: number
}
export const setCommentsPreviousPagePositionMap: ActionFunctionAny<Action<
  ISetCommentsPreviousPagePositionMapPayload
>> = createAction(constants.SET_COMMENTS_PREVIOUS_PAGE_POSITION_MAP)
export const setCommentsCurrentPagePosition: ActionFunctionAny<Action<
  number
>> = createAction(constants.SET_COMMENTS_CURRENT_PAGE_POSITION)
export const setCommentsNextPagePosition: ActionFunctionAny<Action<
  number
>> = createAction(constants.SET_COMMENTS_NEXT_PAGE_POSITION)

export const setCommentsTotalPages: ActionFunctionAny<Action<
  number
>> = createAction(constants.SET_COMMENTS_TOTAL_PAGES)

export const addCommentsFetchedPage: ActionFunctionAny<Action<
  number
>> = createAction(constants.ADD_COMMENTS_FETCHED_PAGE)

// Reducers
const dataInitialState = null
const data: ReduxCompatibleReducer<
  Array<ICommentFromBackend> | null,
  Array<ICommentFromBackend> | null
> = handleActions(
  {
    [constants.SET_COMMENTS_DATA]: (
      _state: Array<ICommentFromBackend> | null,
      action
    ) => action.payload,
    [constants.ADD_COMMENTS_DATA]: (
      _state: Array<ICommentFromBackend> | null,
      action
    ) => {
      const oldData = _state ? _state : []
      const newData = oldData.concat(action.payload!)

      return newData
    },
    [constants.CLEAR_COMMENTS]: () => dataInitialState,
  },
  dataInitialState
)

const countInitialState = 0
const count: ReduxCompatibleReducer<number, number> = handleActions(
  {
    [constants.SET_COMMENTS_COUNT]: (_state: number, action) => action.payload,
    [constants.CLEAR_COMMENTS]: () => countInitialState,
  },
  countInitialState
)

const countOnFirstPageInitialState = 0
const countOnFirstPage: ReduxCompatibleReducer<number, number> = handleActions(
  {
    [constants.SET_COMMENTS_COUNT_ON_FIRST_PAGE]: (_state: number, action) =>
      action.payload,
    [constants.CLEAR_COMMENTS]: () => countOnFirstPageInitialState,
  },
  countInitialState
)

const rightPageInitialState = 0
const rightPage: ReduxCompatibleReducer<number, number> = handleActions(
  {
    [constants.SET_COMMENTS_RIGHT_PAGE]: (_state: number, action) =>
      action.payload,
    [constants.CLEAR_COMMENTS]: () => rightPageInitialState,
  },
  rightPageInitialState
)

const previousPagePositionMapInitialState: Map<number, number> = new Map()
const previousPagePositionMap: ReduxCompatibleReducer<
  Map<number, number>,
  ISetCommentsPreviousPagePositionMapPayload
> = handleActions(
  {
    [constants.SET_COMMENTS_PREVIOUS_PAGE_POSITION_MAP]: (
      _state: Map<number, number>,
      action: {
        payload: ISetCommentsPreviousPagePositionMapPayload
      }
    ) => {
      const { page, position } = action.payload
      const newMap = new Map([..._state])

      newMap.set(page, position)

      return newMap
    },
    [constants.CLEAR_COMMENTS]: () => previousPagePositionMapInitialState,
  },
  previousPagePositionMapInitialState
)

const currentPagePositionInitialState = 0
const currentPagePosition: ReduxCompatibleReducer<
  number,
  number
> = handleActions(
  {
    [constants.SET_COMMENTS_CURRENT_PAGE_POSITION]: (_state: number, action) =>
      action.payload,
    [constants.CLEAR_COMMENTS]: () => currentPagePositionInitialState,
  },
  currentPagePositionInitialState
)

const nextPagePositionInitialState = 0
const nextPagePosition: ReduxCompatibleReducer<number, number> = handleActions(
  {
    [constants.SET_COMMENTS_NEXT_PAGE_POSITION]: (_state: number, action) =>
      action.payload,
    [constants.CLEAR_COMMENTS]: () => nextPagePositionInitialState,
  },
  nextPagePositionInitialState
)

const totalPagesInitialState = 0
const totalPages: ReduxCompatibleReducer<number, number> = handleActions(
  {
    [constants.SET_COMMENTS_TOTAL_PAGES]: (_state: number, action) =>
      action.payload,
    [constants.CLEAR_COMMENTS]: () => totalPagesInitialState,
  },
  totalPagesInitialState
)

const fetchedPagesInitialState: Set<number> = new Set()
const fetchedPages: ReduxCompatibleReducer<Set<number>, number> = handleActions(
  {
    [constants.ADD_COMMENTS_FETCHED_PAGE]: (
      _state: Set<number>,
      action: { payload: number }
    ) => {
      const newSet = new Set([..._state])

      newSet.add(action.payload)

      return newSet
    },
  },
  fetchedPagesInitialState
)

export const initialState: CommentsState = {
  data: dataInitialState,
  count: countInitialState,
  countOnFirstPage: countOnFirstPageInitialState,
  rightPage: rightPageInitialState,
  previousPagePositionMap: previousPagePositionMapInitialState,
  currentPagePosition: currentPagePositionInitialState,
  nextPagePosition: nextPagePositionInitialState,
  totalPages: totalPagesInitialState,
  fetchedPages: fetchedPagesInitialState,
}

const comments = combineReducers({
  data,
  count,
  countOnFirstPage,
  rightPage,
  previousPagePositionMap,
  currentPagePosition,
  nextPagePosition,
  totalPages,
  fetchedPages,
})

export type CommentsState = ReturnType<typeof comments>
export default comments
