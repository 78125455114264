import React, { FC } from 'react'

import { cnContentAd } from 'pages/App/components/Ads/ContentAd'

import { cnSidebarBottomAd } from '..'
import './SidebarBottomAdGoogle.scss'

const SidebarBottomAdGoogle: FC = () => (
  <div
    className={cnContentAd('Item', { development: true }, [
      cnSidebarBottomAd('Google', { development: true }),
    ])}
  />
)

export default SidebarBottomAdGoogle
