import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {
  selectChatListEl,
  selectChatDialogInfoToId,
  fetchChatDialog,
  selectChatDialog,
} from 'modules/Chat'

export const useFetchData = () => {
  const [dialogBeingRead, isDialogBeingRead] = useState(false)
  const id = useSelector(selectChatDialogInfoToId)
  const dialog = useSelector(selectChatDialog)
  const listEl = useSelector(selectChatListEl)
  const dispatch = useDispatch()

  useEffect(() => {
    if (listEl && !dialogBeingRead) {
      listEl.scrollTop = listEl.scrollHeight
    }

    const fetchData = () => {
      if (listEl) {
        isDialogBeingRead(
          listEl.scrollHeight - listEl.scrollTop !== listEl.clientHeight
        )
      }

      dispatch(fetchChatDialog(id))
    }

    const interval = setInterval(() => {
      fetchData()
    }, 5000)

    return () => {
      clearInterval(interval)
    }
  }, [dialog, dialogBeingRead, dispatch, id, listEl])
}
