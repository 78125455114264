import axios from 'axios'

import { getApiKey } from 'utils'

export const searchInCatalog = async (text: string) => {

  return await axios(`/newapi/catalog-search/${getApiKey()}`, {
    params: {
      text,
    },
  })
}
