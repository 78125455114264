import React, { FC, useState, useCallback, useEffect } from 'react'
import { cn } from '@bem-react/classname'
import cx from 'classnames'
import { compose } from '@bem-react/core'

import TextBase, { withHtml } from 'components/Text'
import './Alert.scss'
import { useDispatch } from 'react-redux'
import { deleteUnreadMessageApi } from '../../modules/Chat'

const Text = compose(withHtml)(TextBase)

interface IAlertProps {
  className?: string
  id?: number
  title?: string
  fromId?: number
  text: string
  date?: string
  extraClickFunction?: Function
  closeAllMessages: boolean,
  hideAllMessages: boolean,
}

const Alert: FC<IAlertProps> = ({
                                  className,
                                  id,
                                  title = 'Система оповещений',
                                  fromId,
                                  text,
                                  date,
                                  extraClickFunction,
                                  closeAllMessages,
                                  hideAllMessages
                                }) => {
  const cnAlert = cn('Alert')
  const [visible, isVisible] = useState(true)
  const isAdmin = fromId === 0
  const dispatch = useDispatch()

  const handleClose = useCallback(
    () => {
      window.event!.stopPropagation()
      // if (extraCloseFunction) extraCloseFunction(e)
      dispatch(deleteUnreadMessageApi(id))
      isVisible(false)
    },
    [dispatch, id],
  )

  const handleClick = useCallback(
    () => {
      if (extraClickFunction) extraClickFunction(window.event)

      isVisible(false)
    },
    [extraClickFunction],
  )

  useEffect(() => {
    if (closeAllMessages) isVisible(false);
  }, [closeAllMessages])
  useEffect(() => {
    isVisible(!hideAllMessages);
  }, [hideAllMessages])


  return (
    <>
      {visible && (
        <div
          className={cx(cnAlert(), className)}
          data-name={title}
          data-id={fromId}
          onClick={extraClickFunction && handleClick}
        >
          <header className={cnAlert('Header')}>
            <div className={cnAlert('Title')}>{title}</div>
            {date && <div className={cnAlert('Date')}>{date}</div>}
            <div
              className={cnAlert('CloseButton')}
              data-alert-id={id}
              onClick={handleClose}
            >
              ×
            </div>
          </header>

          <Text className={cnAlert('Text')} html={isAdmin} linkClick={!isAdmin}>{text}</Text>

        </div>
      )}
    </>
  )
}

export default Alert
