import axios from 'axios'

import { IDialogInfo } from '../../../Dialog'
import { getApiKey } from 'utils'

export const addContact = async (to: string): Promise<IDialogInfo | null> => {
  const body = 'Пользователь добавил вас в свой список контактов'

  const formData = new FormData()
  formData.append('to', to)
  formData.append('body', body)

  try {
    const response = await axios({
      method: 'post',
      url: `/get_messages${getApiKey()}`,
      params: { ajax: 1, a: 'send' },
      withCredentials: true,
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    })
    const data = response.data

    const dialogInfo = data
      ? {
          to: data.name,
          toId: data.id,
        }
      : null

    return dialogInfo
  } catch (error) {
    console.error(error)
  }

  return null
}
