import React, { FC } from 'react'
import { cn } from '@bem-react/classname'

import { useMobileVersion } from 'hooks'

import ProfileWidget from './ProfileWidget'
import Bots from './Bots'
import News from './News'
import Thieves from './Thieves'
import OurSources from './OurSources'
import './SidebarInfo.scss'

interface ISidebarInfoProps {
  className?: string
}

export const cnSidebarInfo = cn('SidebarInfo')

const SidebarInfo: FC<ISidebarInfoProps> = ({ className }) => {
  const isMobile = useMobileVersion()

  return (
    <div className={cnSidebarInfo(null, [className])}>
      {!isMobile && (
        <ProfileWidget
          className={cnSidebarInfo('Item', [cnSidebarInfo('ProfileWidget')])}
        />
      )}

      <Bots />

      <News />

      <Thieves />

      <OurSources className={cnSidebarInfo('Item')} />
    </div>
  )
}

export default SidebarInfo
