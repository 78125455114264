import React from 'react'
import { useSelector } from 'react-redux'
import cx from 'classnames'

import { ISubject, ISubjectType } from 'utils/data/subjectList'
import { selectExam } from 'modules/Exam'

import { isSubjectActive, getSubjectUrl } from './utils'
import { cnSubjectMenu } from '..'

interface ISubjectMenuItemProps extends ISubject {
  location?: string
}

const SubjectMenuItem = ({
  title,
  name,
  types,
  disabled,
  location,
}: ISubjectMenuItemProps) => {
  const exam = useSelector(selectExam)

  function get_title() {
   if (exam?.name === 'ent' && title.includes('Математическая грамотность')) {
      return 'Матграмотность'
    } else {
      return title
    }
  }

  if (types) {
    return (
      <div className="SubjectMenu-Link SubjectMenu_desktop-Link SubjectMenu-PopupBlock">
        <div
          className={cx(
            'SubjectMenu-Tab SubjectMenu_desktop-Tab SubjectMenu-PopupTitle',
            {
              'SubjectMenu_desktop-Tab_selected': isSubjectActive(
                location,
                name,
                types
              ),
            }
          )}
        >
          <label htmlFor={'subjectmenu_' + location}>≡ {title}</label>
        </div>
        <input
          type="checkbox"
          id={'subjectmenu_' + location}
          className="SubjectMenu-Checkbox"
        />
        <div className="SubjectMenu-Popup">
          {types.map((type: ISubjectType, typeI: number) => (
            <a
              className="SubjectMenu-Link SubjectMenu_desktop-Link"
              href={getSubjectUrl(exam, type.name)}
              rel="nofollow"
              key={`${type.name}-${typeI}`}
            >
              <div className="SubjectMenu-Tab SubjectMenu_desktop-Tab SubjectMenu-PopupTab">
                <span className="SubjectMenu-PopupLink SubjectMenu-PopupLink_mobile">
                  {exam?.name === 'vpr' ? type.title : title + ' ' + type.title.toLowerCase()}
                </span>
                <span
                  className={cx(
                    'SubjectMenu-PopupLink SubjectMenu-PopupLink_desktop',
                    {
                      'SubjectMenu-PopupLink_selected': isSubjectActive(
                        location,
                        type.name
                      ),
                    }
                  )}
                >
                  {type.title}
                </span>
              </div>
            </a>
          ))}
        </div>
      </div>
    )
  }

  return (
    <a
      className={cnSubjectMenu('Link', { disabled }, [
        'SubjectMenu_desktop-Link',
      ])}
      href={getSubjectUrl(exam, name)}
      rel="nofollow"
      aria-disabled={disabled}
    >
      <div
        className={cx(
          cnSubjectMenu('Tab', { disabled }),
          'SubjectMenu_desktop-Tab',
          {
            'SubjectMenu_desktop-Tab_selected': isSubjectActive(location, name),
          }
        )}
      >
        {get_title()}
      </div>
    </a>
  )
}

export default SubjectMenuItem
