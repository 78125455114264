export const getCommentPositionY = (commentIndex: number): number => {
  const comment = document.querySelector(
    `div[name=CommentAnchorByIndex_${commentIndex}]`
  )
  if (!comment) {
    console.error(comment)
    return 0
  }

  const commentCoordinates = comment.getBoundingClientRect()
  const position = Math.floor(commentCoordinates!.top + window.pageYOffset)

  return position
}
