import { ITopic } from 'components/ConstructorForm/Buttons'

interface Params {
  list: ITopic[]
  index: number
  value: number
  buildNewValue: (value: number) => number
  subtopicIndex?: number
}

export const updateTopicValue = ({
  list,
  index,
  value,
  buildNewValue,
  subtopicIndex,
}: Params): number => {
  let oldValue = 0
  let newValue = 0

  const isSubtopic = subtopicIndex !== undefined
  if (isSubtopic) {
    const subtopic = list[index].subtopics![subtopicIndex!]

    oldValue = subtopic.value!
    newValue = buildNewValue(Number(oldValue))
    subtopic.value! = newValue
  } else {
    newValue = buildNewValue(Number(value))
    list[index].value = newValue
  }

  return newValue
}
