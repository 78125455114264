import { withBemMod } from '@bem-react/core'

import { cnLink2 } from '../Link2'
import './Link2_view_pseudo.scss'

export interface ILink2ViewPseudoProps {
  /**
   * Внешний вид ссылки
   */
  view?: 'pseudo'
}

/**
 * Модификатор, отвечающий за внешний вид ссылки
 * @param {ILink2ViewPseudoProps} props
 */
export const withViewPseudo = withBemMod<ILink2ViewPseudoProps>(cnLink2(), {
  view: 'pseudo',
})
