import { combineReducers } from 'redux'
import {
  ActionFunctionAny,
  Action,
  ReduxCompatibleReducer,
  createAction,
  handleActions,
} from 'redux-actions'

import { IBot } from 'pages/App/components/Sidebar/SidebarInfo/Bots'
import * as constants from './constants'

export interface IAppsStatusAction {
  type: typeof constants.FETCH_APPS
  payload: Array<IBot> | null
}

// Actions
export const fetchApps: ActionFunctionAny<Action<Array<
  IBot
> | null>> = createAction(constants.FETCH_APPS)

// Reducers
const list: ReduxCompatibleReducer<
  Array<IBot> | null,
  Array<IBot> | null
> = handleActions(
  {
    [constants.FETCH_APPS]: (_state: Array<IBot> | null, action) =>
      action.payload,
  },
  null
)

const fetched: ReduxCompatibleReducer<boolean, boolean> = handleActions(
  {
    [constants.FETCH_APPS]: () => true,
  },
  false
)

export const initialState: AppsState = {
  list: null,
  fetched: false,
}

const apps = combineReducers({
  list,
  fetched,
})

export type AppsState = ReturnType<typeof apps>
export default apps
