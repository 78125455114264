import { Dispatch, SetStateAction, useEffect } from 'react'

import { IFriend } from '..'
import { getFriendList } from '../api'

export const useFetchData = (
  setContactList: Dispatch<SetStateAction<IFriend[] | null>>
) => {
  useEffect(() => {
    const fetchData = async () => {
      const response = await getFriendList()

      setContactList(response.data)
    }

    fetchData()
  }, [setContactList])
}
