import React, { useState, useCallback } from 'react'
import { cn } from '@bem-react/classname'
import cx from 'classnames'

import { AdParts } from 'modules/Ads'
import MobileMenu from 'components/MobileMenu'
import AdZone from 'pages/App/components/Ads/AdZone'

import SidebarMenu from './SidebarMenu'
import Search from './SidebarSearch'
import SidebarInfo from './SidebarInfo'
import SidebarBottomAd from './SidebarBottomAd'
import './Sidebar.scss'
import { useSelector } from 'react-redux'
import { selectorIsUAExam } from '../../../../modules/Exam'

const Sidebar = () => {
  const cnSidebar = cn('Sidebar')
  const [mobileSidebar, setMobileSidebar] = useState(false)
  const isUAExam = useSelector(selectorIsUAExam)

  const toggleMobileSidebar = useCallback(() => {
    setMobileSidebar(!mobileSidebar)
  }, [mobileSidebar])

  return (
    <>
      <MobileMenu
        className={cnSidebar('MobileMenu', {open: mobileSidebar})}
        onClick={toggleMobileSidebar}
      >
        {isUAExam ? `Меню, новини` : `Меню, новости`}
      </MobileMenu>

      <aside
        className={cx(cnSidebar(), 'PageLayout-Sidebar', {
          Sidebar_shown: mobileSidebar,
        })}
      >
        <AdZone parentClassName={cnSidebar()} part={AdParts.LEFT_TOP} />

        <SidebarMenu className={cnSidebar('Menu')} />

        <Search className={cnSidebar('Search')} />

        <SidebarInfo className={cnSidebar('Info')} />

        <SidebarBottomAd />
      </aside>
    </>
  )
}

export default Sidebar
