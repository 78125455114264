import React, { FC, useRef } from 'react'

import { ISubtopic } from 'components/ConstructorForm/Buttons'
import { useCheckboxHandler } from './hooks'
import Checkbox from '../../../Checkbox'

interface ISubtopicProps {
  page: string
  topicI: number
  subtopics: Array<ISubtopic>
}

const CheckAll: FC<ISubtopicProps> = ({ page, topicI, subtopics }) => {
  const subtopicLabel = useRef<HTMLLabelElement | null>(null)

  const handleCheckboxChange = useCheckboxHandler(page, subtopicLabel)

  return (
    <label
      className="Link_wrap ConstructorForm-TopicName CheckAll Label"
      // For and id for work in IE 11
      htmlFor={`topicall${topicI}`}
      ref={subtopicLabel}
    >
      <div className="ConstructorForm-TopicNumber">
        <Checkbox
          fakeCheckboxClassName="ConstructorForm-TopicSubCheckbox"
          name={`topicall${topicI}`}
          id={`topicall${topicI}`}
          data-topic-i={topicI}
          data-disabled={
            subtopics
              .map((subtopic) => subtopic.checked)
              .reduce((prev, next) => prev || next) !==
            subtopics
              .map((subtopic) => subtopic.checked)
              .reduce((prev, next) => prev && next)
          }
          value={subtopics
            .map((subtopic) => subtopic.checked)
            .reduce((prev, next) => prev || next)}
          onChange={handleCheckboxChange}
        />
      </div>

      <div className="ConstructorForm-TopicDesc"></div>
    </label>
  )
}

export default CheckAll
