import { combineReducers } from 'redux'
import {
  ActionFunctionAny,
  Action,
  ReduxCompatibleReducer,
  createAction,
  handleActions,
} from 'redux-actions'

import * as constants from './constants'

export interface IAppsStatusAction {
  type: typeof constants.SET_EMAIL
  payload: string
}

// Actions
export const setUserEmail: ActionFunctionAny<Action<
  IAppsStatusAction
>> = createAction(constants.SET_EMAIL)

// Reducers
const emailInitialState = ''
const email: ReduxCompatibleReducer<string, string> = handleActions(
  {
    [constants.SET_EMAIL]: (_state: string, action) => action.payload,
  },
  emailInitialState
)

export const initialState: UserState = {
  email: emailInitialState,
}

const user = combineReducers({
  email,
})

export type UserState = ReturnType<typeof user>
export default user
