import { useEffect, useCallback } from 'react'

import { OnClose } from '..'

export const useOverlay = (onClose: OnClose) => {
  const handleClose = useCallback(
    (e: KeyboardEvent) => {
      const { key } = e

      if (key === 'Escape' || key === 'Esc') {
        onClose(e)
      }
    },
    [onClose]
  )

  useEffect(() => {
    document.body.addEventListener('keyup', handleClose)

    return () => {
      document.body.removeEventListener('keyup', handleClose)
    }
  }, [handleClose])
}
