import { getMonth } from '.'

/**
 * Форматирует дату
 *
 * @param {string} date — Дата в ISO
 * @returns {string} — Дата в удобном человеку формате
 */
export const formatDate = (dateISO: string): string => {
  const date = new Date(dateISO)

  const day = date.getDate()
  const month = getMonth(date)
  const year = date.getFullYear()

  return `${day} ${month} ${year}`
}
