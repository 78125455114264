import React, { FC, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { cn } from '@bem-react/classname'
import { classnames as cx } from '@bem-react/classnames'

import { openChat } from 'modules/Chat'
import './ChatButton.scss'

const ChatButton: FC = () => {
  const chatButton = cn('ChatButton')
  const dispatch = useDispatch()

  const handleOpen = useCallback(() => {
    dispatch(openChat())
  }, [dispatch])

  return (
    <div className={cx(chatButton())} onClick={handleOpen}>
      Чат
    </div>
  )
}

export default ChatButton
