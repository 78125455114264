import React, { FC, useState, useCallback, useRef, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { compose } from '@bem-react/core'

import { openChatDialog } from 'modules/Chat'
import { shake } from 'utils'
import Input from 'components/Input'
import ButtonBase, { withViewDefault } from 'components/Button'

import { cnChatPopup } from '../../..'
import { addContact } from '.'

const Button = compose(withViewDefault)(ButtonBase)

const AddContactForm: FC = () => {
  const [email, setEmail] = useState('')
  const [loading, isLoading] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)
  const dispatch = useDispatch()

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault()

      isLoading(true)
      const dialogInfo = await addContact(email)

      if (dialogInfo) {
        isLoading(false)
        dispatch(openChatDialog(dialogInfo))
      } else {
        shake(inputRef)
        isLoading(false)

        const alertText =
          'Мы не нашли вашего собеседника. Возможно, он входит через Вконтакте или не знает о нашем сайте'
        console.error(alertText)
      }
    },
    [dispatch, email]
  )

  useEffect(() => {
    if (inputRef) {
      inputRef.current!.focus()
    }
  }, [inputRef])

  return (
    <form className={cnChatPopup('AddContactForm')} onSubmit={handleSubmit}>
      <Input
        className={cnChatPopup('AddContactInput')}
        name="email"
        value={email}
        setValue={setEmail}
        placeholder="Эл. почта"
        innerRef={inputRef}
      />

      <Button view="default" disabled={loading}>
        Добавить
      </Button>
    </form>
  )
}

export default AddContactForm
