import React, {
  FC,
  ChangeEvent,
  MouseEvent,
  useState,
  useRef,
  useCallback,
} from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { cn } from '@bem-react/classname'
import { compose } from '@bem-react/core'

import { ErrorType } from './Alert/TeacherVariant-Alert'
import { selectExam, selectorIsUAExam } from 'modules/Exam'
import { getApiKey, shake } from 'utils'
import { redirectToTest } from './utils'
import TitleBase, { withLevel2 } from 'components/Title'
import Text from 'components/Text'
import 'components/Form/Form.scss'
import TeacherVariantAlert from './Alert'
import Input from 'components/Input'
import ButtonBase, { withViewDefault } from 'components/Button'
import './TeacherVariant.scss'

const Title = compose(withLevel2)(TitleBase)
const Button = compose(withViewDefault)(ButtonBase)
export const cnTeacherVariant = cn('TeacherVariant')

const TeacherVariant: FC = () => {
  const [id, setId] = useState('')
  const [foundId, setFoundId] = useState(0)
  const [error, setError] = useState<ErrorType>('NO')
  const [loading, isLoading] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)
  const exam = useSelector(selectExam)
  const isUAExam = useSelector(selectorIsUAExam)

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    let { value: newId } = e.target

    const hasNumber = /\d/.test(newId)
    if (hasNumber) {
      newId = newId.replace(/\D/g, '')
    }

    const hasLetter = /\D/.test(newId)
    if (hasLetter) {
      return shake(inputRef)
    }

    setId(newId)
  }, [])

  const handleClick = useCallback(
    async (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()

      setError('NO')
      isLoading(true)

      if (id) {
        try {
          const response = await axios(
            `/newapi/teacher-variant/${getApiKey()}`,
            {
              params: {
                id,
              },
            }
          )
          const { success: taskExist, teacher: isTeacher } = response.data

          if (taskExist && isTeacher) {
            setError('NO')
            redirectToTest(exam?.domain, parseInt(id))
          } else if (taskExist && !isTeacher) {
            setFoundId(parseInt(id))
            setError('NOT_TEACHER')
          } else {
            setError('NOT_FOUND')
          }
        } catch (error) {
          setError('ERROR')
          console.error(error)
        }
      } else {
        inputRef!.current!.focus()
      }

      isLoading(false)
    },
    [exam, id]
  )

  return (
    <div className="TeacherVariant">
      <Title level="2">{isUAExam ? `Варіант вчителя` : `Вариант учителя`}</Title>

      <Text className="Section-Desc">
        {isUAExam ? `Якщо ваш шкільний вчитель склав роботу та повідомив вам номер, введіть його тут.` : `Если ваш школьный учитель составил работу и сообщил вам номер,
        введите его здесь.`}
      </Text>

      <form className="Form" action="/test" method="POST">
        <div className="Form-Row_oneLine">
          <Input
            className="Form-Input_oneLine"
            type="tel"
            name="id"
            value={id}
            placeholder={isUAExam ? `Номер варіанта` : `Номер варианта`}
            innerRef={inputRef}
            handleChange={handleChange}
          />
          <Button
            className="TeacherVariant-Button"
            view="default"
            disabled={loading}
            handleClick={handleClick}
          >
            {isUAExam ? `Відкрити` : `Открыть`}
          </Button>
        </div>

        <TeacherVariantAlert errorType={error} id={foundId} />
      </form>
    </div>
  )
}

export default TeacherVariant
