import {
  useState,
  useCallback,
  RefObject,
  Dispatch,
  SetStateAction,
} from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { wasRecoveryEmailPopupSent } from 'modules/RecoveryEmailPopup'
import { isEmail, showFailureAnimation } from 'utils'

import {
  SEND_RECOVERY_EMAIL_RESULT_ERROR,
  sendRecoveryEmail,
} from '../RecoveryEmailPopup-Form.api'
import { RecoveryEmailPopupFormAlert } from '..'
import { selectorIsUAExam } from 'modules/Exam/selectors'

export const useHandleSubmit = (
  email: string,
  inputRef: RefObject<HTMLInputElement>,
  setAlertText: Dispatch<SetStateAction<string>>
) => {
  const [loading, isLoading] = useState(false)
  const dispatch = useDispatch()
  const isUAExam = useSelector(selectorIsUAExam)

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault()

      isLoading(true)

      if (email) {
        if (isEmail(email)) {
          localStorage.setItem('email', email)


          try {
            const result = await sendRecoveryEmail({ email })

            if (result === SEND_RECOVERY_EMAIL_RESULT_ERROR) {
              dispatch(wasRecoveryEmailPopupSent(false))

              showFailureAnimation(inputRef, isLoading)
              setAlertText(isUAExam ? RecoveryEmailPopupFormAlert.NO_USER_FOUND.ua : RecoveryEmailPopupFormAlert.NO_USER_FOUND.rus)
            } else {
              dispatch(wasRecoveryEmailPopupSent(true))
            }
          } catch {
            dispatch(wasRecoveryEmailPopupSent(false))

            showFailureAnimation(inputRef, isLoading)
            setAlertText(isUAExam ? RecoveryEmailPopupFormAlert.SERVER_ERROR.ua : RecoveryEmailPopupFormAlert.SERVER_ERROR.rus)
          }
        } else {
          showFailureAnimation(inputRef, isLoading)
          setAlertText(isUAExam ? RecoveryEmailPopupFormAlert.MISTAKE.ua : RecoveryEmailPopupFormAlert.MISTAKE.rus)
        }
      } else {
        showFailureAnimation(inputRef, isLoading)
        setAlertText(isUAExam ? RecoveryEmailPopupFormAlert.EMPTY.ua : RecoveryEmailPopupFormAlert.EMPTY.rus)
      }
    },
    [dispatch, email, inputRef, setAlertText, isUAExam]
  )

  return { loading, handleSubmit }
}
