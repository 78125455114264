interface ITestPartTitles {
  [short: string]: string
  detailed: string
}

export const testPartTitles: ITestPartTitles = {
  test: 'Выбор ответа',
  short: 'Краткий ответ',
  detailed: 'Развернутый ответ',
}
