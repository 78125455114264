import { useCallback } from 'react'
import { SubmitHandler } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'

import { fetchAuthStatusRequest } from 'modules/Auth'

import { ChangePasswordPopupAlert } from '../..'
import { changePassword } from '../ChangePasswordPopup-Form.api'
import { selectorIsUAExam } from 'modules/Exam/selectors'

type FormValues = {
  'new-password': string
}

export const useOnSubmit = (setError: any) => {
  const dispatch = useDispatch()

  const url = new URL(window.location.href)
  const uid = url.searchParams.get('uid')
  const token = url.searchParams.get('token')
  const sign = url.searchParams.get('sign')
  const email = url.searchParams.get('email') || localStorage.getItem('email')
  const isUAExam = useSelector(selectorIsUAExam)

  const onSubmit: SubmitHandler<FormValues> = useCallback(
    async ({ 'new-password': passwordNew }) => {
      try {
        await changePassword({
          uid,
          token,
          sign,
          passwordNew,
        })

        dispatch(
          fetchAuthStatusRequest({
            user: email,
            password: passwordNew,
          })
        )
      } catch {
        setError('new-password', {
          type: 'server-error',
          message: isUAExam ? ChangePasswordPopupAlert.SERVER_ERROR.ua : ChangePasswordPopupAlert.SERVER_ERROR.rus,
        })
      }
    },
    [uid, token, sign, dispatch, email, setError, isUAExam]
  )

  return onSubmit
}
