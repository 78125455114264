import React, { FC, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {
  selectRecoveryEmailPopup,
  getRecoveryEmailPopupVisible,
  isRecoveryEmailPopupVisible,
} from 'modules/RecoveryEmailPopup'
import LinkWrap from 'components/Link/_wrap'

import { cnProfileAuth } from '../..'
import './RecoveryEmailLink.scss'
import { selectorIsUAExam } from 'modules/Exam/selectors'

const RecoveryEmailLink: FC = () => {
  const isUAExam = useSelector(selectorIsUAExam)
  const recoveryEmailPopup = useSelector(selectRecoveryEmailPopup)
  const recoveryEmailPopupVisible = getRecoveryEmailPopupVisible(
    recoveryEmailPopup
  )
  const dispatch = useDispatch()

  const toggleRecoveryEmailPopup = useCallback(() => {
    dispatch(isRecoveryEmailPopupVisible(!recoveryEmailPopupVisible))
  }, [dispatch, recoveryEmailPopupVisible])

  return (
    <LinkWrap
      className={cnProfileAuth('Link', ['Link_pseudo'])}
      classNameU={cnProfileAuth(
        'LinkUnderline',
        { selected: recoveryEmailPopupVisible },
        ['Link_pseudo-U']
      )}
      handleClick={toggleRecoveryEmailPopup}
    >
      {isUAExam ? `Відновлення паролю` : `Восстановление пароля`}
    </LinkWrap>
  )
}

export default RecoveryEmailLink
