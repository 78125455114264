import React, { useEffect, useState } from 'react'
import { cn } from '@bem-react/classname'

import { useTopicType, useCheckOnNumber } from './ConstructorForm.hooks'
import Grid from '../Grid'
import TopicsByParts from './TopicsByParts'
import Buttons from './Buttons'
import './ConstructorForm.scss'
import { updateSavedConstructor } from './ConstructorForm.utils'
import { useSelector } from 'react-redux'
import { selectorIsUAExam } from '../../modules/Exam'
import { nameNumbersRU, nameNumbersUA } from './NameNumber.const'

export const getNewTestTotalText = (total: number, isUAExam: boolean): string => {
  if (total > 0) {
    const totalWord = 'задани'
    const newTotalWord = totalWord + (total % 10 === 1 && total % 100 !== 11 ? 'я' : 'й')

    const totalWordUA = 'завдан'
    const newTotalWordUA = totalWordUA + (total % 10 === 1 && total % 100 !== 11 ? 'ня' : 'ь')

    if (isUAExam) {
      if (total <= 10) {
        return 'із\u00A0' + nameNumbersUA[total] + '\u00A0' + newTotalWordUA
      } else {
        return 'із\u00A0' + total + '\u00A0' + newTotalWordUA
      }
      
    }
    if (total <= 10) {
      return 'из\u00A0' + nameNumbersRU[total] + '\u00A0' + newTotalWord
    } else {
      return 'из\u00A0' + total + '\u00A0' + newTotalWord
    }
    
  } else {
    return ''
  }
}

interface IConstructorFormProps {
  page: string
}

export interface ITestTotal {
  amount: number
  text: string
}

export interface IParts {
  [short: string]: boolean
  detailed: boolean
}

export const cnConstructorForm = cn('ConstructorForm')

const ConstructorForm = ({ page }: IConstructorFormProps) => {

  const isUAExam = useSelector(selectorIsUAExam)
  
  useEffect(() => {
    for (let i = 0; i < localStorage.length; i++) {
      let key = localStorage.key(i)!
      if (key.indexOf('topicList') > 0) {
        localStorage.removeItem(key)
      }
      if (key.indexOf('testTotal') > 0) {
        localStorage.removeItem(key)
      }
    }
  }, [])

  const [testTotal, setTestTotal] = useState<ITestTotal>({
    amount: 0,
    text: '',
  })

  const [parts, setParts] = useState<IParts>({
    short: false,
    detailed: false,
  })

  /**
   * Используется для обновления конструктора
   *
   * const subject = useSelector(selectSubject)
   * const isMathEGE = exam!.name === 'ege' && subject!.name === 'math'
   * if (isMathEGE) updateSavedConstructor(page)
   *
   */
  updateSavedConstructor(page)

  // (exam?.domain !== 'fr-ege.sdamgia.ru')
  //  const isExtraPart = type === 'extra'
  const withTitle = useTopicType(false)
  const withoutNumber = !useCheckOnNumber()

  return (
    <form
      action={`/test?a=generate`}
      method="POST"
      onSubmit={() =>
        `ym(51144176, 'reachGoal', 'CONSTRUCT_VARIANT'); return true;`
      }
    >
      <input type="hidden" name="public" value="false" />
      <input type="hidden" name="defthemes" value="true" />
      <Grid className={cnConstructorForm()}>
        <div className="ConstructorForm-TopicList">
          <div
            className={cnConstructorForm('Row', {
              head: true,
              withoutNumber,
            })}
          >
            <div className="ConstructorForm-Counter">{isUAExam ? `Кількість` : `Количество`}</div>
            <div
              className={cnConstructorForm('Topic', {
                withTitle,
                withoutNumber,
              })}
            >
              Тема
            </div>
          </div>

          <TopicsByParts
            className="Constructor-PartList"
            page={page}
            parts={parts}
            setParts={setParts}
            testTotal={testTotal}
            setTestTotal={setTestTotal}
          />
        </div>

        <Buttons
          page={page}
          parts={parts}
          setParts={setParts}
          testTotal={testTotal}
          setTestTotal={setTestTotal}
          getNewTestTotalText={getNewTestTotalText}
        />
      </Grid>
    </form>
  )
}

export default ConstructorForm
