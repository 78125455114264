import { takeLatest, call, put, fork } from 'redux-saga/effects'

import { IAuthStatus, getAuthStatus, logoutUser } from './api'
import {
  startAuthLoading,
  finishAuthLoading,
  IAuthStatusAction,
  fetchAuthStatusRequest,
  fetchAuthStatusSuccess,
  fetchAuthStatusFailure,
  fetchAuthUserSuccess,
  fetchAuthUserFailure,
  logout,
} from './duck'
import {
  updateSidebarMenuAfterSignIn,
  updateSidebarMenuAfterSignOut,
} from 'modules/SidebarMenu'

function* fetchAuthStatusWatcher() {
  yield takeLatest(fetchAuthStatusRequest, fetchAuthStatusFlow)
}

function* logoutWatcher() {
  yield takeLatest(logout, logoutFlow)
}

function* fetchAuthStatusFlow(action: IAuthStatusAction) {
  try {
    yield put(startAuthLoading())

    const { status, user }: IAuthStatus = yield call(
      getAuthStatus,
      action.payload
    )

    if (status) yield put(updateSidebarMenuAfterSignIn())
    yield put(finishAuthLoading())
    yield put(fetchAuthStatusSuccess(status))
    yield put(fetchAuthUserSuccess(user))
  } catch (error) {
    yield put(finishAuthLoading())

    yield put(fetchAuthStatusFailure())
    yield put(fetchAuthUserFailure())
  }
}

function* logoutFlow() {
  try {
    yield call(logoutUser)

    yield put(fetchAuthStatusSuccess(false))
    yield put(fetchAuthUserSuccess(null))
    yield put(updateSidebarMenuAfterSignOut())
  } catch (error) {
    yield put(fetchAuthStatusFailure())
    yield put(fetchAuthUserFailure())
  }
}

export default function* sagas() {
  yield fork(fetchAuthStatusWatcher)
  yield fork(logoutWatcher)
}
