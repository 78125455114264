import React from 'react'
import { useSelector } from 'react-redux'
import { cn } from '@bem-react/classname'

import { selectExam } from 'modules/Exam'
import ProfileIcon from '../ProfileIcon'

const ProfileLink = () => {
  const cnProfileLink = cn('ProfileLink')
  const exam = useSelector(selectExam)

  return (
    <a className={cnProfileLink()} href={`https://${exam?.domain}/profile`}>
      <ProfileIcon auth />
    </a>
  )
}

export default ProfileLink
