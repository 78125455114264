import React, { FC, ElementType, ReactNode } from 'react'
import { cn } from '@bem-react/classname'

import './Text.scss'
import { hyphenatedText } from '../../utils/hyphenatedText'
import { linkDecorator } from '../../utils/linkDecorator'

export interface ITextProps {
  /**
   * Дополнительный класс
   */
  className?: string

  /**
   * Насыщенность текста
   */
  weight?: string

  /**
   * Рендер ХТМЛа
   */
  html?: boolean

  /**
   * Идентификатор компонента
   */
  id?: string

  /**
   * Функция рендера ХТМЛа
   */
  dangerouslySetInnerHTML?: { __html: string }

  /**
   * Хтмл-атрибут для рендера текста
   * @default 'p'
   */
  as?: ElementType

  /**
   * Сделать кликабельными ссылки в тексте
   * @default false
   */
  linkClick?: boolean

  /**
   * Текст
   */
  children?: ReactNode | string
}

export const cnText = cn('Text')

/**
 * Компонент для создания текста
 * @param {ITextProps} props
 */
const Text: FC<ITextProps> = ({
                                className,
                                weight: _weight,
                                html: _html,
                                linkClick: _linkClick = false,
                                as: Component = 'p',
                                ...props
                              }) => {

  const splitObject = (inputObject: ReactNode) => {
    let result: Array<any> = []
    switch (typeof (inputObject)) {
      case 'string':
        if (_linkClick) {
          return linkDecorator(hyphenatedText(inputObject))
        } else {
          return hyphenatedText(inputObject)
        }
      case 'object':
        let tmp = inputObject as Object
        Object.values(tmp).forEach(function(value) {
          if (typeof (value) === 'string') {
            if (_linkClick) {
              result.push(linkDecorator(hyphenatedText(value)))
            } else {
              result.push(hyphenatedText(value))
            }
          } else {
            result.push(value)
          }
        })
    }
    return (result.length > 0) ? result : null
  }


  return <Component {...props} className={cnText(null, [className])}>{splitObject(props.children)}</Component>
}

export default Text
