import React, { FC, useEffect, useRef, useState } from 'react'
import { classnames as cx } from '@bem-react/classnames'
import { compose } from '@bem-react/core'
import { useSelector } from 'react-redux'

import { useMobileVersion } from 'hooks'
import Text from 'components/Text'
import TitleBase, { withLevel4 } from 'components/Title'
import InputBase, { withSizeL as withInputSizeL } from 'components/Input'
import ButtonBase, { withViewDefault, withSizeL } from 'components/Button'
import 'components/Form/Form.scss'

import { cnRecoveryEmailPopup } from '..'
import {
  useEmail,
  useHandleChange,
  useHandleSubmit,
} from './RecoveryEmailPopup-Form.hooks'
import './RecoveryEmailPopup-Form.scss'
import { selectorIsUAExam } from 'modules/Exam/selectors'

const Input = compose(withInputSizeL)(InputBase)
const Button = compose(withViewDefault, withSizeL)(ButtonBase)

interface IRecoveryEmailPopupFormProps {
  visible: boolean
}

const Title = compose(withLevel4)(TitleBase)

const RecoveryEmailPopupForm: FC<IRecoveryEmailPopupFormProps> = ({
  visible,
}) => {
  const [email, setEmail] = useEmail()
  const [alertText, setAlertText] = useState('')
  const inputRef = useRef<HTMLInputElement>(null)

  const isMobile = useMobileVersion()
  const isUAExam = useSelector(selectorIsUAExam)

  const handleChange = useHandleChange(setEmail, alertText, setAlertText)
  const { loading, handleSubmit } = useHandleSubmit(
    email,
    inputRef,
    setAlertText
  )

  /**
   * Правит баг в Сафари:
   * фокус уходил на форму входа
   */
  useEffect(() => {
    inputRef.current?.focus()
  }, [inputRef])

  return (
    <form
      className={cnRecoveryEmailPopup('Form', { visible }, ['Form'])}
      action="/newapi/password/email-for-change/"
      method="POST"
      onSubmit={handleSubmit}
    >
      <Text className={cnRecoveryEmailPopup('FormText')}>
      {isUAExam ? `Ми надішлемо вам листа, в ньому буде посилання для зміни пароля.` : `Мы отправим вам письмо, в нем будет ссылка для смены пароля.`}
      </Text>

      <label className={cnRecoveryEmailPopup('Label')} htmlFor="recoveryEmail">
        <Title className={cnRecoveryEmailPopup('Title')} level="4">
        {isUAExam ? `Електронна пошта` : `Электронная почта`}
        </Title>
      </label>

      <div className={cx('Form-Row_oneLine', cnRecoveryEmailPopup('FormRow'))}>
        <Input
          className={cx(
            'Form-Input_oneLine',
            cnRecoveryEmailPopup('FormInput')
          )}
          size={isMobile ? 'l' : undefined}
          id="recoveryEmail"
          type="email"
          name="email"
          value={email}
          autoFocus
          innerRef={inputRef}
          handleChange={handleChange}
        />
        <Button
          view="default"
          size={isMobile ? 'l' : undefined}
          disabled={loading}
        >
          {isUAExam ? `Отримати посилання` : `Получить ссылку`}
        </Button>
      </div>

      <p className={cnRecoveryEmailPopup('Alert', { visible: !!alertText })}>
        {alertText}
      </p>
    </form>
  )
}

export default RecoveryEmailPopupForm
