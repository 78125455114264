import { takeLatest, call, put, select, fork } from 'redux-saga/effects'

import {
  IFetchCommentsAction,
  CommentsState,
  IFetchNewCommentsAction,
  fetchComments,
  fetchNewComments,
  setCommentsData,
  addCommentsData,
  setCommentsCount,
  setCommentsCountOnFirstPage,
  setCommentsRightPage,
  setCommentsNextPagePosition,
  setCommentsTotalPages,
  addCommentsFetchedPage,
} from './duck'
import {
  getRightPageFromUrl,
  getCommentIdFromUrl,
  getFirstCommentIndexOnPage,
  getCommentPositionY,
  scrollToComment,
} from './utils'
import { IGetCommentsResult, getComments } from './api'
import { selectComments } from './selectors'
import {
  getCommentsCountOnFirstPage,
  getCommentsFetchedPages,
  getCommentsRightPage,
  getCommentsTotalPages,
} from './getters'
import { ICommentFromBackend } from '.'

function* fetchCommentsWatcher() {
  yield takeLatest(fetchComments, fetchCommentsFlow)

  // Временно не используется
  yield takeLatest(fetchNewComments, fetchNewCommentsFlow)
}

function* fetchCommentsFlow(action: IFetchCommentsAction) {
  let rightPageRequested = getRightPageFromUrl()
  let withScrollToComment = false

  if (action.payload) {
    rightPageRequested = action.payload.rightPage
    withScrollToComment = action.payload.withScrollToComment || false
  }

  const {
    result,
    count,
    rightPage,
    totalPages,
  }: IGetCommentsResult = yield call(getComments, rightPageRequested)
  const countOnFirstPage = result?.length

  yield put(setCommentsData(result))
  yield put(setCommentsCount(count))
  yield put(setCommentsCountOnFirstPage(countOnFirstPage))
  yield put(setCommentsRightPage(rightPage))
  yield put(setCommentsTotalPages(totalPages))
  yield put(addCommentsFetchedPage(rightPage))

  if (withScrollToComment) {
    yield call(scrollToComment, 'Index', 0)
  }

  const commentIdFromUrl = getCommentIdFromUrl()
  if (commentIdFromUrl) {
    yield call(scrollToComment, 'Id', commentIdFromUrl)
  }

  /*
  if (countOnFirstPage! < COMMENT_NUMBER_PER_PAGE) {
    const nextRightPage = rightPage - 1
    yield put(fetchNewComments({ rightPage: nextRightPage }))
  }
  */
}

function* fetchNewCommentsFlow(action: IFetchNewCommentsAction) {
  const { rightPage, withScrollToComment = false } = action.payload
  const comments: CommentsState = yield select(selectComments)
  const countOnFirstPage = getCommentsCountOnFirstPage(comments)
  const currentRightPage: number = getCommentsRightPage(comments)
  const totalPages: number = getCommentsTotalPages(comments)
  const fetchedPages: Set<number> = getCommentsFetchedPages(comments)
  let addedData: Array<ICommentFromBackend> = []

  for (let page = currentRightPage - 1; page >= rightPage; page--) {
    if (fetchedPages.has(page)) {
      continue
    }

    const { result }: IGetCommentsResult = yield call(getComments, page)
    // Здесь был баг со множеством запросов
    yield put(addCommentsFetchedPage(page))

    addedData = addedData.concat(result!)
  }

  yield put(addCommentsData(addedData))

  const commentIndex: number = yield call(
    getFirstCommentIndexOnPage,
    rightPage,
    totalPages,
    countOnFirstPage
  )
  const currentPagePosition: number = yield call(
    getCommentPositionY,
    commentIndex
  )
  yield put(setCommentsNextPagePosition(currentPagePosition))

  if (withScrollToComment) {
    yield put(setCommentsRightPage(rightPage))
    yield call(scrollToComment, 'Index', commentIndex)
  }
}

export default function* sagas() {
  yield fork(fetchCommentsWatcher)
}
