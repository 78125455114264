import { MutableRefObject, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  selectTestConstructor,
  fetchTestConstructor,
} from 'modules/TestConstructor'
import { saveTopicsList } from 'components/ConstructorForm/ConstructorForm.utils'

export function useCheckboxHandler(
  page: string,
  subtopicLabel: MutableRefObject<HTMLLabelElement | null>
) {
  const topicsList = useSelector(selectTestConstructor)
  const dispatch = useDispatch()

  const useCheckboxHandler = useCallback(
    (e:any) => {
      const { topicI } = e.target.dataset
      const list = [...topicsList!]
      const topic = list[topicI]
      let value = !e.target.checked
      for (let i = 0; i < topic.subtopics!.length; i++) {
        topic.subtopics![i].checked = !value
      }
      dispatch(fetchTestConstructor(list))
      saveTopicsList(page, list)
    },
    [dispatch, page, topicsList]
  )

  return useCheckboxHandler
}
