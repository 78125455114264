import React from 'react'
import { cn } from '@bem-react/classname'

import Section from 'components/Section'
import Link from 'components/Link'
import './HistoryWriting.scss'

const HistoryWriting = () => {
  const cnHistoryWriting = cn('HistoryWriting')
  const links = [
    {
      title: 'Исторические сочинения Решу ЕГЭ',
      url: 'https://hist-ege.sdamgia.ru/hist_soch20',
    },
  ]

  return (
    <Section className={cnHistoryWriting()}>
      {links.map(({ title, url }, i) => (
        <Link
          className={cnHistoryWriting('Link', ['Link_static'])}
          href={url}
          key={i.toString()}
        >
          {title}
        </Link>
      ))}
    </Section>
  )
}

export default HistoryWriting
