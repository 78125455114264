import React, { useCallback, useState } from 'react'
import { compose } from '@bem-react/core'

import Modal from 'components/Modal'
import VariantLink from 'components/VariantLink'
import ButtonBase, { withViewDefault } from 'components/Button'
import Text from 'components/Text'

import { cnOurVariants } from '..'
import './OurVariants-Personal.scss'
import { useSelector } from 'react-redux'
import { selectorIsUAExam } from '../../../../../modules/Exam'

const Button = compose(withViewDefault)(ButtonBase)

const OurVariantsPersonal = () => {
  const isUAExam = useSelector(selectorIsUAExam)

  const [hintPopupVisible, isHintPopupVisible] = useState(false)
  const showPersonalVariantHint = useCallback((e) => {
    // Чтобы не срабатывала ссылка при клике на подсказку
    e.preventDefault()

    isHintPopupVisible(true)
  }, [])
  const closeHintPopup = () => {
    isHintPopupVisible(false)
  }

  return (
    <div className={cnOurVariants('PersonalBox')}>
      <VariantLink
        className="Link_static OurVariants-PersonalLink"
        to={`/test?a=own_test`}
      >
        {isUAExam ? `Ваш персональний варіант` : `Ваш персональный вариант`}
        <Button
          className="VariantLink-Hint"
          view="default"
          title="Помощь"
          aria-label="Подробнее о персональном варианте"
          handleClick={showPersonalVariantHint}
        >
          ?
        </Button>
      </VariantLink>

      {hintPopupVisible && (
        <Modal title={isUAExam ? `Про персональний варіант` : `О персональном варианте`} onClose={closeHintPopup}>
          <Text className={cnOurVariants('HintPopupContent')}>
            {isUAExam ? `Штучний інтелект сформує вам індивідуальний варіант із 10 завдань залежно
             від накопиченої статистики з урахуванням поточного рівня, раніше вирішених, невирішених
              та викликаних труднощів завдань. (Необхідно авторизуватися на порталі.) Рекомендуємо
               регулярно вирішувати індивідуальні варіанти: ви тренуватиметеся в тому, що не виходить,
                доки не почне виходити.` : `Искусственный интеллект сформирует вам индивидуальный вариант
            из 10 заданий в зависимости от накопленной статистики с учётом
            текущего уровня, ранее решённых, нерешённых и вызвавших затруднение
            заданий. (Необходимо авторизоваться на портале.) Рекомендуем
            регулярно решать индивидуальные варианты: вы будете тренироваться
            в том, что не получается, до тех пор, пока не начнет получаться.`}
          </Text>
        </Modal>
      )}
    </div>
  )
}

export default OurVariantsPersonal
