import { combineReducers } from 'redux'
import {
  ActionFunctionAny,
  Action,
  ReduxCompatibleReducer,
  createAction,
  handleActions,
} from 'redux-actions'

import { ITopic } from 'components/ConstructorForm/Buttons'
import * as constants from './constants'

export interface ITestConstructorAction {
  type: typeof constants.FETCH_TEST_CONSTRUCTOR
  payload: Array<ITopic> | null
}

export interface ITestConstructorWithTitleLinksAction {
  type: typeof constants.IS_TEST_CONSTRUCTOR_WITH_TITLE_LINKS
  payload: boolean
}

export interface ITestConstructorPartsAction {
  type: typeof constants.SET_TEST_CONSTRUCTOR_PARTS
  payload: Array<string>
}

// Actions
export const fetchTestConstructor: ActionFunctionAny<Action<Array<
  ITopic
> | null>> = createAction(constants.FETCH_TEST_CONSTRUCTOR)

export const isTestConstructorWithTitleLinks: ActionFunctionAny<Action<
  boolean
>> = createAction(constants.IS_TEST_CONSTRUCTOR_WITH_TITLE_LINKS)

export const setTestConstructorParts: ActionFunctionAny<Action<
  Array<string>
>> = createAction(constants.SET_TEST_CONSTRUCTOR_PARTS)

// Reducers
const dataInitialState = null
const data: ReduxCompatibleReducer<
  Array<ITopic> | null,
  Array<ITopic> | null
> = handleActions(
  {
    [constants.FETCH_TEST_CONSTRUCTOR]: (
      _state: Array<ITopic> | null,
      action
    ) => action.payload,
  },
  dataInitialState
)

const withTitleLinksInitialState = false
const withTitleLinks: ReduxCompatibleReducer<boolean, boolean> = handleActions(
  {
    [constants.IS_TEST_CONSTRUCTOR_WITH_TITLE_LINKS]: (
      _state: boolean,
      action
    ) => action.payload,
  },
  withTitleLinksInitialState
)

export const partsInitialState = ['short', 'detailed']
const parts: ReduxCompatibleReducer<
  Array<string>,
  Array<string>
> = handleActions(
  {
    [constants.SET_TEST_CONSTRUCTOR_PARTS]: (_state: Array<string>, action) =>
      action.payload,
  },
  partsInitialState
)

export const initialState: TestConstructorState = {
  data: dataInitialState,
  withTitleLinks: withTitleLinksInitialState,
  parts: partsInitialState,
}

const testConstructor = combineReducers({
  data,
  withTitleLinks,
  parts,
})

export type TestConstructorState = ReturnType<typeof testConstructor>
export default testConstructor
