import { useCallback, useState } from 'react'

import {
  PasswordInputTypes,
  PasswordTogglerTitles,
} from '../PasswordToggler.constants'

export type PasswordInputType = 'password' | 'text'
export interface IPasswordInputTypes {
  PASSWORD: PasswordInputType
  TEXT: PasswordInputType
}

export const usePasswordInputAttributes = () => {
  const [type, setType] = useState<PasswordInputType>(
    PasswordInputTypes.PASSWORD
  )
  const [title, setTitle] = useState(PasswordTogglerTitles.SHOW)

  const toggleVisibility = useCallback(() => {
    if (type === 'password') {
      setType(PasswordInputTypes.TEXT)
      setTitle(PasswordTogglerTitles.HIDE)
    } else {
      setType(PasswordInputTypes.PASSWORD)
      setTitle(PasswordTogglerTitles.SHOW)
    }
  }, [type])

  return {
    type,
    title,
    toggleVisibility,
  }
}
