import React, { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { cn } from '@bem-react/classname'

import { deleteUnreadMessageApi, getUnreadMessages, selectChat } from 'modules/Chat'

import { useFetchData } from './hooks'
import Alert from './Alert'
import Link2 from '../../../../../components/Link2'

export const cnAlertListChat = cn('AlertListChat')

const AlertListChat: FC = () => {
  const chat = useSelector(selectChat)
  const unreadMessages = getUnreadMessages(chat)
  const [closeAllMessages, setCloseAllMessages] = useState(false)
  const [hideAllMessages, setHideAllMessages] = useState(Boolean(localStorage.getItem('hideAllMessages')) || false)
  const [showAlert, setShowAlert] = useState(false)

  useFetchData()
  const dispatch = useDispatch()

  function CloseAllMessages() {
    if (unreadMessages) {
      setCloseAllMessages(true)
      for (let key in unreadMessages) {
        dispatch(deleteUnreadMessageApi(unreadMessages[key].id))
      }
      setShowAlert(true)
      setTimeout(() => setShowAlert(false), 3000)
    }
  }

  function HideAllMessagesFunc() {
    setHideAllMessages(!hideAllMessages)
    if (hideAllMessages) {
      localStorage.removeItem('hideAllMessages')
    } else {
      localStorage.setItem('hideAllMessages', '1')
    }

  }

  return (
    <div>
      <div className={cnAlertListChat()}>
        {unreadMessages?.map(({ id, text, from, fromId, date, type }, i) => (
          <Alert
            i={i}
            id={id}
            text={text}
            from={from}
            fromId={fromId}
            date={date}
            type={type}
            key={id}
            closeAllMessages={closeAllMessages}
            hideAllMessages={hideAllMessages}
          />
        ))}
      </div>
      {unreadMessages && unreadMessages?.length > 0 &&
      <div>{hideAllMessages ?
        <div className={'AlertListChat-Alert-WrapBell'} style={{ boxShadow: '0 0 32px rgb(0 0 0 / 8%)' }} onClick={() => HideAllMessagesFunc()}>
          <div className={'AlertListChat-Alert-NotifyBell'} />
        </div> :
        <div className={'Alert-HideAllMain' + (unreadMessages?.length === 1 ? ' Alert-HideAllMain-Full' : '')}>
          <div className={'Alert Alert-HideAll' + (unreadMessages?.length === 1 ? ' Alert-HideAll-Full' : '')}>
            <Link2 onClick={() => HideAllMessagesFunc()}>Свернуть</Link2>
          </div>
          {unreadMessages?.length > 1 && <div className={'Alert Alert-HideAll'}>
            <Link2 onClick={() => CloseAllMessages()}>Закрыть все</Link2>
          </div>}
        </div>}
      </div>}
      {showAlert && <div className={'Alert'} style={{ textAlign: 'center' }}>Уведомления сохранены в чат</div>}
    </div>
  )
}

export default AlertListChat
