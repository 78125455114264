import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { cn } from '@bem-react/classname'
import './ExamTitleUA.scss'
import { getDomain } from 'utils/getDomain'

export const cnHeader = cn('Header')

const ExamTitleUA: FC = () => {
  const domain = useSelector(getDomain)
  const isNMT = domain.includes('nmt');
  return (
    <div className="header-title">
      <span className="headerTitleUAWrap_active">
        <span className="headerTitleUA headerTitleUA_active"><h1>СКЛАДУ {isNMT ? `НМТ` : `ЗНО`}</h1></span>
      </span>
    <a href={isNMT ? `https://skladuzno.online/` : `https://skladunmt.online/`} target="_blank" rel="noreferrer">
      <span className="headerTitleUA_noactiveWrap">
        <span className="headerTitleUA headerTitleUA_noactive">СКЛАДУ {isNMT ? `ЗНО` : `НМТ`}</span>
      </span>
    </a>
</div>
  )
}

export default ExamTitleUA
