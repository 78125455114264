import React, { FC, ReactNode, useEffect, useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { cn } from '@bem-react/classname'

import {
  selectChatDialogOpen,
  selectChatDialogInfoTo,
  selectChatDialogInfoToId,
  closeChat,
  setChatListEl,
  closeChatDialog,
} from 'modules/Chat'

export const cnChatPopup = cn('ChatPopup')

interface IChatLayoutProps {
  sendForm: ReactNode
  children: ReactNode
}

const ChatLayout: FC<IChatLayoutProps> = ({ sendForm, children }) => {
  // TODO: Move style from ChatPopup to ChatLayout
  // const cnChatLayout = cn('ChatLayout')
  const listRef = useRef<HTMLDivElement | null>(null)
  const dialogOpen = useSelector(selectChatDialogOpen)
  const to = useSelector(selectChatDialogInfoTo)
  const toId = useSelector(selectChatDialogInfoToId)
  const dispatch = useDispatch()

  const isAlertSystem = toId === 0

  useEffect(() => {
    dispatch(setChatListEl(listRef.current))
  }, [dispatch, listRef])

  const handleClose = useCallback(() => {
    const handleChatClose = () => {
      dispatch(closeChat())
    }

    const handleDialogClose = () => {
      dispatch(closeChatDialog())
    }

    dialogOpen ? handleDialogClose() : handleChatClose()
  }, [dispatch, dialogOpen])

  return (
    <div className={cnChatPopup()}>
      <header className={cnChatPopup('Header')}>
        <div className={cnChatPopup('Title')}>{to || 'Список контактов'}</div>
      </header>
      <div className={cnChatPopup('CloseButton')} onClick={handleClose}>
        ×
      </div>
      <div
        className={cnChatPopup('List', {
          mode: dialogOpen && !isAlertSystem && 'dialog',
        })}
        ref={listRef}
      >
        {children}
      </div>
      {dialogOpen && !isAlertSystem && sendForm}
    </div>
  )
}

export default ChatLayout
