import React from 'react'
import { cn } from '@bem-react/classname'

import Section from 'components/Section'
import Link from 'components/Link'
import './HistoryWriting.scss'

const SocialAnthology = () => {
  const cnSocialAnthology = cn('SocialAnthology')
  const links = [
    {
      title: 'Хрестоматия по обществознанию',
      url: 'https://soc-ege.sdamgia.ru/anthology',
    },
  ]

  return (
    <Section className={cnSocialAnthology()}>
      {links.map(({ title, url }, i) => (
        <Link
          className={cnSocialAnthology('Link', ['Link_static'])}
          href={url}
          key={i.toString()}
        >
          {title}
        </Link>
      ))}
      <span className="Title-Caption">2021</span>
    </Section>
  )
}

export default SocialAnthology
