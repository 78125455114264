import React, { FC } from 'react'
import { compose } from '@bem-react/core'
import { useSelector } from 'react-redux'

import Text from 'components/Text'
import TitleBase, { withLevel4 } from 'components/Title'

import { cnRecoveryEmailPopup } from '..'
import './RecoveryEmailPopup-Confirm.scss'
import { selectorIsUAExam } from 'modules/Exam/selectors'

interface IRecoveryEmailPopupConfirmProps {
  visible: boolean
}

const Title = compose(withLevel4)(TitleBase)

const RecoveryEmailPopupConfirm: FC<IRecoveryEmailPopupConfirmProps> = ({
  visible,
}) => {
  const isUAExam = useSelector(selectorIsUAExam)
return (<div className={cnRecoveryEmailPopup('Confirm', { visible })}>
<Title level="4">{isUAExam ? `Перевірте пошту` : `Проверьте почту`}</Title>

<Text>
{isUAExam ? `Вам надіслано листа з посиланням на зміну пароля. Якщо лист не надійшов, перевірте папку зі спамом.` : `Вам отправлено письмо со ссылкой для смены пароля. Если письмо не пришло,
  проверьте папку со спамом.`}
</Text>
</div>)
}

export default RecoveryEmailPopupConfirm
