/**
 * Пути главной страницы
 *
 * /index.html нужен для последнего тестирования
 * перед релизом на прод
 */
export const indexPaths = [
  '/',
  '/index.html',
  '/pass_change',
  '/change-password',

  /**
   * @ticket #153
   * Чтобы на кешированной странице Гугла
   * показывалась главная страница
   */
  '/search',
]
