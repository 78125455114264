import axios from 'axios'

import { IMessage } from '../../../pages/App/components/Chat/ChatPopup/Dialog/Message'
import { getApiKey } from 'utils'

export const getDialog = async (id: number): Promise<Array<IMessage>> => {

  const data = new FormData()
  data.append('id', id.toString())

  const response = await axios({
    method: 'POST',
    url: `/get_messages${getApiKey()}`,
    data,
    headers: { 'Content-Type': 'multipart/form-data' },
    params: {
      ajax: 1,
      a: 'get_messages',
    },
    withCredentials: true,
  })

  const responseData = response.data.map((message: any) => ({
    id: message.id,
    text: message.body,
    from: message.from_str,
    fromId: message.from,
    date: message.creation_time,
  }))

  return responseData
}
