import React, { FC, memo } from 'react'
import { useSelector } from 'react-redux'

import LinkWrap from 'components/Link/_wrap'

import RecoveryEmailLink from './RecoveryEmailLink'
import { cnProfileAuth } from '..'
import { selectorIsUAExam } from 'modules/Exam/selectors'

const ProfileAuthLinks: FC = () => {
  const isUAExam = useSelector(selectorIsUAExam)
  const links = [
    {
      href: `/vkauth`,
      text: isUAExam ? `Вхід через Вконтакте` : `Вход через Вконтакте`,
    },
    {
      href: `/register`,
      text: isUAExam ? `Реєстрація` : `Регистрация`,
      activeClassName: 'ProfileAuth-Link_selected',
    },
  ]

  return (
    <div className={cnProfileAuth('Links')}>
      <RecoveryEmailLink />

      {links.map(({ href, text, activeClassName }) => (
        <LinkWrap
          className={cnProfileAuth('Link')}
          activeClassName={activeClassName}
          href={href}
          key={href}
        >
          {text}
        </LinkWrap>
      ))}
    </div>
  )
}

export default memo(ProfileAuthLinks)
