import React from 'react'
import { withBemMod } from '@bem-react/core'

import { ITitleProps, cnTitle } from '../Title'
import './Title_level_2.scss'

export interface ITitleLevel2Props {
  /**
   * Уровень заголовка
   */
  level?: '2'
}

/**
 * Модификатор, отвечающий за уровень заголовка
 * @param {ITitleLevel2Props} props
 */
export const withLevel2 = withBemMod<ITitleLevel2Props, ITitleProps>(
  cnTitle(),
  {
    level: '2',
  },
  (WrappedComponent) => ({ ...props }) => (
    <WrappedComponent {...props} as="h2" />
  )
)
