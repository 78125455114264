import { FETCH_AUTH_STATUS_SUCCESS } from "modules/Auth"
import { ITeacherTestGroup } from "modules/TeacherTest"
import { doPatchAssigns } from "modules/TeacherTest/api"
import { Action } from "redux-actions"
import { call, fork, put, select, takeEvery, takeLatest } from "redux-saga/effects"
import { FETCH_PUPILS, IGetPupilsError, IGetPupilsResult, IGetPupilsSuccess, initAssigns, initGroups, initPupils, setError, initThemes, setGroup, newGroup, TeacherPupilsState, selectTeacherPupils, setAssign, removeGroup, initSites, IMoveGroupResult, MOVE_GROUP, removePupil, removePupilDone, tryPupilAdd, IPupil, addPupil, pupilRename, pupilMerge, initialState } from "."
import { doDeleteGroup, doMoveGroup, doPatchGroup, doPupilMerge, doPupilRename, doPutGroup, doRemovePupil, doTryPupilAdd, getPupils} from "./api"

function* fetchPupilsWatcher() { yield takeLatest(FETCH_PUPILS, fetchPupilsFlow) }

function* putGroupWatcher()  { yield takeEvery(newGroup, putGroupFlow) }
function* patchGroupWatcher()  { yield takeEvery(setGroup, patchGroupFlow) }
function* patchAssignsWatcher()  { yield takeEvery(setAssign, patchAssignsFlow) }
function* deleteGroupWatcher()  { yield takeEvery(removeGroup, deleteGroupFlow) }
function* userLoginWatcher() {yield takeLatest(FETCH_AUTH_STATUS_SUCCESS, userFetchPupilsFlow)}
function* groupMoveWatcher() {yield takeEvery(MOVE_GROUP, moveGroupFlow)}
function* removePupilWatcher() {yield takeEvery(removePupil, removePupilFlow)}
function* tryPupilAddWatcher() {yield takeEvery(tryPupilAdd, tryPupilAddFlow)}
function* pupilRenameWatcher() {yield takeEvery(pupilRename, pupilRenameFlow)}
function* pupilMergeWatcher() {yield takeEvery(pupilMerge, pupilMergeFlow)}

function* userFetchPupilsFlow(action: Action<null>) {
  const data: TeacherPupilsState = yield select(selectTeacherPupils)
  if (data.error !== initialState.error){
    yield fetchPupilsFlow(action)
  }
}


function* pupilMergeFlow(action: Action<number>){
  yield call(doPupilMerge, action.payload) 
}

function* pupilRenameFlow(action: Action<{id: number, name: string}>){
  yield call(doPupilRename, action.payload) 
}

function* tryPupilAddFlow(action: Action<string>){
  const result: IPupil = yield call(doTryPupilAdd, action.payload)
  if (result){
    yield put(addPupil(result))
  }
}

function* moveGroupFlow(action: Action<{group_id:number, portal:string, site:string, to:string, callback:Function}>){
  const result:IMoveGroupResult = yield call(doMoveGroup, action.payload)
  window.alert(result.message)
  action.payload.callback()
}

function* removePupilFlow(action: Action<number>){
  yield call(doRemovePupil, action.payload)
  yield put(removePupilDone(action.payload))
}

function* fetchPupilsFlow(action: Action<null>) {
    const result: IGetPupilsResult = yield call(getPupils)
    switch (result.status) {
        case 0:
            const asSucc = result as IGetPupilsSuccess
            yield put(initAssigns(asSucc.groupAssign))
            yield put(initPupils(asSucc.pupils))
            yield put(initThemes(asSucc.themes))
            yield put(initSites(asSucc.sites))
            yield put(initGroups(asSucc.groups))
            yield put(setError(''))    
            break
        case 1:
            const asErr = result as IGetPupilsError
            yield put(setError(asErr.error))
    }
  }

  function* putGroupFlow(action: Action<string>) {
    const data: ITeacherTestGroup = {id: 0, name: action.payload, archive:0, state:1, members:[]}
     const group: ITeacherTestGroup = yield call(doPutGroup, data)
     yield put(setGroup(group))
  }
  
  function* patchGroupFlow(action: Action<ITeacherTestGroup>) {
    yield call(doPatchGroup, action.payload)
  }
  
  function* deleteGroupFlow(action: Action<number>) {
    yield call(doDeleteGroup, action.payload)
  }
  
  function* patchAssignsFlow() {
    const data: TeacherPupilsState = yield select(selectTeacherPupils)
    yield call(doPatchAssigns, data.groupAssign)
  }
  



  export default function* sagas() {
    yield fork(fetchPupilsWatcher)
    yield fork(putGroupWatcher)
    yield fork(patchGroupWatcher)
    yield fork(patchAssignsWatcher)
    yield fork(deleteGroupWatcher)
    yield fork(userLoginWatcher)
    yield fork(groupMoveWatcher)
    yield fork(removePupilWatcher)
    yield fork(tryPupilAddWatcher)
    yield fork(pupilRenameWatcher)
    yield fork(pupilMergeWatcher)
  }
  