import axios from 'axios'

import { IMessage } from 'pages/App/components/Chat/ChatPopup/Dialog/Message'
import { getApiKey } from 'utils'

export const sendMessage = async ({ id, text }: IMessage) => {

  const data = new FormData()
  data.append('to', id.toString())
  data.append('body', text)

  return await axios({
    method: 'POST',
    url: `/get_messages${getApiKey()}`,
    data,
    headers: { 'Content-Type': 'multipart/form-data' },
    params: {
      ajax: 1,
      a: 'send',
    },
    withCredentials: true,
  })
}
