import * as constants from './constants'

import { createAction } from '@reduxjs/toolkit'
import { ICatalog, ICatType, ITypeStats, IStats } from './types'
import { combineReducers } from 'redux'

export const fetchTypes = createAction<undefined>(constants.FETCH_TYPES)
export const fetchCatalog = createAction<{type: string, name: string}>(constants.FETCH_CATALOG)
export const fetchUserStats = createAction<{type: string, name: string}>(constants.FETCH_USER_STATS)
export const initTypes = createAction<typeof initialState.catalogTypes>(constants.INIT_TYPES)
export const initCatalog = createAction<{type: string, name: string, catalog: ICatalog}>(constants.INIT_CATALOG)
export const initUserStats = createAction<{type: string, name: string, stats: IStats}>(constants.INIT_USER_STATS)
export const setCatalogType = createAction<string>(constants.SET_CATALOG_TYPE)
export const setCatalog = createAction<string>(constants.SET_CATALOG)
export const setStat = createAction<string>(constants.SET_STAT)
export const setStatEnabled = createAction<number>(constants.SET_STAT_ENABLED)
export const setError = createAction<string>(constants.SET_ERROR)
export const insertAfter = createAction<{type:string, catalog: string, id: number, ipath: number[]}>(constants.INSERT_AFTER)
export const insertBefore = createAction<{type:string, catalog: string, id: number, ipath: number[]}>(constants.INSERT_BEFORE)
export const setShowEmpty = createAction<undefined>(constants.SET_SHOW_EMPTY)

const catalogTypes = function(state: typeof initialState.catalogTypes|undefined,
    action: ReturnType<typeof initTypes> | ReturnType<typeof initCatalog> | ReturnType<typeof insertAfter> | ReturnType<typeof insertBefore>) {
    let _state = state !== undefined ? state : initialState.catalogTypes
    switch (action.type){
        case initTypes.type:
            return action.payload as ICatType[]
        case initCatalog.type:
            let payload = action.payload as {type: string, name: string, catalog: ICatalog}
            return _state.map((i)=>i.name === payload.type ? {...i, catalogs: i.catalogs.map((k)=> k.name === payload.name? payload.catalog : k)} as ICatType : i)
        case insertAfter.type:
        case insertBefore.type:
            let newstate = [..._state]
            let iapayload = action.payload as {type:string, catalog: string, id: number, ipath: number[]}
            const type = newstate.filter((i) => i.name === iapayload.type)[0] || newstate[0]
            const catalog = type.catalogs.filter((i) => i.name === iapayload.catalog)[0] || type.catalogs[0]
            const ipath = [...iapayload.ipath]
            let ref = ipath.pop() as number
            let current = catalog.themes
            let i:number|undefined
            while ((i = ipath.shift()) !== undefined) {
                current = current[i as number].children!
            }
            const removed = current.splice(iapayload.id, 1)
            if (ref > iapayload.id){
                ref += -1
            }
            current.splice(ref + (action.type === insertAfter.type?1:0), 0, ...removed)
            return newstate
    }
    return _state
}

const userStats = function(state: typeof initialState.userStats|undefined,
    action: ReturnType<typeof initUserStats>) {
    let _state = state !== undefined ? state : initialState.userStats
    switch (action.type) {
        case initUserStats.type:
            let newstate = {..._state}
            if (!newstate.hasOwnProperty(action.payload.type)){
                newstate[action.payload.type] = {}
            }
            newstate[action.payload.type][action.payload.name] = action.payload.stats
            return newstate
    }
    return _state
}

const activeType = function(state: typeof initialState.activeType|undefined,
    action: ReturnType<typeof setCatalogType>) {
    let _state = state !== undefined ? state : initialState.activeType
    switch (action.type) {
        case setCatalogType.type:
          return action.payload
      }
      return _state
}

const activeCatalog = function(state: typeof initialState.activeCatalog|undefined,
    action: ReturnType<typeof setCatalog>) {
    let _state = state !== undefined ? state : initialState.activeCatalog
    switch (action.type) {
        case setCatalog.type:
            return action.payload
        case setCatalogType.type:
            return ''
        }
    return _state
}

const activeStat = function(state: typeof initialState.activeStat|undefined,
    action: ReturnType<typeof setStat>) {
    let _state = state !== undefined ? state : initialState.activeStat
    switch (action.type) {
        case setStat.type:
            return action.payload
        case setCatalogType.type:
            return ''
        case setCatalog.type:
            return ''
      }
      return _state
}
const statEnabled = function(state: typeof initialState.statEnabled|undefined,
    action: ReturnType<typeof setStatEnabled>) {
    let _state = state !== undefined ? state : initialState.statEnabled
    switch (action.type) {
        case setStatEnabled.type:
            return action.payload
      }
      return _state
}

const showEmpty = function(state: typeof initialState.showEmpty|undefined,
    action: ReturnType<typeof setShowEmpty>) {
    let _state = state || initialState.showEmpty
    switch (action.type) {
        case setShowEmpty.type:
            return true
    }
    return _state

}

const error = function(state: typeof initialState.error|undefined, 
    action: ReturnType<typeof setError>) {
    let _state = state !== undefined ? state : initialState.error
    switch (action.type) {
      case setError.type:
        return action.payload
    }
    return _state
}

export const initialState = {
    catalogTypes: [] as Array<ICatType>,
    userStats: {} as ITypeStats,
    activeType: '',
    activeCatalog: '',
    activeStat: '',
    statEnabled: 0,
    showEmpty: false,
    error: "Идет загрузка"
}

const catalog = combineReducers({
    catalogTypes,
    userStats,
    activeType,
    activeCatalog,
    activeStat,
    statEnabled,
    showEmpty,
    error
})

export type CatalogState = ReturnType<typeof catalog>

export default catalog