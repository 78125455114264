import { ITopic } from './Buttons/Buttons'
import { IParts, ITestTotal } from './ConstructorForm'

export const loadTopicsList = (page: string): Array<ITopic> | null => {
  const topicListSaved = localStorage.getItem(`${page}-topicList`)

  return topicListSaved ? JSON.parse(topicListSaved) : null
}

export const saveTopicsList = (page: string, topicList: Array<ITopic>) => {
  try {
    localStorage.setItem(`${page}-topicList`, JSON.stringify(topicList))
  } catch (e) {}
}

export const loadParts = (page: string): IParts => {
  const partsSaved = localStorage.getItem(`${page}-parts`)

  return partsSaved ? JSON.parse(partsSaved) : null
}

export const saveParts = (page: string, parts: IParts) => {
  localStorage.setItem(`${page}-parts`, JSON.stringify(parts))
}

export const loadTestTotal = (page: string): ITestTotal => {
  const testTotalSaved = 0
  // const testTotalSaved = localStorage.getItem(`${page}-testTotal`)

  return testTotalSaved ? JSON.parse(testTotalSaved) : null
}

export const saveTestTotal = (page: string, testTotal: ITestTotal) => {
  localStorage.setItem(`${page}-testTotal`, JSON.stringify(testTotal))
}

export const updateSavedConstructor = (page: string) => {
  const wasUpdated = document.cookie.includes('updateSavedConstructor=true')

  if (!wasUpdated) {
    const items = ['topicList', 'parts', 'testTotal']

    items.forEach((item) => {
      localStorage.removeItem(`${page}-${item}`)
    })

    const tenDays = '60*60*24*10'
    document.cookie = `updateSavedConstructor=true; path=/; max-age=${tenDays}`
  }
}
