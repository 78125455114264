import React, { FC } from 'react'
import { cn } from '@bem-react/classname'

import { OnClose } from 'components/Modal'
import Button from 'components/Button'

import './CloseButton.scss'

interface ICloseButtonProps {
  className?: string
  title?: string
  onClick?: OnClose
}

const CloseButton: FC<ICloseButtonProps> = ({
  className,
  title = 'Закрыть',
  onClick,
  ...props
}) => {
  const cnCloseButton = cn('CloseButton')

  return (
    <Button
      className={cnCloseButton(null, [className])}
      aria-label={title}
      // @ts-ignore: ilyasidorchik не понял, как починить
      handleClick={onClick}
      {...props}
    >
      ×
    </Button>
  )
}

export default CloseButton
