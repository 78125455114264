import React from 'react'

import { ITopic } from 'components/ConstructorForm/Buttons'
import { cnConstructorForm } from 'components/ConstructorForm'

interface ConstructorFormNumberProps {
  issue: ITopic['issue']
}

const ConstructorFormNumber = ({ issue }: ConstructorFormNumberProps) => {
  if (issue === null) {
    return null
  }

  return <div className={cnConstructorForm('TopicNumber')}>{issue}.</div>
}

export default ConstructorFormNumber
