import { useSelector } from 'react-redux'

import { selectTestConstructor } from 'modules/TestConstructor'

export const useCheckOnNumber = (): boolean => {
  const topicsList = useSelector(selectTestConstructor)

  if (!topicsList) {
    return false
  }

  return topicsList![0].issue !== null
}
