import React, {
  FC,
  ChangeEvent,
  KeyboardEvent,
  FormEvent,
  useState,
  useCallback,
  useRef,
} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import TextareaAutosize from 'react-textarea-autosize'
import cx from 'classnames'

import 'components/Input/Input.scss'

import { IMessage } from '../Message'
import { selectAuthUser } from 'modules/Auth'
import { selectChatDialogInfoToId, sendChatMessage } from 'modules/Chat'
import { getDate, shake } from 'utils'
import { cnChatPopup } from '../../ChatPopup'

const Send: FC = () => {
  const [text, setText] = useState('')
  const to = useSelector(selectChatDialogInfoToId)
  const user = useSelector(selectAuthUser)
  const from = user ? `${user.firstName} ${user.lastName}` : ''
  const buttonRef = useRef<HTMLDivElement>(null)
  const dispatch = useDispatch()

  const handleChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value)
  }, [])

  const submit = useCallback(() => {
    const textTrimmed = text.trim()

    if (!textTrimmed) {
      return shake(buttonRef)
    }

    const message: IMessage = {
      id: Number(to),
      text: textTrimmed,
      from,
      date: getDate(),
    }

    setText('')
    dispatch(sendChatMessage(message))
  }, [dispatch, to, text, from])

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === 'Enter' && (e.ctrlKey || e.metaKey)) {
        e.preventDefault()

        submit()
      }
    },
    [submit]
  )

  const handleSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault()

      submit()
    },
    [submit]
  )

  return (
    <form onSubmit={handleSubmit}>
      <TextareaAutosize
        className={cx('Input', cnChatPopup('Textarea'))}
        value={text}
        placeholder="Ваше сообщение"
        maxRows={15}
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        autoFocus
      />
      <button
        className={cnChatPopup('SendButton')}
        title="Ctrl + Enter — отправить, Enter — перевод строки"
      >
        <div ref={buttonRef}>
          <svg
            width="100%"
            viewBox="0 0 512 512"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
          >
            <g>
              <path
                className={cnChatPopup('IconPath')}
                d="M481.508,210.336L68.414,38.926c-17.403-7.222-37.064-4.045-51.309,8.287C2.86,59.547-3.098,78.551,1.558,96.808    L38.327,241h180.026c8.284,0,15.001,6.716,15.001,15.001c0,8.284-6.716,15.001-15.001,15.001H38.327L1.558,415.193    c-4.656,18.258,1.301,37.262,15.547,49.595c14.274,12.357,33.937,15.495,51.31,8.287l413.094-171.409    C500.317,293.862,512,276.364,512,256.001C512,235.638,500.317,218.139,481.508,210.336z"
                fill="#000066"
              />
            </g>
          </svg>
        </div>
      </button>
    </form>
  )
}

export default Send
