import { IPupil, TeacherPupilsState } from ".";


type SortFunction = (arg1: IPupil, arg2: IPupil) => number
interface Sorter {
    [key: string]: SortFunction;
}
const sorters:Sorter = {
    'id': (a:IPupil, b:IPupil) => b.id - a.id,
    //'public': (a:IPupil, b:IPupil) => a.public - b.public,
    'name': (a:IPupil, b:IPupil) => a.name === ''?1:b.name===''?-1:a.name.toLowerCase() > b.name.toLowerCase() ? 1: -1,
    'count': (a:IPupil, b:IPupil) => b.count - a.count,
    'assigned': (a:IPupil, b:IPupil) => b.assigns - a.assigns,
    'retest': (a:IPupil, b:IPupil) => b.retest_pending - a.retest_pending,
    //'fromDate': (a:IPupil, b:IPupil) => a.fromDate === null?1:b.fromDate===null?-1:a.fromDate > b.fromDate ? -1: 1,
    //'toDate': (a:IPupil, b:IPupil) => a.toDate === null?1:b.toDate===null?-1:a.toDate > b.toDate ? -1: 1,
    //'creationDate': (a:IPupil, b:IPupil) => a.creationDate > b.creationDate ? -1: 1,
    //'count': (a:IPupil, b:IPupil) => b.count - a.count,
}


export const getOrder = (comments: TeacherPupilsState) => comments.order
export const getGroupsData = (comments: TeacherPupilsState) => comments.groups.sort((a, b) => a.name > b.name? 1:-1)
export const getThemes = (comments: TeacherPupilsState) => comments.themes

export const getWOGroup = function (state: TeacherPupilsState) {
    let wtheme = Array<number>()
    wtheme = state.groups.reduce((prev, curr) => [...prev, ...curr.members!], Array<number>());
    return state.pupils.filter((pupil) => !wtheme.includes(pupil.id)).sort(sorters[state.order])
}

export const getAllPupils = (state: TeacherPupilsState) => state.pupils
export const getPupils = (state: TeacherPupilsState, members: Array<number>) => state.pupils.filter((pupil) => members.includes(pupil.id)).sort(sorters[state.order])
export const getSites = (state: TeacherPupilsState) => state.sites
export const getGroup = function (state: TeacherPupilsState, id: number) {
    let groups = state.groups.filter((group) => group.id === id)
    if (groups.length) {
        return groups[0]
    } else {
        return null
    }
}
export const getThemeAssigns = function (state: TeacherPupilsState, id: number) {
    let out = []
    for (let i in state.groupAssign){
        if (state.groupAssign[i].indexOf(id) !== -1){
            out.push(parseInt(i))
        }
    }
    return out
}
export const getPupil = function (state: TeacherPupilsState, id: number) {
    let pupils = state.pupils.filter((pupil) => pupil.id === id)
    if (pupils.length) {
        return pupils[0]
    } else {
        return null
    }
}
