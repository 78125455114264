import React, { FC } from 'react'

import { getDomain, getPath } from 'utils'
import Section from 'components/Section'
import Text from 'components/Text'
import ConstructorTitle from './ConstructorTitle'
import ConstructorForm from 'components/ConstructorForm'
import 'components/Link/Link.scss'
import { useSelector } from 'react-redux'
import { selectorIsUAExam } from '../../../../modules/Exam'

const Constructor: FC = () => {
  const domain = useSelector(getDomain)
  const isUAExam = useSelector(selectorIsUAExam)
  return (<Section>
    <ConstructorTitle />

    <Text className="Section-Desc">
      {isUAExam ? `Щоб цілеспрямовано тренуватися на певні теми, ви можете скласти
       варіант із необхідної кількості завдань по конкретних розділах задачного каталогу.
        Для швидкого складання типового варіанта використовуйте  кнопки праворуч.` : `Чтобы
         целенаправленно тренироваться по определённым темам, вы можете
      составить вариант из необходимого количества заданий по конкретным
      разделам задачного каталога. Для быстрого составления типового варианта
      используйте кнопки справа.`}
    </Text>

    <ConstructorForm page={domain + getPath() + new Date().getDate() + "-" + new Date().getMonth()} />
  </Section>)
}

export default Constructor
