import axios from 'axios'

import { getApiKey } from 'utils'

export const markMessageRead = async (id: number): Promise<void> => {

  try {
    await axios.get(`/get_messages${getApiKey()}`, {
      params: { ajax: 1, a: 'mark_read', id },
      withCredentials: true,
    })
  } catch (error) {
    console.error(error)
  }
}
