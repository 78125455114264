import React, {
  FC,
  Dispatch,
  SetStateAction,
  MouseEvent,
  useCallback,
} from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {
  cnConstructorForm,
  IParts,
  ITestTotal,
} from 'components/ConstructorForm'
import { ITopic } from 'components/ConstructorForm/Buttons'
import { selectExam } from 'modules/Exam'
import {
  selectTestConstructor,
  fetchTestConstructor,
} from 'modules/TestConstructor'
import {
  saveParts,
  saveTopicsList,
} from 'components/ConstructorForm/ConstructorForm.utils'
import { useTopicType } from 'components/ConstructorForm/ConstructorForm.hooks'
import Counter from 'components/Counter'
import LinkPseudo from 'components/Link/_pseudo'
import LinkWrapU from 'components/Link/_wrap/-U'
import Subtopic from '../Subtopic'
import CheckAll from '../CheckAll'
import Link from 'components/Link'

import Number from './Number'
import './ConstructorForm-Row.scss'

interface ITopicProps extends ITopic {
  i: number
  page: string
  extraTopicsHidden: boolean
  parts: IParts
  setParts: Dispatch<SetStateAction<IParts>>
  testTotal: ITestTotal
  setTestTotal: Dispatch<SetStateAction<ITestTotal>>
}

const ConstructorFormRow: FC<ITopicProps> = ({
  i,
  page,
  extraTopicsHidden,
  parts,
  setParts,
  testTotal,
  setTestTotal,
  id,
  issue,
  title,
  name,
  value,
  type,
  open,
  amount,
  subtopics,
}) => {
  const exam = useSelector(selectExam)
  const topicsList = useSelector(selectTestConstructor)
  const dispatch = useDispatch()

  const switchOffPart = useCallback(
    (part: string) => {
      const newParts = {
        ...parts,
        [part]: false,
      }

      setParts(newParts)
      saveParts(page, newParts)
    },
    [page, parts, setParts]
  )

  const switchOnPart = useCallback(
    (part: string) => {
      const newParts = {
        ...parts,
        [part]: true,
      }

      setParts(newParts)
      saveParts(page, newParts)
    },
    [page, parts, setParts]
  )

  const handleTopicClick = useCallback(
    (e: MouseEvent) => {
      const elem = e.currentTarget as HTMLElement
      const i = parseInt(elem.dataset.item!)

      const list = topicsList ? [...topicsList] : []
      list[i].open = !list[i].open

      dispatch(fetchTestConstructor(list))
      saveTopicsList(page, list)
    },
    [dispatch, page, topicsList]
  )

  const isExtraPart = type === 'extra'
  const topicNameType = useTopicType(isExtraPart)
  const isRowToogler = Boolean(value === null && open !== null && subtopics)
  const isRowHidden = exam?.name !== 'ct' && isExtraPart && extraTopicsHidden
  const withCounter = value !== null

  const Topic = (
    <div className={cnConstructorForm('Topic')}>
      {subtopics ? (
        <>
          <LinkPseudo
            className={cnConstructorForm(
              'TopicName',
              {
                type: topicNameType,
              },
              ['Link_pseudoBlack']
            )}
            dataItem={i.toString()}
            handleClick={handleTopicClick}
            customChildren
          >
            <Number issue={issue} />
            <div className={cnConstructorForm(withCounter ? 'TopicDesc' : 'TopicDesc-GroupTitle')}>
              <LinkWrapU className="Link_pseudo-U Link_pseudoBlack-U">
                {title}
              </LinkWrapU>
            </div>
          </LinkPseudo>

          <div
            className={cnConstructorForm('TopicSubs', {
              open: Boolean(open),
            })}
          >
            {!isRowToogler && subtopics && (
              <CheckAll page={page} subtopics={subtopics} topicI={i} />
            )}
            {subtopics?.map((subtopic, subtopicI) => (
              <Subtopic
                page={page}
                topicI={i}
                subtopic={subtopic}
                subtopicI={subtopicI}
                testTotal={testTotal}
                setTestTotal={setTestTotal}
                part={type}
                switchOnPart={switchOnPart}
                switchOffPart={switchOffPart}
                isRowTooglerItem={isRowToogler}
                key={`subtopic${subtopic.id}`}
              />
            ))}
          </div>
        </>
      ) : (
        <div
          className={cnConstructorForm('TopicName', {
            view: issue === null && 'numberless',
            type: topicNameType,
          })}
        >
          <Number issue={issue} />
          <div className={cnConstructorForm('TopicDesc')}>
            {title}
            &nbsp;·&nbsp;
            <Link
              className="Link_black"
              href={`https://${exam!.domain}/test?theme=${id}`}
            >
              {amount}
              &nbsp;шт.
            </Link>
          </div>
        </div>
      )}
    </div>
  )

  return (
    <div
      className={cnConstructorForm('Row', {
        hidden: isRowHidden,
        toggler: isRowToogler,
      })}
      key={`topic${issue}${i}`}
    >
      {withCounter && (
        <Counter
          className={cnConstructorForm('Counter')}
          page={page}
          name={name}
          index={i}
          value={value!}
          list={topicsList ? [...topicsList] : []}
          testTotal={testTotal}
          setTestTotal={setTestTotal}
          part={type}
          switchOnPart={switchOnPart}
          switchOffPart={switchOffPart}
        />
      )}
      {Topic}
    </div>
  )
}

export default ConstructorFormRow
