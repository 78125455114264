import { compose, composeU } from '@bem-react/core'

import FormAlertBase, {
  withViewSuccess,
  withViewError,
} from 'components/FormAlert'

export const FormAlert = compose(composeU(withViewSuccess, withViewError))(
  FormAlertBase
)
