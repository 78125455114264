import React, { FC, useEffect, useState } from 'react'
// import { useDetectAdBlock } from 'adblock-detect-react'
import { cn } from '@bem-react/classname'

import { isDevMode } from 'utils'
import { AdParts, getAdsPart, selectAds } from 'modules/Ads'
import AdZone from 'pages/App/components/Ads/AdZone'

import ContentAd from '../ContentAd'
import ContentTopAdYandex from './ContentTopAdYandex'
import ContentTopAdGoogle from './ContentTopAdGoogle'
import ContentTopAdBlockDetect from './ContentTopAdBlockDetect'
import './ContentTopAd.scss'
import { useSelector } from 'react-redux'
import { selectorIsCTExam, selectorIsKZExam } from 'modules/Exam'

export const cnContentTopAd = cn('ContentTopAd')

const ContentTopAd: FC = () => {
  const isDev = isDevMode()
  // const adBlockDetected = useDetectAdBlock()
  const isCTExam = useSelector(selectorIsCTExam);
  const isKZExam = useSelector(selectorIsKZExam);

  const [detectorAdBlock, setDetectorAdBlock] = useState(false);
  const topad = getAdsPart(useSelector(selectAds), AdParts.TOP);
  const havetop = topad !== null && topad?.length !== 0;
  useEffect(() => {
    if (!havetop) return;
    const url = "https://yandex.ru/ads/system/context.js";
    fetch(url, {
        method: "GET",
        // mode: "no-cors",
        cache: "default",
    }).then((resp)=>{
      resp.text().then((text)=> {
        if (text) {
          if (text.length < 10000) {
            setDetectorAdBlock(true);
          } else {

            setTimeout(function(){
              let yandex_banner_f = document.querySelectorAll('div[id="yandex_rtb_R-A-154002-8"]');
              if (yandex_banner_f.length > 0) {
                if (getComputedStyle(yandex_banner_f[0]).display === 'none') {
                  setDetectorAdBlock(true);
                } else {
                  document.cookie = 'Ochop9=1; Max-Age=0;secure;path="/"';
                }
              } else {
                let yandex_banner_s = document.querySelectorAll('div[id="yandex_rtb_R-A-154002-2"]');
                if (yandex_banner_s.length > 0) {
                  if (getComputedStyle(yandex_banner_s[0]).display === 'none') {
                    setDetectorAdBlock(true);
                  } else {
                    document.cookie = 'Ochop9=1; Max-Age=0;secure;path="/"';
                  }
                }
              }             
            }, 1500);

            // document.cookie = 'bda=1; Max-Age=0;secure;path="/"';
          }
        } else {
          setDetectorAdBlock(true);
        }
      })
    })
      .catch(() => {
        setDetectorAdBlock(true);
      });
}, []);

  return (
    havetop?<ContentAd className={cnContentTopAd() + " ContentTopAd_fixedHeight" + ((isCTExam || isKZExam)? " ContentTopAd_fixedHeightCT" : '')}>
      {isDev ? (
        <>
          <ContentTopAdGoogle />
          <ContentTopAdYandex />
        </>
      ) : (
        <AdZone parentClassName={cnContentTopAd()} part={AdParts.TOP} />
      )}

      {detectorAdBlock && <ContentTopAdBlockDetect />}
    </ContentAd>:<></>
  )
}

export default ContentTopAd
