import React, { FC, ReactNode, MouseEvent } from 'react'
import { Link as RouterLink, NavLink as RouterNavLink } from 'react-router-dom'
import { cn } from '@bem-react/classname'
import cx from 'classnames'

import './Link.scss'
import './_selected/Link_selected.scss'

export const cnLink = cn('Link')

interface ILinkProps {
  className?: string
  classNameU?: string
  activeClassName?: string | boolean
  to?: string
  href?: string
  exact?: boolean
  title?: string
  target?: string
  rel?: string
  dataItem?: string
  id?: string
  role?: string
  tabIndex?: number
  handleClick?: (e: MouseEvent<HTMLElement>) => void
  children: ReactNode
}

/**
 * @todo (ilyasidorchik)
 * Отрефакторить компонент с @bem-react
 */
const Link: FC<ILinkProps> = ({
  className,
  classNameU,
  activeClassName,
  to,
  href = '/',
  title,
  target,
  exact = false,
  dataItem,
  handleClick,
  children,
  ...props
}) => {
  let rel = props.rel
  if (target === '_blank' && !rel) {
    rel = 'noopener noreferrer'
  }

  if (to && (activeClassName || exact) && classNameU) {
    return (
      <RouterNavLink
        className={({isActive}) => isActive?cx('Link_selected', activeClassName):cx('Link', className)}
        to={to}
        end={exact}
        title={title}
      >
        <u className={cx('Link-U', classNameU)}>{children}</u>
      </RouterNavLink>
    )
  }

  if (to && (activeClassName || exact)) {
    return (
      <RouterNavLink
        className={({isActive}) => isActive?cx('Link_selected', activeClassName):cx('Link', className)}
        to={to}
        end={exact}
        title={title}
      >
        {children}
      </RouterNavLink>
    )
  }

  if (to) {
    return (
      <RouterLink className={cx('Link', className)} to={to} title={title}>
        {children}
      </RouterLink>
    )
  }

  if (handleClick && classNameU) {
    return (
      <div className={className} data-item={dataItem} onClick={handleClick}>
        <u className={cx('Link-U', classNameU)}>{children}</u>
      </div>
    )
  }

  if (handleClick) {
    return (
      <div className={className} data-item={dataItem} onClick={handleClick}>
        {children}
      </div>
    )
  }

  if (classNameU) {
    return (
      <a
        className={className}
        href={href}
        target={target}
        rel={rel}
        title={title}
        {...props}
      >
        <u className={cx('Link-U', classNameU)}>{children}</u>
      </a>
    )
  }

  return (
    <a
      className={cx('Link', className)}
      href={href}
      target={target}
      rel={rel}
      title={title}
      {...props}
    >
      {children}
    </a>
  )
}

export default Link
