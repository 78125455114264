import { IPasswordInputTypes } from './PasswordToggler.hooks'

export const PasswordInputTypes: IPasswordInputTypes = {
  PASSWORD: 'password',
  TEXT: 'text',
}
export const PasswordTogglerTitles = {
  SHOW:
    'Показать пароль как обычный текст. ' +
    'Внимание: ваш пароль отобразится на экране',
  HIDE: 'Скрыть пароль',
}
