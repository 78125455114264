import {
  ChangeEvent,
  FormEvent,
  RefObject,
  ChangeEventHandler,
  FormEventHandler,
  useState,
  useCallback,
  useRef,
} from 'react'
import { searchInCatalog } from '../api'
import { useSelector } from 'react-redux'
import { selectorIsUAExam } from '../../../../../modules/Exam'

interface IHelpers {
  formAction: string
  inputRef: RefObject<HTMLInputElement>
  id: string
  loading: boolean
  error: string
  handleChange: ChangeEventHandler
  handleSubmit: FormEventHandler
}

export const useHelpers = (): IHelpers => {
  const [id, setId] = useState('')
  const [loading, isLoading] = useState(false)
  const [error, setError] = useState('')
  const formAction = `/problem`
  const inputRef = useRef<HTMLInputElement>(null)
  const isUAExam = useSelector(selectorIsUAExam)

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value: newId } = e.currentTarget

      setId(newId)

      if (error) {
        setError('')
      }
    },
    [setId, error]
  )

  const handleSubmit = useCallback(
    async (e: FormEvent<HTMLButtonElement>) => {
      e.preventDefault()

      setError('')
      isLoading(true)

      if (id) {
        try {
          const response = await searchInCatalog(id)
          const { success: taskExist } = response.data

          if (taskExist) {
            setError('')
            window.location.assign(`${formAction}?id=${id}`)
          } else {
            setError(isUAExam ? `За запитом нічого не знайдено` : `По запросу ничего не найдено`)
          }
        } catch (err) {
          setError(isUAExam ? `Вибачте, спробуйте ще раз` : `Извините, попробуйте еще раз`)
          console.error(err)
        }
      }

      if (!id || error) {
        inputRef!.current!.focus()
      }

      isLoading(false)
    },
    [id, error, formAction, inputRef, isUAExam]
  )

  return {
    formAction,
    inputRef,
    id,
    loading,
    error,
    handleChange,
    handleSubmit,
  }
}
