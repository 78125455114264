import React, {
  FC,
  ReactNode,
  useState,
  useCallback,
  useRef,
  useEffect,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { cn } from '@bem-react/classname'
import { compose } from '@bem-react/core'

import {
  selectAuthLoading,
  selectAuthStatus,
  fetchAuthStatusRequest,
} from 'modules/Auth'
import { isEmail } from 'utils'
import TitleBase, { withLevel2 } from 'components/Title'
import InputBase, { withSizeL as withInputSizeL } from 'components/Input'
import ButtonBase, {
  withViewDefault,
  withSizeL as withButtonSizeL,
} from 'components/Button'

import ProfileAuthLinks from './ProfileAuthLinks'
import { useEmailUpdate } from './ProfileAuth.hooks'
import './ProfileAuth.scss'
import { useMobileVersion } from 'hooks'
import Checkbox from '../../../../components/Checkbox'
import { selectorIsUAExam } from 'modules/Exam/selectors'

const Title = compose(withLevel2)(TitleBase)
const Input = compose(withInputSizeL)(InputBase)
const Button = compose(withViewDefault, withButtonSizeL)(ButtonBase)
export const cnProfileAuth = cn('ProfileAuth')

interface IFieldState {
  value: string
  error: boolean
}

/**
 * @todo (ilyasidorchik): Вынести сущности, переписать,
 * на библиотеке, например Реакт-хукс-форм,
 * сделать валидный текст уведомления
 */
const ProfileAuth: FC = () => {
  const [email, setEmail] = useState<IFieldState>({ value: '', error: false })
  const [password, setPassword] = useState<IFieldState>({
    value: '',
    error: false,
  })
  const [submitted, isSubmitted] = useState(false)
  const [readyForCheck, isReadyForCheck] = useState(false)
  const [alertText, setAlertText] = useState<ReactNode | string>('')
  const [guestInputCheckBox, setGuestInputCheckBox] = useState(false)

  const loading = useSelector(selectAuthLoading)
  const auth = useSelector(selectAuthStatus)
  const isUAExam = useSelector(selectorIsUAExam)
  const dispatch = useDispatch()

  const emailInput = useRef<HTMLInputElement | null>(null)
  const passwordInput = useRef<HTMLInputElement | null>(null)
  // const guestInput = useRef<HTMLInputElement | null>(null)

  const isMobile = useMobileVersion()

  const handleInputChange = useCallback(
    (e) => {
      const { name, value: newValue } = e.target
      let newError = false

      switch (name) {
        case 'email':
          setEmail({
            ...email,
            value: newValue,
          })

          if (submitted) {
            newError = !newValue.trim() || !isEmail(newValue) ? true : false

            setEmail({
              value: newValue,
              error: newError,
            })
          }

          break
        case 'password':
          setPassword({
            ...password,
            value: newValue,
          })

          if (submitted) {
            newError = !newValue ? true : false

            setPassword({
              value: newValue,
              error: newError,
            })
          }

          break
        default:
          break
      }
    },
    [submitted, email, password]
  )

  const handleInputFocus = useCallback((ref) => {
    ref.current.focus()
  }, [])

  const handleSubmit = useCallback(
    (e) => {
      const newEmail = email.value.trim(),
        newPassword = password.value.trim()

      e.preventDefault()
      isSubmitted(true)
      isReadyForCheck(true)

      // Validation
      if (!newEmail || !isEmail(newEmail) || !newPassword) {
        let newAlertText: ReactNode | null = ''

        if (!newEmail) {
          newAlertText = (
            <span>
              <span className="ProfileAuth-AlertContent">Без почты</span>
              <span className="ProfileAuth-AlertContent">нельзя :-(</span>
            </span>
          )
          handleInputFocus(emailInput)
        } else if (!isEmail(newEmail)) {
          newAlertText = (
            <span>
              <span className="ProfileAuth-AlertContent">Нужна</span>
              <span className="ProfileAuth-AlertContent">почта :-(</span>
            </span>
          )
          handleInputFocus(emailInput)
        } else if (!newPassword) {
          newAlertText = (
            <span>
              <span className="ProfileAuth-AlertContent">Без пароля</span>
              <span className="ProfileAuth-AlertContent">нельзя :-(</span>
            </span>
          )
          handleInputFocus(passwordInput)
        }

        if (!newEmail && !newPassword) {
          newAlertText = (
            <span>
              <span className="ProfileAuth-AlertContent">Нет почты</span>
              <span className="ProfileAuth-AlertContent">и пароля</span>
            </span>
          )
          handleInputFocus(emailInput)
        }

        if (!newEmail || !newEmail.match(/.+@.+\..+/i)) {
          setEmail({
            ...email,
            error: true,
          })
        }

        if (
          (isEmail(newEmail) && !newPassword) ||
          (!newEmail && !newPassword)
        ) {
          setPassword({
            ...password,
            error: true,
          })
        }

        setAlertText(newAlertText)
        isReadyForCheck(false)

        return
      }

      setAlertText('')

      dispatch(
        fetchAuthStatusRequest({
          user: newEmail,
          password: newPassword,
          guest: guestInputCheckBox
        })
      )
    },
    [email, password, dispatch, handleInputFocus, guestInputCheckBox]
  )

  useEffect(() => {
    if (readyForCheck && !loading && !auth) {
      setAlertText(
        <span>
          <span className="ProfileAuth-AlertContent">Не та почта</span>
          <span className="ProfileAuth-AlertContent">или пароль</span>
        </span>
      )
      handleInputFocus(emailInput)

      isReadyForCheck(false)
    }
  }, [readyForCheck, loading, auth, handleInputFocus])

  useEmailUpdate(email.value)

  const title = isUAExam ? `Вхід на сайт` : 'Вход на сайт'

  return (
    <div className={cnProfileAuth()}>
      <Title
        className={cnProfileAuth('Title', { platform: 'mobile' })}
        level="2"
      >
        {title}
      </Title>
      <h2
        className={cnProfileAuth('Title', { platform: 'desktop' }, [
          'SidebarInfo-Title',
        ])}
      >
        {title}
      </h2>

      <form
        className={cnProfileAuth('Form')}
        action=""
        method="POST"
        onSubmit={handleSubmit}
      >
        <div className={cnProfileAuth('InputGroup')}>
          <label className={cnProfileAuth('Label')}>
            <div className={cnProfileAuth('LabelText')}>{isUAExam ? `Електронна пошта` : `Электронная почта`}</div>
            <Input
              className={cnProfileAuth('Input', {
                email: true,
                invalid: email.error,
              })}
              size={isMobile ? 'l' : undefined}
              type="email"
              name="email"
              id="email"
              autoComplete="username"
              value={email.value}
              placeholder={isUAExam ? `Електронна пошта` : `Электронная почта`}
              innerRef={emailInput}
              handleChange={handleInputChange}
            />
          </label>

          <label className={cnProfileAuth('Label')}>
            <div className={cnProfileAuth('LabelText')}>Пароль</div>
            <Input
              className={cnProfileAuth('Input', {
                password: true,
                invalid: password.error,
              })}
              size={isMobile ? 'l' : undefined}
              type="password"
              name="password"
              id="current-password"
              autoComplete="current-password"
              value={password.value}
              placeholder="Пароль"
              innerRef={passwordInput}
              handleChange={handleInputChange}
            />
          </label>
          {/*{isMobile?"":*/}
          {/*<label><input style={{margin: 0}} type="checkbox" name="guest" ref={guestInput}/> Чужой компьютер</label>}*/}
          {isMobile ? "" : <label style={{display: "flex", alignItems: 'center'}}><Checkbox name={'guest'} value={guestInputCheckBox} fakeCheckboxClassName={"ProfileAuth-CheckBox"} onChange={() => setGuestInputCheckBox(!guestInputCheckBox)}/> {isUAExam ? `Чужий комп'ютер` : `Чужой компьютер`}</label>}

          <input type="hidden" name="la" value="login" />
        </div>
        <div className="ProfileAuth-ButtonGroup">
          <Button
            className="ProfileAuth-Button"
            view="default"
            size={isMobile ? 'l' : undefined}
            disabled={loading}
          >
            {isUAExam ? `Увійти` : `Войти`}
          </Button>

          <div
            className={cnProfileAuth('Alert', { visible: alertText !== '' })}
          >
            {alertText}
          </div>
        </div>
      </form>

      <ProfileAuthLinks />
    </div>
  )
}

export default ProfileAuth
