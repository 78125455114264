import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { ClassNameFormatter } from '@bem-react/classname'

import { selectAppList, selectAppFetchedStatus } from 'modules/Apps'

interface IAppsProps {
  cnFormatter: ClassNameFormatter
}

const Apps: FC<IAppsProps> = ({ cnFormatter }) => {
  const data = useSelector(selectAppList)
  const dataFetched = useSelector(selectAppFetchedStatus)

  const hidden = (!data || !data?.length) && dataFetched

  return (
    <div className={cnFormatter('Apps', { hidden })}>
      <h3 className="SidebarInfo-Title">Мобильные приложения</h3>
      <div className={cnFormatter('ButtonStoreList')}>
        <a
          className={cnFormatter('ButtonStore', { android: true })}
          href="https://play.google.com/store/apps/details?id=com.reshuege"
          target="_blank"
          rel="noopener noreferrer"
          title="Мобильное приложение для Андроида"
        >
          {' '}
        </a>
        <a
          className={cnFormatter('ButtonStore', { ios: true })}
          href="https://appsto.re/ru/TSavbb.i"
          target="_blank"
          rel="noopener noreferrer"
          title="Мобильное приложение для Айфона"
        >
          {' '}
        </a>
      </div>
    </div>
  )
}

export default Apps
