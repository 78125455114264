import React, {
  FC,
  Dispatch,
  SetStateAction,
  MouseEvent,
  ChangeEvent,
  useEffect,
  useCallback,
  useRef,
} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { compose } from '@bem-react/core'

import { ITestTotal, IParts } from '../ConstructorForm'
import {
  selectTestConstructor,
  fetchTestConstructor,
  selectTestConstructorParts,
} from 'modules/TestConstructor'
import { testPartTitles } from './constants'
import { useHandleSubmit } from './hooks'
import {
  saveParts,
  loadParts,
  saveTestTotal,
  loadTestTotal,
  saveTopicsList,
} from 'components/ConstructorForm/ConstructorForm.utils'
import { updateTestTotal } from 'components/Counter/helpers'
import Checkbox from '../../Checkbox'
import LinkWrapU from 'components/Link/_wrap/-U'
import ButtonBase, { withViewDefault, withSizeL } from 'components/Button'
import '../../Switcher/Switcher.scss'
import '../../Switcher/_vertical/Switcher_vertical.scss'
import '../../Switcher/_checkbox/Switcher_checkbox.scss'

import './ConstructorForm-Buttons.scss'
import { selectorIsUAExam, selectorIsCTExam } from '../../../modules/Exam'

const Button = compose(withViewDefault, withSizeL)(ButtonBase)

interface IButtonsProps {
  page: string
  parts: IParts
  setParts: Dispatch<SetStateAction<IParts>>
  testTotal: ITestTotal
  setTestTotal: Dispatch<SetStateAction<ITestTotal>>
  getNewTestTotalText: (total: number, isUAExam: boolean) => string
}

export interface ITopic {
  id?: number | null
  issue: string | null
  title: string
  name: string
  value: number | null
  type: string
  open: boolean | null
  amount?: number
  subtopics: Array<ISubtopic> | null

  /**
   * @deprecated
   */
  num?: number
}

export interface ISubtopic {
  id: number | null
  title: string
  name?: string
  value: number | null
  checked: boolean
  amount: number
  subtopics: Array<ISubtopic> | null
}

const Buttons: FC<IButtonsProps> = ({
  page,
  parts,
  setParts,
  testTotal,
  setTestTotal,
  getNewTestTotalText,
}) => {
  const buttonTextRef = useRef<HTMLElement>(null)
  const topicsList = useSelector(selectTestConstructor)
  const testParts = useSelector(selectTestConstructorParts)
  const isUAExam = useSelector(selectorIsUAExam)
  const isCTExam = useSelector(selectorIsCTExam)
  const dispatch = useDispatch()

  useEffect(() => {
    if (loadTestTotal(page)) setTestTotal(loadTestTotal(page))
    if (loadParts(page)) setParts(loadParts(page))
  }, [page, setParts, setTestTotal])

  const switchPart = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const part = e.target.name.split('Part')[0]

      if (topicsList) {
        const newValue = !parts[part]
        const countValue = newValue ? 1 : 0

        const willBeSaved = countValue === 1

        const newParts = {
          ...parts,
          [part]: newValue,
        }
        setParts(newParts)
        saveParts(page, newParts)

        const list = [...topicsList]
        let newTestTotalAmount = testTotal.amount
        for (let i = 0; i < list.length; i++) {
          if (list[i].type === part) {
            const oldValue = list[i].value
            const newValue =
              oldValue! > 1 && willBeSaved ? oldValue : countValue

            list[i].value = newValue

            newTestTotalAmount = newTestTotalAmount + (newValue! - oldValue!)
          }
        }

        dispatch(fetchTestConstructor(list))
        saveTopicsList(page, list)

        const newTestTotal = {
          amount: newTestTotalAmount,
          text: getNewTestTotalText(newTestTotalAmount, isUAExam),
        }
        setTestTotal(newTestTotal)
        saveTestTotal(page, newTestTotal)
      }
    },
    [
      topicsList,
      parts,
      setParts,
      page,
      testTotal.amount,
      dispatch,
      getNewTestTotalText,
      setTestTotal,
      isUAExam
    ]
  )

  const handleResetClick = (e: MouseEvent) => {
    e.preventDefault()

    if (topicsList) {
      const list = [...topicsList]
      for (let i = 0; i < list.length; i++) {
        if (list[i].value !== null) {
          list[i].value = 0
        }

        if (list[i].subtopics) {
          list[i].subtopics?.forEach((subtopic) => {
            if (subtopic.value !== null) {
              subtopic.value = 0
            }
          })
        }
      }
      dispatch(fetchTestConstructor(list))
      saveTopicsList(page, list)

      const newTestTotal = updateTestTotal(page, 0, isUAExam)
      setTestTotal(newTestTotal)
      saveTestTotal(page, newTestTotal)

      const newParts = {
        short: false,
        detailed: false,
      }
      setParts(newParts)
      saveParts(page, newParts)
    }
  }

  const handleSubmit = useHandleSubmit(testTotal.amount, buttonTextRef)

  return (
    <div className="ConstructorForm-Buttons">
      <div className="ConstructorForm-ButtonsPanel">
        <Button
          className="ConstructorForm-SubmitButton"
          view="default"
          size="l"
          type="submit"
          tabIndex={topicsList ? topicsList.length : 1}
          handleClick={handleSubmit}
        >
          <span ref={buttonTextRef}>{isUAExam ? `Скласти варіант` : `Составить вариант`} {testTotal.text}</span>
        </Button>
        {isCTExam && <Button
          className="ConstructorForm-SubmitButton CTSubmitButton"
          view="default"
          handleClick={(e) => {
            e.preventDefault();
            window.location.href='/test?a=generate';
            }
          }
        >
          Составить стандартный вариант
        </Button>}

        <div className="Switcher Switcher_vertical Switcher_checkbox ConstructorForm-Switcher">
          {testParts?.map((item) => (
            <label
              className="Label Switcher-Label Switcher_vertical-Label Switcher_checkbox-Label"
              key={item}
            >
              <Checkbox
                className="Switcher-Input"
                fakeCheckboxClassName="FakeCheckbox_blue Switcher_checkbox-FakeCheckbox"
                name={`${item}Part`}
                value={parts[item]}
                onChange={switchPart}
              />
              <span className="Switcher-Text Switcher_checkbox-Text">
                {testPartTitles[item]}
              </span>
            </label>
          ))}
        </div>

        <button
          className="Link Link_wrap Link_pseudo ConstructorForm-ResetButton"
          onClick={handleResetClick}
        >
          <span className="Link_wrap-U">×</span>

          <LinkWrapU className="Link_pseudo-U ConstructorForm-ResetButtonText">
            {isUAExam ? `Прибрати все` : `Убрать все`}
          </LinkWrapU>
        </button>
      </div>
    </div>
  )
}

export default Buttons
