import React, { FC, MouseEventHandler } from 'react'
import { cn } from '@bem-react/classname'

import Button from 'components/Button'

import './ProfileIcon.scss'

interface IProfileIconProps {
  auth: boolean
  selected?: boolean
  handleClick?: MouseEventHandler
}

const ProfileIcon: FC<IProfileIconProps> = ({
  auth,
  selected,
  handleClick,
}) => {
  const cnProfileIcon = cn('ProfileIcon')

  return (
    <Button className={cnProfileIcon({ selected })} handleClick={handleClick}>
      <div className={cnProfileIcon('Content', { auth })} aria-hidden="true" />
      {!auth && <span className={cnProfileIcon('Caption')}>Войти</span>}
    </Button>
  )
}

export default ProfileIcon
