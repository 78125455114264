import React, { FC } from 'react'
import { cn } from '@bem-react/classname'
import cx from 'classnames'

import './Fallback.scss'

interface FallbackProps {
  className?: string
}

const Fallback: FC<FallbackProps> = ({ className }) => {
  const fallback = cn('Fallback')

  return <div className={cx(fallback(), className)}>&nbsp;</div>
}

export default Fallback
