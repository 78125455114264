import axios from 'axios'

import { ICommentFromBackend } from 'modules/Comments'
import { getApiKey, isTestMode } from 'utils'

const API_KEY = getApiKey()
const ENDPONT = 'guestbook/messages'

export interface IGetCommentsResult {
  result: Array<ICommentFromBackend> | null
  count: number
  rightPage: number
  totalPages: number
}

export const getComments = async (rpage?: number | null) => {
  try {
    const response = await axios(
      `/newapi/${ENDPONT}${API_KEY}`,
      {
        params: {
          rpage,
        },
      }
    )

    if (response) {
      const result: IGetCommentsResult = response.data

      if (result) {
        return Promise.resolve(result)
      } else {
        console.error(`Error with /${ENDPONT}/`)
        return Promise.reject()
      }
    }
  } catch (error) {
    if (!isTestMode) {
      console.error(error)
    }
  }
}
