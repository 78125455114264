import {
  ActionFunctionAny,
  Action,
  ReduxCompatibleReducer,
  createAction,
  handleActions,
} from 'redux-actions'

import { ILarinVariant } from 'pages/StudentPage/components/LarinVariants'
import * as constants from './constants'

export interface ILarinVariantsStatusAction {
  type: typeof constants.FETCH_LARIN_VARIANTS
  payload: Array<ILarinVariant> | null
}

// Actions
export const fetchLarinVariants: ActionFunctionAny<Action<Array<
  ILarinVariant
> | null>> = createAction(constants.FETCH_LARIN_VARIANTS)

// Reducers
const larinVariants: ReduxCompatibleReducer<
  Array<ILarinVariant> | null,
  Array<ILarinVariant> | null
> = handleActions(
  {
    [constants.FETCH_LARIN_VARIANTS]: (
      _state: Array<ILarinVariant> | null,
      action
    ) => action.payload,
  },
  null
)

export const initialState = null
export type larinVariantsState = ReturnType<typeof larinVariants>
export default larinVariants
