import { useCallback, useState } from 'react'

import { OnClose } from 'components/Modal'

interface IHelpers {
  visible: boolean
  toggleVisibility: OnClose
}

export const useHelpers = (): IHelpers => {
  const [visible, isVisible] = useState(false)

  const toggleVisibility = useCallback(() => {
    isVisible(!visible)
  }, [visible])

  return { visible, toggleVisibility }
}
