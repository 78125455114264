import { getNewTestTotalText } from 'components/ConstructorForm'
import { saveTestTotal } from 'components/ConstructorForm/ConstructorForm.utils'

interface TestTotal {
  amount: number
  text: string
}

export const updateTestTotal = (
  page: string,
  newTestTotalAmount: number,
  isUAExam: boolean
): TestTotal => {
  const newTestTotalText = getNewTestTotalText(newTestTotalAmount, isUAExam)
  const newTestTotal: TestTotal = {
    amount: newTestTotalAmount,
    text: newTestTotalText,
  }

  saveTestTotal(page, newTestTotal)

  return newTestTotal
}
