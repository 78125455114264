import { individualProfileState } from './duck'

export const getIndividualProfileData = (
  individualProfile: individualProfileState
) => individualProfile.data

export const getIsAllTestStat = (individualProfile: individualProfileState) =>
  individualProfile.isAllTestStat
export const getIsTeacherTestStat = (
  individualProfile: individualProfileState
) => individualProfile.isTeacherTestStat
export const getIsTeacherTestStatWithoutLastMonth = (
  individualProfile: individualProfileState
) => individualProfile.isTeacherTestStatWithoutLastMonth
