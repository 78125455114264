import React, { FC, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { cn } from '@bem-react/classname'
import cx from 'classnames'

import { ISubject } from 'utils/data/subjectList'
import { selectSubjectMenu } from 'modules/SubjectMenu'
import { selectSubject } from 'modules/Subject'
import Fallback from 'components/Fallback'
import MobileMenu from 'components/MobileMenu'
import SubjectMenuItem from './SubjectMenuItem'
import './SubjectMenu.scss'

interface ISubjectMenuProps {
  className?: string
}

export const cnSubjectMenu = cn('SubjectMenu')

const SubjectMenu: FC<ISubjectMenuProps> = ({ className }) => {
  const [mobileMenu, isMobileMenu] = useState<boolean>(false)
  const data = useSelector(selectSubjectMenu)
  const subject = useSelector(selectSubject)

  const toggleMobileMenu = useCallback(() => {
    isMobileMenu(!mobileMenu)
  }, [mobileMenu])

  return (
    <>
      <MobileMenu
        className={cx(cnSubjectMenu( { open: mobileMenu }), className)}
        onClick={toggleMobileMenu}
      >
        {subject?.titleMobile || subject?.title}
      </MobileMenu>

      <nav
        className={cx(
          cnSubjectMenu({ desktop: true, show: mobileMenu, fallback: !data }),
          className
        )}
      >
        {!data &&
          Array(14)
            .fill('')
            .map((item: string, i: number) => (
              <Fallback className={'SubjectMenu-Link_fallback'} key={i} />
            ))}

        {data &&
          data.map(({ title, name, types, disabled }: ISubject, i: number) => (
            <SubjectMenuItem
              title={title}
              name={name}
              types={types}
              disabled={disabled}
              location={subject!.name}
              key={i}
            />
          ))}

        <div className="SubjectMenu-Ruler_clear_both"></div>
      </nav>
    </>
  )
}

export default SubjectMenu
