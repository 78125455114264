import axios from 'axios'

import { getApiKey } from 'utils'

export const removeContact = async (id: number): Promise<void> => {

  const data = new FormData()
  data.append('id', id.toString())

  try {
    await axios({
      method: 'POST',
      url: `/get_messages${getApiKey()}`,
      data,
      headers: { 'Content-Type': 'multipart/form-data' },
      params: {
        ajax: 1,
        a: 'remove_user',
      },
      withCredentials: true,
    })
  } catch (error) {
    throw error
  }
}
