import { takeLatest, call, put, fork } from 'redux-saga/effects'

// import {default as test_resp} from "./test_resp.json";

import { IFetchStatIndividualProfileAction, fetchStat, setStat } from './duck'
import { getStatIndividualProfile } from './api'
import { IStatIndividualProfileFromBackEnd } from './types'

// Watchers
function* fetchStatIndividualProfileWatcher() {
  yield takeLatest(fetchStat, fetchStatIndividualProfileFlow)
}

// Flows
function* fetchStatIndividualProfileFlow(
  action: IFetchStatIndividualProfileAction
) {
  const statIndividualProfile: IStatIndividualProfileFromBackEnd = yield call(
    getStatIndividualProfile
  )

  function isIStatIndividualProfileFromBackEnd(
    obj: any
  ): obj is IStatIndividualProfileFromBackEnd {
    return obj.groups !== undefined
  }

  if (isIStatIndividualProfileFromBackEnd(statIndividualProfile)) {
    yield put(setStat(statIndividualProfile))
  }

  // yield put(setStat(test_resp))
}

export default function* sagas() {
  yield fork(fetchStatIndividualProfileWatcher)
}
