import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { cn } from '@bem-react/classname'

import { selectChatOpen } from 'modules/Chat'
import ChatPopup from './ChatPopup'
import ChatButton from 'pages/App/components/Chat/ChatButton'

const Chat: FC = () => {
  const cnChat = cn('Chat')
  const open = useSelector(selectChatOpen)

  return <div className={cnChat()}>{open ? <ChatPopup /> : <ChatButton />}</div>
}

export default Chat
