import React, { FC, useState, useCallback } from 'react'
import cx from 'classnames'

import Text from 'components/Text'
import 'components/Alert/Alert.scss'

interface IAlertAuthProps {
  className?: string
}

// TODO: Remove hardcode, test and bem-react for classnames
const AlertAuth: FC<IAlertAuthProps> = ({ className }) => {
  const [visible1, isVisible1] = useState(true)
  const [visible2, isVisible2] = useState(true)

  const handleClose = useCallback((e) => {
    const { id } = e.target.dataset

    switch (id) {
      case '1':
        isVisible1(false)
        break

      case '2':
        isVisible2(false)
        break
    }
  }, [])

  return (
    <>
      {visible1 && (
        <div className={cx('Alert', className)}>
          <header className="Alert-Header">
            <div className="Alert-Title">Система оповещений</div>
            <div
              className="Alert-CloseButton"
              data-id="1"
              onClick={handleClose}
            >
              ×
            </div>
          </header>

          <div className="Alert-Text Alert-Text_mobile">
            Зачем работать под своим логином? Система будет вести статистику
            всех решённых заданий и чаще предлагать те из них, где было
            совершено больше всего ошибок. Компьютерный интеллект сделает
            подготовку умной.
          </div>
          <Text className="Alert-Text Alert-Text_desktop">
            Зачем работать под своим логином? Система будет вести статистику
            всех решённых заданий и чаще предлагать те из них, где было
            совершено больше всего ошибок. Компьютерный интеллект сделает
            подготовку умной.
          </Text>
        </div>
      )}

      {visible2 && (
        <div className="Alert">
          <header className="Alert-Header">
            <div className="Alert-Title Alert-Title_mobile name">
              Готовьтесь эффективнее
            </div>
            <div className="Alert-Title Alert-Title_desktop name">
              Система оповещений
            </div>
            <div
              className="Alert-CloseButton"
              data-id="2"
              onClick={handleClose}
            >
              ×
            </div>
          </header>

          <div className="Alert-Text Alert-Text_mobile">
            Используйте персонализи
            <span className="Alert-Hyphen">
              -<wbr />
            </span>
            рованные сервисы и искусст
            <span className="Alert-Hyphen">
              -<wbr />
            </span>
            венный интеллект. Для дос
            <span className="Alert-Hyphen">
              -<wbr />
            </span>
            тупа войдите в систему.
          </div>
          <Text className="Alert-Text Alert-Text_desktop">
            Войдите в систему под своим именем. Вам будут доступны
            дополнительные закрытые сервисы, варианты и задания.
          </Text>
        </div>
      )}
    </>
  )
}

export default AlertAuth
