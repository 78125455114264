export const MonthListRU = [
  'январские',
  'февральские',
  'мартовские',
  'апрельские',
  'майские',
  'июньские',
  'июльские',
  'августовские',
  'сентябрьские',
  'октябрьские',
  'ноябрьские',
  'декабрьские',
]

export const MonthListUA = [
  'січневі',
  'лютневі',
  'березневі',
  'квітневі',
  'травневі',
  'червневі',
  'липневі',
  'серпневі',
  'вересневі',
  'жовтневі',
  'листопадові',
  'грудневі'
]
