import { takeLatest, takeEvery, call, put, select, fork } from 'redux-saga/effects'

import { IMessage } from 'pages/App/components/Chat/ChatPopup/Dialog/Message'

import {
  IOpenChatDialogAction,
  ICloseChatDialogAction,
  ISendChatMessageAction,
  IFetchChatDialogAction,
  IFetchUnreadMessagesAction,
  IDeleteUnreadMessageApiAction,
  openChatDialog,
  closeChatDialog,
  fetchChatDialog,
  setChatDialogInfo,
  setChatDialogOpen,
  setChatDialog,
  resetChatDialogInfo,
  resetChatDialog,
  sendChatMessage,
  updateChatDialog,
  fetchUnreadMessages,
  setUnreadMessages,
  deleteUnreadMessageApi,
  deleteUnreadMessageStore,
} from './duck'
import { selectChatListEl } from './selectors'
import { getDialog, requestUnreadMessages, sendMessage } from './api'
import { markMessageRead } from '../../pages/App/components/Alerts/AlertListChat/api'

// Watchers
function* openChatDialogWatcher() {
  yield takeLatest(openChatDialog, openChatDialogFlow)
}

function* closeChatDialogWatcher() {
  yield takeLatest(closeChatDialog, closeChatDialogFlow)
}

function* fetchChatDialogWatcher() {
  yield takeLatest(fetchChatDialog, fetchChatDialogFlow)
}

function* sendChatMessageWatcher() {
  yield takeLatest(sendChatMessage, sendChatMessageFlow)
}

function* fetchUnreadMessagesWatcher() {
  yield takeLatest(fetchUnreadMessages, fetchUnreadMessagesFlow)
}

function* deleteUnreadMessageWatcher() {
  yield takeEvery(deleteUnreadMessageApi, deleteUnreadMessagesFlow)
}

// Flows
function* openChatDialogFlow(action: IOpenChatDialogAction) {
  const dialogInfo = action.payload

  yield put(setChatDialogInfo(dialogInfo))
  yield put(setChatDialogOpen(true))
  yield put(fetchChatDialog(dialogInfo.toId))
}

function* closeChatDialogFlow(action: ICloseChatDialogAction) {
  yield put(setChatDialogOpen(false))
  yield put(resetChatDialogInfo())
  yield put(resetChatDialog())
}

function* fetchChatDialogFlow(action: IFetchChatDialogAction) {
  const dialog: Array<IMessage> = yield call(getDialog, action.payload)

  yield put(setChatDialog(dialog))
}

function* sendChatMessageFlow(action: ISendChatMessageAction) {
  const message = action.payload
  const listEl: HTMLDivElement | null = yield select(selectChatListEl)

  yield call(sendMessage, message)
  yield put(updateChatDialog(message))
  listEl!.scrollTop = listEl!.scrollHeight
  yield put(fetchChatDialog(message.id))
}

function* fetchUnreadMessagesFlow(action: IFetchUnreadMessagesAction) {
  const unreadMessages: Array<IMessage> = yield call(requestUnreadMessages)

  yield put(setUnreadMessages(unreadMessages))
}

function* deleteUnreadMessagesFlow(action: IDeleteUnreadMessageApiAction) {
  const id_message = action.payload
  yield put(deleteUnreadMessageStore(id_message))
  yield call(markMessageRead, id_message)
}

export default function* sagas() {
  yield fork(openChatDialogWatcher)
  yield fork(closeChatDialogWatcher)
  yield fork(fetchChatDialogWatcher)
  yield fork(sendChatMessageWatcher)
  yield fork(fetchUnreadMessagesWatcher)
  yield fork(deleteUnreadMessageWatcher)
}
