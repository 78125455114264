import { MouseEvent, RefObject, useCallback } from 'react'

import { shake } from 'utils'

export const useHandleSubmit = (amount: number, ref: RefObject<HTMLElement>) =>
  useCallback(
    (e: MouseEvent) => {
      if (!amount) {
        e.preventDefault()

        shake(ref)
      }
    },
    [amount, ref]
  )
