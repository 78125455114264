import {
  FC,
  useCallback,
  Dispatch,
  SetStateAction,
  MouseEvent,
  ChangeEvent,
  RefObject,
  ForwardedRef,
} from 'react'
import { cn } from '@bem-react/classname'

import './Input.scss'

export const cnInput = cn('Input')

interface IInputProps {
  className?: string
  type?: string
  name?: string
  id?: string
  value?: string
  placeholder?: string
  autoFocus?: boolean
  autoComplete?: string
  tabIndex?: number
  innerRef?: RefObject<HTMLInputElement> | ForwardedRef<HTMLInputElement>
  setValue?: Dispatch<SetStateAction<string>>
  handleClick?: (e: MouseEvent<HTMLInputElement>) => void
  handleChange?: (e: ChangeEvent<HTMLInputElement>) => void
}

const Input: FC<IInputProps> = ({
  className,
  type = 'text',
  innerRef,
  setValue,
  handleClick: handleSpecialClick,
  handleChange: handleSpecialChange,
  ...props
}) => {
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (!setValue) return

      setValue(e.target.value)
    },
    [setValue]
  )

  return (
    <input
      className={cnInput(null, [className])}
      type={type}
      onClick={handleSpecialClick}
      onChange={handleSpecialChange ? handleSpecialChange : handleChange}
      data-testid="Input"
      ref={innerRef}
      {...props}
    />
  )
}

export default Input
