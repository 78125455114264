import { TeacherJournalState } from "./duck";
import { IPortals, IProblem, IProbResult, IPupilGroup, ITest, ITests, IUser, IUserResult, IUserResults } from "./types";

export const getPortals = (state: TeacherJournalState) => {
    const outAvailable = [] as IPortals
    const outUnavailable = [] as IPortals
    state.portals.forEach(portal => {
        let visible = false
        const pout = {...portal}
        for (let subj in portal.subjects){
            const groups = getGroups(state, portal.id, subj)
            if (groups.length){
                visible = true
            } else {
                delete pout.subjects[subj]
            }
        }
        if (visible){
            outAvailable.push(pout)
        } else {
            outUnavailable.push(pout)
        }
    });
    return {availablePortals: outAvailable, unavailablePortals: outUnavailable}
}

export const getGroups = (state: TeacherJournalState, portal: string, subject: string) => {
    return state.groups.filter(item => item.portal === portal && item.subject === subject).sort((a,b)=>a.archive !== b.archive? a.archive - b.archive: a.id === -1? 1: b.id === -1? -1: a.name.toLowerCase() > b.name.toLowerCase() ? 1: -1)
}

export const getSort = (state: TeacherJournalState) => state.options.sort

export const getGroup = (state: TeacherJournalState) => 
    state.groups.filter((i) => i.id === state.options.selectedGroup?.id && i.subject === state.options.selectedGroup?.subject && i.portal === state.options.selectedGroup?.portal)[0]

export const getJournal = (state: TeacherJournalState, group:IPupilGroup) => {
    //const group = getGroup(state)
    const users = state.users.filter((i)=>group.members?.indexOf(i.id) !== -1)
    users.sort((a,b) => {if (a.reverse_name > b.reverse_name) return 1; if (a.reverse_name < b.reverse_name) return -1; return 0})
    const results = state.results.filter((i)=> i.portal === group.portal && i.subject === group.subject && group.members?.indexOf(i.user) !== -1)
    const test_ids = results.map((i)=>i.test)
    const tests = state.tests.filter((i)=>i.portal === group.portal && i.subject === group.subject && test_ids.indexOf(i.id) !== -1 && !i.journal_hide)
    tests.sort((a, b) => {
        const a_date = a.sortdate?a.sortdate:a.time
        const b_date = b.sortdate?b.sortdate:b.time
        if (a_date > b_date) {
            return state.options.sort === "DESC"?-1:1
        }
        if (a_date < b_date) {
            return state.options.sort === "DESC"?1:-1
        }
        if (a.id > b.id) {
            return state.options.sort === "DESC"?-1:1
        }
        if (a.id < b.id) {
            return state.options.sort === "DESC"?1:-1
        }
        return 0
    })
    return {users, results, tests}
}

export const getMark = (result: IUserResult, test: ITest): {score: number, lowered: boolean, mark:number} => {
    let score = 0
    for (let i = 0; i < result.probs.length; i++){
        score += result.probs[i].res
    }
    //console.log(test)
    let lowered = false
    let mark = 0
    if (test.marks){
        mark = 2
        for (let i = 0; i < test.marks.length; i++){
            if (score >= test.marks[i]){
                mark++
            }
        }
    }
    if (mark && test.lower_mark && test.valid_to && test.valid_to < result.time){
        lowered = true
        mark = mark - 1
    }
    return {score, lowered, mark}
}
export const getMean = (state:TeacherJournalState, tests:ITests, results: IUserResults, user:IUser):number|null => {
    let sum = 0;
    let count = 0;
    let testsf = tests
    if (state.options.meanFrom){
        testsf = testsf.filter((item)=>((item.sortdate?item.sortdate:item.time) >= state.options.meanFrom))
    }
    if (state.options.meanTo){
        testsf = testsf.filter((item)=>((item.sortdate?item.sortdate:item.time) <= state.options.meanTo))
    }
    for (let i=0; i<testsf.length; i++){
        let test = testsf[i]
        let tmark = getTeacherMark(state, test, user)
        if (tmark && !Number.isNaN(parseInt(tmark))) {
            sum += parseInt(tmark)
            count += 1
            continue
        }
        let marks = results.filter((item)=>item.test === test.id).map((i)=>getMark(i, test)).map((i)=>i.mark)
        if (marks.length) {
            sum += Math.max(...marks)
            count += 1
        }
    }
    if (!count) {
        return null
    }
    return Math.round(sum / count * 10)/10
}
export const getTestMean = (state: TeacherJournalState, test:ITest, results: IUserResults, users:IUser[]) => {
    let sum = 0
    let count = 0;
    const marks = {} as {[key: number]: number}
    for (let i=0; i<results.length; i++){
        let mark = getMark(results[i], test)
        if (mark.mark && (!marks.hasOwnProperty(results[i].user) || mark.mark > marks[results[i].user])){
            marks[results[i].user] = mark.mark
        }
    }
    for (let i=0; i < users.length; i++){
        let user = users[i]
        let tmark = getTeacherMark(state, test, user)
        if (tmark && !Number.isNaN(parseInt(tmark))){
            marks[user.id] = parseInt(tmark)
        }
    }
    for (let user_id in marks){
        sum += marks[user_id]
        count += 1
    }
    if (!count) {
        return null
    }
    //console.log(sum, count)
    return Math.round(sum / count * 10)/10.
}
export const getProbMean = (test:ITest, results: IUserResults, prob: number) => {
    let sum = 0
    let count = 0;
    const marks = {} as {[key: number]: number}
    for (let i = 0; i < results.length; i++){
        if (results[i].probs.length <= prob){
            continue
        }
        let mark = results[i].probs[prob].res
        if (!marks.hasOwnProperty(results[i].user) || mark > marks[results[i].user]){
            marks[results[i].user] = mark
        }
    }
    for (let user_id in marks){
        sum += marks[user_id]
        count += 1
    }
    if (!count) {
        return null
    }
    //console.log(sum, count)
    return Math.round(sum / count * 10)/10.
}

export const getPortal = (state: TeacherJournalState) => {
    const sel = state.options.selectedGroup!
    let delimiter = '-'
    let hostname = ''
    switch (sel.portal) {
        case 'ct':
            hostname = 'reshu.by'
            delimiter = '.'
            break
        case 'olymp':
            hostname = 'reshuolymp.ru'
            delimiter = '.'
            break
        case 'ent':
            hostname = 'reshuent.ru'
            delimiter = '.'
            break
        case 'zno':
            hostname = 'skladuzno.online'
            delimiter = '.'
            break
        case 'nmt':
            hostname = 'skladunmt.online'
            delimiter = '.'
            break
        default:
            hostname = sel.portal + '.sdamgia.ru'
    }
    return sel.subject + delimiter + hostname
}
export const getTeacherMark = (state: TeacherJournalState, test:ITest, user:IUser) => {
    const res = state.teacherMarks.filter((i) => i.portal === test.portal && i.subject === test.subject && i.test_id === test.id && i.user_id === user.id)
    if (res.length){
        return res[0].mark
    }
    return ''
    }

export const getProbNum = (state: TeacherJournalState, prob: IProbResult|IProblem) => {
    if (prob.hasOwnProperty('prob')){
        let item = prob as IProbResult
        if (!item.portal){
            return item.prob.toString()
        }
        let portal = state.portals.filter(i => i.id === item.portal)[0]
        let subject = portal.subjects[item.subject!]
        return portal.name + ' ' + subject + ' ' + item.prob.toString()
    }
    let item = prob as IProblem
    if (!item.portal){
        return item.id.toString()
    }
    let portal = state.portals.filter(i => i.id === item.portal)[0]
    let subject = portal.subjects[item.subject!]
    return portal.name + ' ' + subject + ' ' + item.id.toString()
}

export const getHref = (test: ITest, prob: IProblem) => {
    let subj = prob.subject?prob.subject:test.subject;
    let portal = prob.portal?prob.portal:test.portal;
    let hostname
    switch (portal){
        case 'ct':
            hostname = subj + '.reshu.by'
            break
        case 'olymp':
            hostname = subj + '.reshuolymp.ru'
            break
        case 'zno':
            hostname = subj + '.skladuzno.online'
            break
        case 'nmt':
            hostname = subj + '.skladunmt.online'
            break
        case 'ent':
            hostname = subj + '.reshuent.kz'
            break
        default:
            hostname = subj + '-' + portal + '.sdamgia.ru'
    }

    return 'https://' + hostname + '/problem?id=' + prob.id.toString()
}