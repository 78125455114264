const STORE_KEY = 'CHAT'

export const OPEN_CHAT = `${STORE_KEY}/OPEN_CHAT`
export const CLOSE_CHAT = `${STORE_KEY}/CLOSE_CHAT`
export const SET_CHAT_DIALOG_INFO = `${STORE_KEY}/SET_CHAT_DIALOG_INFO`
export const RESET_CHAT_DIALOG_INFO = `${STORE_KEY}/RESET_CHAT_DIALOG_INFO`

export const FETCH_CHAT_DIALOG = `${STORE_KEY}/FETCH_CHAT_DIALOG`
export const SET_CHAT_DIALOG = `${STORE_KEY}/SET_CHAT_DIALOG`
export const UPDATE_CHAT_DIALOG = `${STORE_KEY}/UPDATE_CHAT_DIALOG`
export const RESET_CHAT_DIALOG = `${STORE_KEY}/RESET_CHAT_DIALOG`

export const OPEN_CHAT_DIALOG = `${STORE_KEY}/OPEN_CHAT_DIALOG`
export const CLOSE_CHAT_DIALOG = `${STORE_KEY}/CLOSE_CHAT_DIALOG`

export const SET_CHAT_DIALOG_OPEN = `${STORE_KEY}/SET_CHAT_DIALOG_OPEN`

export const SET_CHAT_LIST_EL = `${STORE_KEY}/SET_CHAT_LIST_EL`

export const SEND_CHAT_MESSAGE = `${STORE_KEY}/SEND_CHAT_MESSAGE`

export const FETCH_UNREAD_MESSAGES = `${STORE_KEY}/FETCH_UNREAD_MESSAGES`
export const SET_UNREAD_MESSAGES = `${STORE_KEY}/SET_UNREAD_MESSAGES`
export const DELETE_UNREAD_MESSAGE_API = `${STORE_KEY}/DELETE_UNREAD_MESSAGE_API`
export const DELETE_UNREAD_MESSAGE_STORE = `${STORE_KEY}/DELETE_UNREAD_MESSAGE_STORE`
