import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { animateScroll } from 'react-scroll'

import { useMobileVersion } from 'hooks'

export const useTopScrollToNewPage = (forDesktop = true, forTouch = true) => {
  const { pathname } = useLocation()
  const isTouch = useMobileVersion()

  useEffect(() => {
    if (!forDesktop || !forTouch) {
      const cancelForDesktop = !isTouch && !forDesktop
      const cancelForTouch = isTouch && !forTouch

      if (cancelForDesktop || cancelForTouch) {
        return
      }
    }

    animateScroll.scrollToTop({ duration: 500 })
  }, [isTouch, pathname, forDesktop, forTouch])
}
