export interface ISubjectType {
  title: string
  name: string
}

export interface ISubject {
  title: string
  titleMobile?: string
  name?: string
  types?: Array<ISubjectType>
  disabled?: boolean
  title_extra?: string
}

export const getSubjectList = (): Array<ISubject> => [
  {
    title: 'Математика',
    types: [
      {
        name: 'Базовый уровень',
        title: 'mathb',
      },
      {
        name: 'Профильный уровень',
        title: 'math',
      },
    ],
  },
  {
    name: 'Информатика',
    title: 'inf',
  },
  {
    name: 'Русский язык',
    title: 'rus',
  },
  {
    name: 'Английский язык',
    title: 'en',
  },
  {
    name: 'Немецкий язык',
    title: 'de',
  },
  {
    name: 'Французcкий язык',
    title: 'fr',
  },
  {
    name: 'Испанский язык',
    title: 'sp',
  },
  {
    name: 'Физика',
    title: 'phys',
  },
  {
    name: 'Химия',
    title: 'chem',
  },
  {
    name: 'Биология',
    title: 'bio',
  },
  {
    name: 'География',
    title: 'geo',
  },
  {
    name: 'Обществознание',
    title: 'soc',
  },
  {
    name: 'Литература',
    title: 'lit',
  },
  {
    name: 'История',
    title: 'hist',
  },
]
