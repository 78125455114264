
import { combineReducers } from "redux";

import { createAction } from '@reduxjs/toolkit'
import * as constants from './constants'
import { IGroups, IGroupSel, IOptions, IPortals, ITeacherMark, ITest, ITests, IUserResult, IUserResults, IUsers } from "./types";

export const fetchGroups = createAction<undefined>(constants.FETCH_GROUPS)
export const initPortals = createAction<IPortals>(constants.INIT_PORTALS)
export const appendUsers = createAction<IUsers>(constants.APPEND_USERS)
export const appendTests = createAction<ITests>(constants.APPEND_TESTS)
export const appendGroups = createAction<IGroups>(constants.APPEND_GROUPS)
export const appendResults = createAction<IUserResults>(constants.APPEND_RESULTS)
export const setError = createAction<string>(constants.SET_ERROR)

export const setGroup = createAction<IGroupSel|undefined>(constants.SET_GROUP)
export const setSort = createAction<"ASC"|"DESC">(constants.SET_SORT)
export const setMeanFrom = createAction<Date|null>(constants.SET_MEAN_FROM)
export const setMeanTo = createAction<Date|null>(constants.SET_MEAN_TO)

export const setLoaded = createAction<IGroupSel>(constants.SET_GROUP_LOADED)
export const setGroupMembers = createAction<{group: IGroupSel, members: Array<number>}>(constants.SET_GROUP_MEMBERS)
export const toggleTest = createAction<number>(constants.TOGGLE_TEST)
export const updateTest = createAction<ITest>(constants.UPDATE_TEST)

export const initTeacherMarks = createAction<ITeacherMark[]>(constants.INIT_TEACHER_MARKS)
export const setTeacherMark = createAction<ITeacherMark>(constants.SET_TEACHER_MARK)
export const deleteResult = createAction<IUserResult>(constants.DELETE_RESULT)

export const setHoverTest = createAction<number|null>(constants.SET_HOVER_TEST)
export const setHoverUser = createAction<number|null>(constants.SET_HOVER_USER)

export const toggleShowMark = createAction<undefined>(constants.TOGGLE_SHOWMARK)

const portals = function(state: typeof initialState.portals|undefined, 
    action: 
      ReturnType<typeof initPortals>
      ) {
    let _state = state !== undefined ? state : initialState.portals
    switch (action.type) {
      case initPortals.type:
        let subjOrder: {[key: string]: number} = {
          'math': 1, 'inf': 2, 'rus': 3, 'en': 4, 'de': 5, 'fr': 6, 'sp': 7, 'phys': 8, 'chem': 9,
          'bio': 10, 'geo': 11, 'soc': 12, 'lit': 13, 'hist': 14
        }
        const getOrder = (nameSubj: string) => {
          for (let s in subjOrder) {
            if (nameSubj.includes(s)) {
              return Number(subjOrder[s])
            }
          }
          return 99
        }
        for (let idSubj = 0; idSubj < action.payload.length; idSubj++) {
          let sortT = Object.keys(action.payload[idSubj]['subjects']).sort((a: string, b: string) => {
            let aVal = getOrder(a);
            let bVal = getOrder(b);
            return aVal - bVal
          })
          let newlistSubj: {[key: string]: string} = {};
          for (let item of sortT) {
            newlistSubj[item] = action.payload[idSubj]['subjects'][item]
          }
          action.payload[idSubj]['subjects'] = newlistSubj
        }
        return action.payload
    }
    return _state
  }

  
const groups = function(state: typeof initialState.groups|undefined, 
    action: 
      ReturnType<typeof appendGroups>|
      ReturnType<typeof setLoaded>|
      ReturnType<typeof setGroupMembers>
      ) {
    let _state = state !== undefined ? state : initialState.groups
    switch (action.type) {
      case appendGroups.type:
        return [..._state, ...action.payload as IGroups] as IGroups
      case setLoaded.type:
        const newstate = [..._state] as IGroups
        const payload = action.payload as IGroupSel
        newstate.filter((i)=>i.id === payload.id && i.subject === payload.subject && i.portal === payload.portal).forEach((item) => {item.loaded = 1})
        return newstate
      case setGroupMembers.type:
        const mnewstate = [..._state] as IGroups
        const mpayload = action.payload as {group: IGroupSel, members: Array<number>}
        mnewstate.filter((i)=>i.id === mpayload.group.id && i.subject === mpayload.group.subject && i.portal === mpayload.group.portal).forEach((item) => {
          item.members = mpayload.members
        })
        return mnewstate
        
    }
    return _state
  }

const users = function(state: typeof initialState.users|undefined, 
    action: 
      ReturnType<typeof appendUsers>
      ) {
    let _state = state !== undefined ? state : initialState.users
    switch (action.type) {
      case appendUsers.type:
        return [..._state, ...action.payload] as IUsers
    }
    return _state
  }

  
const tests = function(state: typeof initialState.tests|undefined, 
    action: 
      ReturnType<typeof appendTests>|
      ReturnType<typeof toggleTest>
      ) {
    let _state = state !== undefined ? state : initialState.tests
    switch (action.type) {
      case appendTests.type:
        return [..._state.filter(i => (action.payload as ITests).filter(k=>k.id === i.id).length === 0), ...action.payload as ITests]
      case toggleTest.type:
        const newstate = [..._state]
        const test = newstate.filter((t)=>t.id === action.payload)[0]
        test.isOpen = !test.isOpen
        return newstate
    }
    return _state
  }


  const results = function(state: typeof initialState.results|undefined, 
    action: 
      ReturnType<typeof appendResults>|
      ReturnType<typeof deleteResult>
      ) {
    let _state = state !== undefined ? state : initialState.results
    if (appendResults.match(action)){
      return [..._state, ...action.payload]
    }
    if (deleteResult.match(action)){
      return _state.filter((i) => i.id !== action.payload.id)
    }
    return _state
  }



  const options = function(state: typeof initialState.options|undefined, 
    action: 
      ReturnType<typeof setGroup>|
      ReturnType<typeof setSort>|
      ReturnType<typeof setMeanFrom>|
      ReturnType<typeof setMeanTo>|
      ReturnType<typeof toggleShowMark> 
      ) {
    let _state = state !== undefined ? state : initialState.options
    // console.log(state, action)
    if (setGroup.match(action)){
      return {..._state, selectedGroup: action.payload} as IOptions
    }
    if (setSort.match(action)) {
      return {..._state, sort: action.payload} as IOptions
    }
    if (setMeanFrom.match(action) && action.type === setMeanFrom.type) {
      return {..._state, meanFrom: action.payload} as IOptions
    }
    if (setMeanTo.match(action) && action.type === setMeanTo.type) {
      return {..._state, meanTo: action.payload} as IOptions
    }
    if (toggleShowMark.match(action)) {
      return {..._state, showMark: _state.showMark % 3 + 1} as IOptions
    }
    
    return _state
  }


const error = function(state: typeof initialState.error|undefined, 
    action: ReturnType<typeof setError>) {
    let _state = state !== undefined ? state : initialState.error
    switch (action.type) {
      case setError.type:
        return action.payload
    }
    return _state
}

const teacherMarks = function(state: typeof initialState.teacherMarks|undefined,
  action: ReturnType<typeof initTeacherMarks> | ReturnType<typeof setTeacherMark>) {
      let _state = state !== undefined ? state : initialState.teacherMarks
      if (initTeacherMarks.match(action)){
        return [..._state, ...action.payload]
      }
      if (setTeacherMark.match(action)){
        if (action.payload.mark){
          return [..._state.filter((i) => !(i.test_id === action.payload.test_id && i.user_id === action.payload.user_id)), action.payload]
        } else {
          return [..._state.filter((i) => !(i.test_id === action.payload.test_id && i.user_id === action.payload.user_id))]
        }
      }
      return _state
}


const hoverTest = function(state: typeof initalHoverState.hoverTest|undefined, 
  action: ReturnType<typeof setHoverTest>) {
  let _state = state !== undefined ? state : initalHoverState.hoverTest
  if (setHoverTest.match(action)){
    return action.payload
  }
  return _state
}
const hoverUser = function(state: typeof initalHoverState.hoverUser|undefined, 
  action: ReturnType<typeof setHoverUser>) {
  let _state = state !== undefined ? state : initalHoverState.hoverUser
  if (setHoverUser.match(action)){
    return action.payload
  }
  return _state
}

export const initialState = {
    options: {sort: "ASC", showMark: 3} as IOptions,
    portals: [] as IPortals,
    groups: [] as IGroups,
    users: [] as IUsers,
    tests: [] as ITests,
    results: [] as IUserResults,
    teacherMarks: [] as ITeacherMark[],
    error: "Идет загрузка…",
}

export const initalHoverState = {
  hoverTest: null as number|null,
  hoverUser: null as number|null
}
const teacherJournal = combineReducers({
  options,
  portals,
  groups,
  users,
  tests,
  results,
  teacherMarks,
  error
})

export const teacherJournalHover = combineReducers({
  hoverTest,
  hoverUser
})

export type TeacherJournalState = ReturnType<typeof teacherJournal>
export type TeacherJournalHoverState = ReturnType<typeof teacherJournalHover>
export default teacherJournal
