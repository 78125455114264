
import { FETCH_AUTH_STATUS_SUCCESS } from "modules/Auth"
import { Action } from "redux-actions"
import { call, fork, put, select, takeEvery, takeLatest } from "redux-saga/effects"
import { doFetchCatalog, doFetchTypes, doFetchUserStats, saveOrders } from "./api"
import { CatalogState, fetchCatalog, fetchTypes, fetchUserStats, initCatalog, initialState, initTypes, initUserStats, insertAfter, insertBefore, setCatalog, setCatalogType, setError, setStat, setStatEnabled } from "./duck"
import { getCatalog, getStats, getType } from "./getters"
import { selectCatalog } from "./selectors"
import { ICatalog, ICatType, IStats, ITheme } from "./types"


function* fetchTypesWatcher() { yield takeLatest(fetchTypes, fetchTypesFlow) }
function* fetchCatalogWatcher() { yield takeEvery(fetchCatalog, fetchCatalogFlow) }
function* fetchUserStatsWatcher() { yield takeEvery(fetchUserStats, fetchUserStatsFlow) }
function* fetchSetCatalogWatcher() { yield takeLatest(setCatalog, fetchCatalogCheck) }
function* fetchSetCatalogTypeWatcher() { yield takeLatest(setCatalogType, fetchCatalogCheck) }
function* fetchSetStatWatcher() { yield takeLatest(setStat, fetchCatalogCheck) }
function* userLoginWatcher() {yield takeLatest(FETCH_AUTH_STATUS_SUCCESS, fetchTypesFlowC)}
function* insertAfterWatcher() {yield takeLatest(insertAfter, saveOrderFlow)}
function* insertBeforeWatcher() {yield takeLatest(insertBefore, saveOrderFlow)}


function* saveOrderFlow(action: Action<{type:string, catalog: string, id: number, ipath: number[]}>) {
  const state: CatalogState = yield select(selectCatalog)
  const orders = {} as {[key: number]: number}
  const type = getType(state)
  const catalog = getCatalog(state)
  const themes = catalog?.themes
  const get_plain = function(ths: ITheme[]) {
      const out = [] as ITheme[]
      for (let i = 0; i<ths.length; i++){
        out.push(ths[i])
        if (ths[i].children && ths[i].children !== undefined) {
          out.push(...get_plain(ths[i].children!))
        }
      }
      return out
  }
  const plain = get_plain(themes!).filter((i) => i.id !== undefined)
  for (let i = 0; i<plain.length; i++){
    orders[plain[i].id!] = i + 1
  }
  saveOrders(type.name, catalog?.name!, orders)
}

function* fetchTypesFlowC(action: Action<undefined>) {
  const data: CatalogState = yield select(selectCatalog)
  if (data.error !== initialState.error){
    yield fetchTypesFlow(action)
  }
}
function* fetchCatalogCheck(action: Action<string>) {
  const state: CatalogState = yield select(selectCatalog)
  const catalog = getCatalog(state)
  if (catalog?.themes.length === 0){
    yield put(fetchCatalog({name: catalog.name, type: state.activeType}))
  }
  if (!state.activeStat || !catalog){
    return
  }
  const stat = getStats(state)
  if (!stat){
    yield put(fetchUserStats({name: catalog.name, type: state.activeType}))
  }
}

function* fetchTypesFlow(action: Action<undefined>) {
  try {
    const data: {types: ICatType[], loggedIn: number} = yield call(doFetchTypes)
    yield put(initTypes(data.types))
    yield put(setStatEnabled(data.loggedIn))
    yield put(setError(''))
    yield put(setCatalogType(data.types[0].name))
    yield put(setCatalog(data.types[0].catalogs[0].name))
    return
  } catch {
    yield put(setError('Ошибка при загрузке'))
    return 
  }
}

function* fetchCatalogFlow(action: Action<{type: string, name: string}>) {
  try {
    yield put(setError('Загрузка…'))
    const data: ICatalog = yield call(doFetchCatalog, action.payload.type, action.payload.name)
    yield put(initCatalog({type: action.payload.type, name: action.payload.name, catalog: data}))
    yield put(setError(''))
    return
  } catch {
    yield put(setError('Ошибка при загрузке'))
    return 
  }
}

function* fetchUserStatsFlow(action: Action<{type: string, name: string}>) {
  try {
    const data: IStats = yield call(doFetchUserStats, action.payload.type, action.payload.name)
    yield put(initUserStats({type: action.payload.type, name: action.payload.name, stats: data}))
    return
  } catch {
    yield put(setError('Ошибка при загрузке'))
    return 
  }
}

export default function* sagas() {
  yield fork(fetchTypesWatcher)
  yield fork(fetchCatalogWatcher)
  yield fork(fetchUserStatsWatcher)
  yield fork(fetchSetCatalogWatcher)
  yield fork(fetchSetCatalogTypeWatcher)
  yield fork(fetchSetStatWatcher)
  yield fork(userLoginWatcher)
  yield fork(insertAfterWatcher)
  yield fork(insertBeforeWatcher)
}
  