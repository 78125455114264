const STORE_KEY = 'PROB_CATALOG'

export const FETCH_TYPES = `${STORE_KEY}/FETCH_TYPES`
export const FETCH_CATALOG = `${STORE_KEY}/FETCH_CATALOG`
export const FETCH_USER_STATS = `${STORE_KEY}/FETCH_USER_STATS`

export const INIT_TYPES = `${STORE_KEY}/INIT_TYPES`
export const INIT_CATALOG = `${STORE_KEY}/INIT_CATALOG`
export const INIT_USER_STATS = `${STORE_KEY}/INIT_USER_STATS`

export const SET_CATALOG_TYPE = `${STORE_KEY}/SET_CATALOG_TYPE`
export const SET_CATALOG = `${STORE_KEY}/SET_CATALOG`
export const SET_STAT = `${STORE_KEY}/SET_STAT`
export const SET_ERROR = `${STORE_KEY}/SET_ERROR`

export const SET_STAT_ENABLED = `${STORE_KEY}/SET_STAT_ENABLED`

export const INSERT_AFTER = `${STORE_KEY}/INSERT_AFTER`
export const INSERT_BEFORE = `${STORE_KEY}/INSERT_BEFORE`

export const SET_SHOW_EMPTY = `${STORE_KEY}/SET_SHOW_EMPTY`