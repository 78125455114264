import { RefObject } from 'react'

import nahStep from 'components/Form/nahStep'

export const shake = (elemRef: RefObject<HTMLElement | null>): void => {
  if (elemRef && elemRef.current) {
    elemRef.current.style.position = 'relative'
    elemRef.current.style.left = '0'
    let x = 0,
      m = 0,
      nahStepTimeOut

    nahStep(elemRef.current, m, x, nahStepTimeOut)
  }
}
