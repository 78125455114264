import axios from 'axios'

import { IMessage } from 'pages/App/components/Chat/ChatPopup/Dialog/Message'
import { getApiKey } from 'utils'

export const requestUnreadMessages = async (): Promise<Array<
  IMessage
> | null> => {

  try {
    const response = await axios({
      method: 'get',
      url: `/get_messages${getApiKey()}`,
      params: { ajax: 1, a: 'get_list' },
      withCredentials: true,
    })

    const responseData = response.data.map((message: any) => ({
      id: message.id,
      text: message.body,
      from: message.from_str,
      fromId: message.from,
      date: message.creation_time,
    }))

    return responseData
  } catch (error) {
    console.error(error)
  }

  return null
}
