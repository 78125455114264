import axios from 'axios'
import { ITeacherTestGroup } from 'modules/TeacherTest'
import { IGenericResponse } from 'modules/TeacherTest/api'
import { IGetPupilsResult, IMoveGroupResult, IPupil } from '..'


import { getApiKey, isTestMode } from '../../../utils'


const API_KEY = getApiKey()
const ENDPONT = 'teacher/pupils'



export const getPupils = async () => {
    try {
      const response = await axios.get(
        `/newapi/${ENDPONT}${API_KEY}`,
        {
          params: {},
          withCredentials: true,
        }
      )
  
      if (response) {
        const result: IGetPupilsResult = response.data
        if (result) {
          return Promise.resolve(result)
        } else {
          console.error(`Error with /${ENDPONT}/`)
          return Promise.reject()
        }
      }
    } catch (error) {
      if (!isTestMode) {
        console.error(error)
      }
    }
  }


  export const doPutGroup = async (group: ITeacherTestGroup) => {
    try {const resp = await axios.put(
      `/newapi/${ENDPONT}${API_KEY}`,
      {group: group},
      {
        params: {},
        withCredentials: true,
      }
    )
    if (resp) {
      const res: ITeacherTestGroup = resp.data
      return Promise.resolve(res)
    } else {
      console.error(`Error with /${ENDPONT}/`)
      return Promise.reject()
    }
  } catch (e) {
    window.alert('Ошибка')
  }
  }
 
  export const doPatchGroup = async (group: ITeacherTestGroup) => {
    try {  const resp = await axios.patch(
      `/newapi/${ENDPONT}${API_KEY}`,
      {group: group},
      {
        params: {},
        withCredentials: true,
      }
    )
    if (resp) {
      const res: IGenericResponse = resp.data
      if (res.status !== 0) {
        window.alert('Ошибка при сохранении')
      }
      return Promise.resolve(res)
    } else {
      window.alert('Ошибка при сохранении')
      console.error(`Error with /${ENDPONT}/`)
      return Promise.reject()
    }
  } catch (e) {
    window.alert('Ошибка')
  }
  }
  
  export const doDeleteGroup = async (group_id: number) => {
    try {  const resp = await axios.delete(
      `/newapi/${ENDPONT}${API_KEY}` + (API_KEY.indexOf('?') === -1?'?group_id=':'&group_id=') + group_id,
      {
        params: {},
        withCredentials: true,
      }
    )
    if (resp) {
      const res: IGenericResponse = resp.data
      if (res.status !== 0) {
        window.alert('Ошибка при сохранении')
      }
      return Promise.resolve(res)
    } else {
      window.alert('Ошибка при сохранении')
      console.error(`Error with /${ENDPONT}/`)
      return Promise.reject()
    }
  } catch (e) {
      window.alert('Ошибка')
  }
  }

  export const doMoveGroup = async (payload: {group_id:number, portal:string, site:string, to:string}) => {
    const formdata = new FormData();
    formdata.append('group_id', payload.group_id.toString())
    formdata.append('site', payload.portal)
    formdata.append('subj', payload.site)
    formdata.append('to', payload.to)
    try {  const resp = await axios.post(
      `/options` + (API_KEY.indexOf('?') === -1?'?a=copy_group&ajax=1':'&a=copy_group&ajax=1'),
      formdata,
      {
        params: {},
        withCredentials: true,
      }
    )
    if (resp) {
      const res = resp.data as IMoveGroupResult
      return Promise.resolve(res)
    } else {
      window.alert('Ошибка')
      console.error(`Error with /${ENDPONT}/`)
      return Promise.reject()
    }
  } catch (e) {
      window.alert('Ошибка')
  }
  }

export const doRemovePupil = async (pupil_id: number) => {
  try {
    const resp = await axios.get(
      `/teacher${API_KEY}` + (API_KEY.indexOf('?') === -1?'?':'&') + 'ajax=to_archive&uid=' + pupil_id,
      {
        params: {},
        withCredentials: true,
      }
    )
    if (resp && resp.data === 'ok') {
      return Promise.resolve()
    } else {
      window.alert('Ошибка')
      return Promise.reject()
    } 
  } catch (e){
    window.alert('Ошибка')
  }
}

export const doTryPupilAdd = async(email: string) => {
  try {
    const resp = await axios.put(
      `/newapi/${ENDPONT}${API_KEY}`,
      {email: email},
      {
        params: {},
        withCredentials: true,
      }
    )
    if (resp) {
      const res: IGenericResponse = resp.data
      if (res.status === 0){
        return Promise.resolve(res as IPupil)
      } else {
        window.alert(res.error)
      }
      return Promise.resolve()
    } else {
      window.alert('Ошибка')
      return Promise.reject()
    } 
  } catch (e){
    window.alert('Ошибка')
  }
}

export const doPupilRename = async(payload: {id: number, name: string}) => {
  try {
    const formdata = new FormData();
    formdata.append('id', payload.id.toString())
    formdata.append('alias', payload.name)
        const resp = await axios.post(
      `/user_alias?ajax=1`,
      formdata,
      {
        params: {},
        withCredentials: true,
      }
    )
    if (resp && resp.data === 'ok') {
      return Promise.resolve()
    } else {
      window.alert('Ошибка')
      return Promise.reject()
    } 
  } catch (e){
    window.alert('Ошибка')
  }
}

export const doPupilMerge = async(pupil_id:  number) => {
  try {
    const formdata = new FormData();
    formdata.append('id', pupil_id.toString())
    
        const resp = await axios.post(
      `/teacher?ajax=1&a=query_merge`,
      formdata,
      {
        params: {},
        withCredentials: true,
      }
    )
    if (resp) {
      window.alert(resp.data)
      return Promise.resolve()
    } else {
      window.alert('Ошибка')
      return Promise.reject()
    } 
  } catch (e){
    window.alert('Ошибка')
  }
}

