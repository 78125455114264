import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { setUserEmail } from 'modules/User'
import { wasRecoveryEmailPopupSent } from 'modules/RecoveryEmailPopup'

export const useEmailUpdate = (email: string) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setUserEmail(email))

    /*
     * Нужно для сценария:
     * пользователь опечатался в эл. почте,
     * исправляет его и заново запрашивает
     * письмо для смены пароля
     */
    dispatch(wasRecoveryEmailPopupSent(false))
  }, [dispatch, email])
}
