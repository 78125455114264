
import { combineReducers } from "redux";

import { createAction } from '@reduxjs/toolkit'
import * as constants from './constants'
import { IAnswers, IOptions, IProblem } from "./types";



export const fetchTest = createAction<number>(constants.FETCH_TEST)
export const setProbs = createAction<IProblem[]>(constants.SET_PROBS)
export const setOptions = createAction<IOptions>(constants.SET_OPTIONS)
export const setTimer = createAction<number>(constants.SET_TIMER)
export const setError = createAction<string>(constants.SET_ERROR)



const probs = function(state: typeof initialState.probs|undefined,
  action: ReturnType<typeof setProbs>) {
      let _state = state !== undefined ? state : initialState.probs
      if (setProbs.match(action)){
        return [..._state, ...action.payload]
      }
      return _state
}

const answers = function(
    state: typeof initialState.answers|undefined,
    action: undefined) {
        let _state = state !== undefined ? state : initialState.answers
        return _state
}

const options = function(state: typeof initialState.options|undefined, action: ReturnType<typeof setOptions>){
    let _state = state !== undefined ? state : initialState.options
    if (setOptions.match(action)){
        return {..._state, ...action.payload}
    }
    return _state
}

const timer = function(state: typeof initialState.timer|undefined, action: ReturnType<typeof setTimer>){
    let _state = state !== undefined ? state : initialState.timer
    if (setTimer.match(action)){
        return action.payload
    }
    return _state
}

const error = function(state: typeof initialState.error|undefined, action: ReturnType<typeof setError>){
    let _state = state !== undefined ? state : initialState.error
    if (setError.match(action)){
        return action.payload
    }
    return _state
}

export const initialState = {
    probs: [] as IProblem[],
    answers: {} as IAnswers,
    options: {} as IOptions,
    timer: 0,
    error: "Идет загрузка"
}



const test = combineReducers({
  probs,
  answers,
  options,
  timer,
  error
})

export type TestState = ReturnType<typeof test>
export default test
