import React, { FC } from 'react'
import { cn } from '@bem-react/classname'
import { compose } from '@bem-react/core'
import { useSelector } from 'react-redux'

import { CHANGE_PASSWORD_POPUP_TITLE } from 'pages/App/components/ChangePasswordPopup'
import { useMobileVersion } from 'hooks'
import Portal from 'components/Portal'
import DrawerBase, { withViewDefault } from 'components/Drawer'
import Modal from 'components/Modal'
import TitleBase, { withLevel2 } from 'components/Title'

import ChangePasswordPopupForm from './Form'
import { useHandleClose, usePathMatch } from './ChangePasswordPopup.hooks'
import './ChangePasswordPopup.scss'
import { selectorIsUAExam } from 'modules/Exam/selectors'

const Drawer = compose(withViewDefault)(DrawerBase)
const Title = compose(withLevel2)(TitleBase)

export const cnChangePasswordPopup = cn('ChangePasswordPopup')

const ChangePasswordPopup: FC = () => {
  const isMobile = useMobileVersion()
  const visible = usePathMatch()
  const onClose = useHandleClose()
  const isUAExam = useSelector(selectorIsUAExam)

  const content = <ChangePasswordPopupForm visible={true} />

  if (!visible) {
    return null
  }

  return isMobile ? (
    <Portal>
      <Drawer
        className={cnChangePasswordPopup({ platform: 'touch' })}
        view="default"
        visible={isMobile && visible}
        onClose={onClose}
      >
        <div className={cnChangePasswordPopup('DrawerContent')}>
          <Title level="2">{isUAExam ? CHANGE_PASSWORD_POPUP_TITLE.ua : CHANGE_PASSWORD_POPUP_TITLE.rus}</Title>
          {content}
        </div>
      </Drawer>
    </Portal>
  ) : (
    <Modal
      className={cnChangePasswordPopup({ platform: 'desktop' })}
      title={isUAExam ? CHANGE_PASSWORD_POPUP_TITLE.ua : CHANGE_PASSWORD_POPUP_TITLE.rus}
      onClose={onClose}
    >
      {content}
    </Modal>
  )
}

export default ChangePasswordPopup
