import axios from 'axios'

import { getApiKey } from 'utils'

export interface ISendRecoveryEmailParams {
  email: string
}

export const SEND_RECOVERY_EMAIL_RESULT_ERROR = 'no user found'
type SendRecoveryEmailResultErrorType = typeof SEND_RECOVERY_EMAIL_RESULT_ERROR
export interface ISendRecoveryEmailResult {
  success: boolean
  error?: SendRecoveryEmailResultErrorType
}

const API_KEY = getApiKey()
const ENDPONT = 'password/email-for-change'

export const sendRecoveryEmail = async ({
  email,
}: ISendRecoveryEmailParams): Promise<SendRecoveryEmailResultErrorType | void> => {
  try {
    const response = await axios.post(
      `/newapi/${ENDPONT}${API_KEY}`,
      {
        email,
      },
      { withCredentials: true }
    )

    if (response) {
      const result: ISendRecoveryEmailResult = response.data

      if (result.success) {
        return Promise.resolve()
      }

      if (result.error) {
        return result.error
      } else {
        console.error(`Error with /${ENDPONT}/`)
        return Promise.reject()
      }
    }
  } catch (error) {
    console.error(error)
  }

  return Promise.reject()
}
