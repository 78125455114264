import { combineReducers } from 'redux'
import {
  ActionFunctionAny,
  Action,
  ReduxCompatibleReducer,
  createAction,
  handleActions,
} from 'redux-actions'

import * as constants from './constants'

export interface IAppsStatusAction {
  type: typeof constants.IS_RECOVERY_EMAIL_POPUP_VISIBLE
  payload: boolean
}

// Actions
export const isRecoveryEmailPopupVisible: ActionFunctionAny<Action<
  boolean
>> = createAction(constants.IS_RECOVERY_EMAIL_POPUP_VISIBLE)

export const wasRecoveryEmailPopupSent: ActionFunctionAny<Action<
  boolean
>> = createAction(constants.WAS_RECOVERY_EMAIL_POPUP_SENT)

// Reducers
const visibleInitialState = false
const visible: ReduxCompatibleReducer<boolean, boolean> = handleActions(
  {
    [constants.IS_RECOVERY_EMAIL_POPUP_VISIBLE]: (_state: boolean, action) =>
      action.payload,
  },
  visibleInitialState
)

const sentInitialState = false
const sent: ReduxCompatibleReducer<boolean, boolean> = handleActions(
  {
    [constants.WAS_RECOVERY_EMAIL_POPUP_SENT]: (_state: boolean, action) =>
      action.payload,
  },
  sentInitialState
)

export const initialState: RecoveryEmailPopupState = {
  visible: visibleInitialState,
  sent: sentInitialState,
}

const recoveryEmailPopup = combineReducers({
  visible,
  sent,
})

export type RecoveryEmailPopupState = ReturnType<typeof recoveryEmailPopup>
export default recoveryEmailPopup
