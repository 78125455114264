import React, { FC, ChangeEventHandler } from 'react'
import { cn } from '@bem-react/classname'

import '../Input/Input.scss'
import './Checkbox.scss'

interface ICheckboxProps {
  className?: string
  fakeCheckboxClassName?: string
  name: string
  value: boolean
  id?: string
  onChange: ChangeEventHandler
  title?: string
}

const Checkbox: FC<ICheckboxProps> = ({
  className,
  fakeCheckboxClassName,
  name,
  value,
  onChange,
  ...props
}) => {
  const cnCheckbox = cn('Checkbox')

  return (
    <>
      <input
        className={cnCheckbox(null, [className])}
        type="checkbox"
        name={name}
        value={value?.toString() ?? false}
        checked={value}
        onChange={onChange}
        {...props}
      />
      <span className={`Input FakeCheckbox ${fakeCheckboxClassName}`} title={props.title}></span>
    </>
  )
}

export default Checkbox
