import React, { FC } from 'react'

import { cnContentAd } from 'pages/App/components/Ads/ContentAd'
// import { useGoogleAdScript } from 'pages/App/components/Ads/hooks'

import { cnContentTopAd } from '..'
import './ContentTopAdGoogle.scss'

/**
 * Рекламные цсс-классы генерируются без @bem-react/classname,
 * чтобы получалось их захешировать
 */
const ContentTopAdGoogle: FC = () => (
  <div
    className={cnContentAd('Item', { development: true }, [
      cnContentTopAd('Google'),
    ])}
  />
)

export default ContentTopAdGoogle
