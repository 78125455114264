
import { ITeacherTestGroup, ITeacherTestTheme } from "modules/TeacherTest";
import { combineReducers } from "redux";


import { IPupil } from ".";

import { createAction } from '@reduxjs/toolkit'
import * as constants from './constants'

export const fetchPupils = createAction<undefined>(constants.FETCH_PUPILS)
export const initPupils = createAction<typeof initialState.pupils>(constants.INIT_PUPILS)
export const initGroups = createAction<typeof initialState.groups>(constants.INIT_GROUPS)
export const initThemes = createAction<typeof initialState.themes>(constants.INIT_THEMES)
export const initAssigns = createAction<typeof initialState.groupAssign>(constants.INIT_ASSIGNS)
export const initSites = createAction<typeof initialState.sites>(constants.INIT_SITES)
export const setError = createAction<string>(constants.SET_ERROR)
export const setOrder = createAction<string>(constants.SET_ORDER)
export const setGroup = createAction<ITeacherTestGroup>(constants.SET_GROUP)
export const newGroup = createAction<string>(constants.NEW_GROUP)
export const removeGroup = createAction<number>(constants.REMOVE_GROUP)
export const removePupil = createAction<number>(constants.REMOVE_PUPIL)
export const removePupilDone = createAction<number>(constants.REMOVE_PUPIL_DONE)
export const setAssign = createAction<{theme_id:number, group_id:number, selected:boolean}>(constants.SET_ASSIGN)
export const moveGroup = createAction<{portal:string, site:string, group_id: number, to:string, callback: Function}>(constants.MOVE_GROUP)
export const tryPupilAdd = createAction<string>(constants.TRY_PUPIL_ADD)
export const addPupil = createAction<IPupil>(constants.ADD_PUPIL)
export const pupilRename = createAction<{name:string, id:number}>(constants.PUPIL_RENAME)
export const pupilMerge = createAction<number>(constants.PUPIL_MERGE)

const pupils = function(state: typeof initialState.pupils|undefined,
    action: ReturnType<typeof initPupils>|ReturnType<typeof removePupilDone>|ReturnType<typeof addPupil>|ReturnType<typeof pupilRename>) {
    let _state = state !== undefined ? state : initialState.pupils
    switch (action.type) {
        case initPupils.type:
          return action.payload as IPupil[]
        case removePupil.type: 
          return _state.filter((i)=>i.id !== action.payload)
        case addPupil.type:
          return [..._state, action.payload] as IPupil[]
        case pupilRename.type:
          let nstate = [..._state]
          for (let i = 0; i< nstate.length; i++){
              const payload = action.payload as {name:string, id:number}
              if (nstate[i].id === payload.id) {
                nstate[i].name = payload.name
              }
          }
          return nstate
    }
    return _state
  }

const themes = function(state: typeof initialState.themes|undefined,
  action: ReturnType<typeof initThemes>) {
  let _state = state !== undefined ? state : initialState.themes
  switch (action.type) {
    case initThemes.type:
      return action.payload
  }
  return _state
}

const groups = function(state: typeof initialState.groups|undefined, 
  action: 
    ReturnType<typeof initGroups>|
    ReturnType<typeof setGroup>|
    ReturnType<typeof removeGroup>) {
  let _state = state !== undefined ? state : initialState.groups
  switch (action.type) {
    case initGroups.type:
      return action.payload as Array<ITeacherTestGroup>
    case setGroup.type:
      let nstate = [..._state]
      let ngroup = action.payload as ITeacherTestGroup
      for (let i = 0; i < nstate.length; i++){
        if (nstate[i].id === ngroup.id){
          nstate[i] = {...ngroup}
          return nstate
        }
      }
      nstate.push(ngroup)
      return nstate
    case removeGroup.type:
      return _state.filter((i)=>i.id !== action.payload)
        
  }
  return _state
}

const sites = function(state: typeof initialState.sites|undefined,
    action: ReturnType<typeof initSites>) {
      let _state = state !== undefined ? state : initialState.sites
      switch (action.type) {
        case initSites.type:
            return action.payload
      }
      return _state
    }

const order = function(state: typeof initialState.order|undefined, 
  action: ReturnType<typeof setOrder>) {
  let _state = state !== undefined ? state : initialState.order
  switch (action.type) {
    case setOrder.type:
      return action.payload
  }
  return _state
}
const groupAssign = function(state: typeof initialState.groupAssign|undefined, 
  action: 
    ReturnType<typeof initAssigns>|
    ReturnType<typeof setAssign>) {
  let _state = state !== undefined ? state : initialState.groupAssign
  if (setAssign.match(action)){
    let payload = action.payload
    let nstate = {..._state}
    if (payload.selected){
      if (nstate[payload.theme_id] === undefined){
        nstate[payload.theme_id] = []
      }
      nstate[payload.theme_id] = [...nstate[payload.theme_id], payload.group_id]
    } else {
      nstate[payload.theme_id] = nstate[payload.theme_id].filter((i) => i !== payload.group_id)
    }
    return nstate
  }
  if (initAssigns.match(action)) {
    return action.payload
  }
  return _state
}
const error = function(state: typeof initialState.error|undefined, 
  action: ReturnType<typeof setError>) {
  let _state = state !== undefined ? state : initialState.error
  switch (action.type) {
    case setError.type:
      return action.payload
  }
  return _state
}


export const initialState = {
    pupils: [] as Array<IPupil>,
    themes: [] as Array<ITeacherTestTheme>,
    groups: [] as Array<ITeacherTestGroup>,
    sites: {} as {[key: string]: Array<Array<string>>},
    order: 'name',
    groupAssign: {} as {[key: number]: Array<number>},
    error: "Идет загрузка"
}


const teacherPupils = combineReducers({
    pupils,
    themes,
    groups,
    sites,
    order,
    groupAssign,
    error
})

export type TeacherPupilsState = ReturnType<typeof teacherPupils>

export default teacherPupils