import { useEffect } from 'react'

/**
 * Яндекс-директ
 */
export const useYandexAdScript = (ID: string) => {
  useEffect(() => {
    const yaScript = document.createElement('script')

    yaScript.innerHTML = `
    window.yaContextCb.push(()=>{
  Ya.Context.AdvManager.render({
    renderTo: 'yandex_rtb_${ID}',
    blockId: '${ID}'
  })
})`

    document.body.appendChild(yaScript)
  }, [ID])
}
