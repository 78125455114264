import { MonthListRU, MonthListUA } from 'constants/month'

export interface ILang {
  lang: 'ru' | 'ua'
}

export const getCurrentMonthOfVariants = ({lang}: ILang): string => {
  const date = new Date()
  const monthI = date.getMonth()
  let monthList = Object.values(MonthListRU);
  if (lang === 'ru') {
    monthList = Object.values(MonthListRU)
  } else if (lang === 'ua') {
    monthList = Object.values(MonthListUA)
  }
  return monthList[monthI]

}
