import {
  ActionFunctionAny,
  Action,
  ReduxCompatibleReducer,
  createAction,
  handleActions,
} from 'redux-actions'

import { ISubject } from 'utils/data'
import * as constants from './constants'

export interface ISubjectStatusAction {
  type: typeof constants.FETCH_SUBJECT
  payload: ISubject | null
}

// Actions
export const fetchSubject: ActionFunctionAny<Action<ISubject | null>> = createAction(
  constants.FETCH_SUBJECT
)

// Reducers
const subject: ReduxCompatibleReducer<
  ISubject | null,
  ISubject | null
> = handleActions(
  {
    [constants.FETCH_SUBJECT]: (_state: ISubject | null, action) =>
      action.payload,
  },
  null
)

export const initialState = null
export type subjectState = ReturnType<typeof subject>
export default subject
