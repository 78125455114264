import axios from 'axios'

import { getApiKey } from 'utils'

export const getFriendList = async () => {

  return await axios(`/get_messages${getApiKey()}`, {
    params: {
      ajax: 1,
      a: 'users_list',
    },
    withCredentials: true,
  })
}
