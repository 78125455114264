import React, { FC, ReactNode, Ref, useState, useMemo, useEffect } from 'react'

import { cnDrawer, DefaultAnimation } from './Drawer.const'
import { useClientHeight, useLockBodyScroll, useSpring } from './Drawer.utils'
import DrawerContent from './Content'
import './Drawer.scss'

export interface IDrawerAnimationParams {
  /**
   * Основные параметры анимации для rebound
   * @see https://github.com/facebook/rebound-js
   */
  tension: number
  friction: number

  /**
   * Отключает спринговые анимации (напр. для прогонов автотестов)
   */
  disabled?: boolean

  /**
   * Отключает анимации на момент перетаскивания
   * шторки пальцем (таким образом делая их отзывчивее)
   */
  dragImmediate?: boolean
}

export interface IDrawerProps {
  className?: string
  visible?: boolean

  /**
   * Меняет внешний вид для режима «шторка внутри шторки»
   */
  nested?: boolean

  /**
   * Направление, откуда появляется шторка
   */
  direction?: 'bottom' | 'left'

  /**
   * Ссылка на корневой DOM-элемент компонента
   */
  innerRef?: Ref<HTMLDivElement>

  /**
   * Параметры анимации шторки
   */
  animation?: IDrawerAnimationParams

  /**
   * Максимальный размер шторки (ширина или высота в зависимости от direction).
   * Принимает любое валидное CSS значение.
   */
  maxSize?: string

  /**
   * Делает шторку "статичной"
   */
  dragDisabled?: boolean

  /**
   * Функция, которая будет вызвана при попытке закрытия шторки
   */
  onClose?: () => void

  children: ReactNode
}

/**
 * Используется для создания всплывающих модальных окон
 * @param {IDrawerProps} props
 */
const Drawer: FC<IDrawerProps> = (props) => {
  const {
    className,
    visible,
    nested = false,
    direction = 'bottom',
    innerRef,
    animation = DefaultAnimation,
  } = props

  // прогресс открытия шторки от 0 до 1
  const [progress, setProgress] = useState<number>(0)

  // признак того, что анимация временно отключена (напр. на время drag жеста)
  const [springDisabled, setSpringDisabled] = useState<boolean>(false)

  // спринговое значение прогресса и его производные
  const springImmediate =
    (animation.dragImmediate && springDisabled) || animation.disabled
  const springValue = useSpring(
    progress,
    animation.tension,
    animation.friction,
    springImmediate
  )
  const springVisible = springValue > 0

  // решает баг в iOS: в альбомной ориентации fixed элементы с
  // height: 100% показываются некорректно если виден navigation bar
  const clientHeight = useClientHeight()
  const popupStyle = useMemo(
    () => ({ height: clientHeight && clientHeight + 'px' }),
    [clientHeight]
  )

  useLockBodyScroll(springVisible)

  useEffect(() => {
    setSpringDisabled(false)
    setProgress(visible ? 1 : 0)
  }, [visible, animation])

  return (
    <div
      className={cnDrawer({ visible: springVisible, direction, nested }, [
        className,
      ])}
      ref={innerRef}
      style={popupStyle}
    >
      <DrawerContent
        {...props}
        springValue={springValue}
        setProgress={setProgress}
        setSpringDisabled={setSpringDisabled}
      />
    </div>
  )
}

export default Drawer
