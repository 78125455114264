import React, { useState, useCallback } from 'react'
import { useSelector } from 'react-redux'

import { selectExam } from 'modules/Exam'
import { useMobileVersion } from 'hooks'
import Button from 'components/Button'

import { cnHeader } from '..'
import ExamSwitcher from './ExamSwitcher'
import './Header-ExamTitle.scss'
import { getDomain } from '../../../../../utils'

const ExamTitle = () => {
  const [visible, isVisible] = useState(false)
  const exam = useSelector(selectExam)
  const isMobile = useMobileVersion()
  const domain = useSelector(getDomain)
  let isMNT = domain.includes('nmt') || domain.includes('zno')

  const handleClick = useCallback(() => {
    isVisible(!visible)
  }, [visible])

  return (
    <>
      <Button
        className={cnHeader('ExamTitle', { open: visible, nmt: isMNT })}
        handleClick={handleClick}
        as={isMobile ? 'span' : undefined}
        role={isMobile ? 'button' : undefined}
        aria-haspopup="listbox"
        aria-expanded={visible}
        aria-controls={visible && 'ExamSwitcher'}
      >
        <span className={cnHeader('ExamTitleFirstPart')}>
          РЕШУ<span className={cnHeader('Halfspace')}> </span>
        </span>
        <span className={cnHeader('ExamTitleNameWrapper')}>
          <span className={cnHeader('ExamTitleName', { open: visible })}>
            {exam?.title}
          </span>
          {!isMNT && <ExamSwitcher visible={visible} />}
          
        </span>
      </Button>

      
    </>
  )
}

export default ExamTitle
