import React, { FC, MouseEventHandler } from 'react'
import { UseFormRegisterReturn } from 'react-hook-form'
import { compose } from '@bem-react/core'

import InputBase, { withSizeL as withInputSizeL } from 'components/Input'
import PasswordToggler, { PasswordInputType } from 'components/PasswordToggler'

import { cnChangePasswordPopup } from '../..'
import './ChangePasswordPopup-Control.scss'

const Input = compose(withInputSizeL)(InputBase)

export interface IChangePasswordPopupControl {
  label: string
  title: string
  type: PasswordInputType
  name: string
  size?: 'l'
  autoFocus?: boolean
  register?: UseFormRegisterReturn
  toggleVisibility: MouseEventHandler
}

const ChangePasswordPopupControl: FC<IChangePasswordPopupControl> = ({
  label,
  title,
  type,
  name,
  size,
  autoFocus,
  register,
  toggleVisibility,
}) => {
  const { ref, ...rest } = register!

  return (
    <div className={cnChangePasswordPopup('Control')} key={name}>
      <label className={cnChangePasswordPopup('Label')} htmlFor={name}>
        {label}
      </label>

      <div className={cnChangePasswordPopup('FormRow')}>
        <Input
          className={cnChangePasswordPopup('FormInput')}
          size={size}
          type={type}
          id={name}
          autoComplete={name}
          autoFocus={autoFocus}
          innerRef={ref}
          {...rest}
        />
        <PasswordToggler type={type} title={title} onClick={toggleVisibility} />
      </div>
    </div>
  )
}

export default ChangePasswordPopupControl
