import {parseISO, parse} from "date-fns"

export interface IPortal {
    id: string,
    url: string,
    name: string,
    subjects: {[name: string]: string},
}
export type IPortals = Array<IPortal>

export interface IPupilGroup {
    id: number,
    portal: string,
    subject: string,
    name: string,
    archive: number,
    loaded?: number,
    members?: Array<number>
}
export type IGroups = Array<IPupilGroup>
export interface IUser {
    id: number,
    name: string,
    reverse_name: string
}
export type IUsers = Array<IUser>
export interface IProbResult {
    answer: string
    prob: number
    res: number
    portal?: string
    subject?: string
}
export interface IUserResult {
    id: number,
    user: number,
    test: number,
    portal: string,
    subject: string,
    time: Date,
    probs: IProbResult[],
    checked: number
}
export interface IUserResultRaw extends Omit<IUserResult, 'time'> {
    time: string
}

export const sanitizeResult = (result:IUserResultRaw):IUserResult => {
    const out = {
        ...result,
        time: parseISO(result.time)
    }
    return out as IUserResult

}

export type IUserResults = Array<IUserResult>

export type IGroupSel = {
    portal: string,
    subject: string,
    id: number
}

export interface IOptions {
    selectedGroup?: IGroupSel
    sort: "ASC"|"DESC",
    meanFrom: Date,
    meanTo: Date
    showMark: 1|2|3
}

export interface IProblem {
    id: number
    type: string
    portal?: string
    subject?: string
}

export interface ITest {
    id: number
    portal: string
    subject: string
    public: number
    title?: string
    description?: string
    assigns: number
    count: number
    isTemplate: boolean
    sortdate?: Date
    time: Date
    problems: IProblem[]
    marks?: number[]
    valid_to?: Date,
    valid_from?:Date,
    journal_hide?: number
    allow_late?: number
    lower_mark?: number
    testtime?: number
    isOpen?: boolean
  }

export interface ITestRaw extends Omit<ITest, 'valid_from'|'valid_to'|'time'|'sortdate'> {
    sortdate?: string
    time: string
    valid_to?: string
    valid_from?: string
}

export const sanitizeTest  = (test: ITestRaw):ITest => {
    const out = {
        ...test,
        time: parseISO(test.time),
        sortdate: test.sortdate?parse(test.sortdate, "dd.MM.yyyy", new Date()):undefined,
        valid_to: test.valid_to?parse(test.valid_to + ' +03', "dd.MM.yyyy HH:mm x", new Date()):undefined,
        valid_from: test.valid_from?parse(test.valid_from + ' +03', "dd.MM.yyyy HH:mm x", new Date()):undefined
    }
    return out as ITest
}

export interface ITeacherMark {
    id?: number
    user_id: number
    test_id: number
    mark: string
    portal: string
    subject: string
}

export type ITests = Array<ITest>