import React, { FC, useEffect } from 'react'

import './ContentTopAdBlockDetect.scss'

const ContentTopAdBlockDetect: FC = () => {
  useEffect(() => {
    document.cookie = 'Ochop9=1'
  }, [])

  return (
    <div className={'ContentTopAd-BlockDetect'}>
      <h3 className={'ContentTopAd-BlockDetectTitle'}>
        Ваш блокировщик рекламы ломает сайт
      </h3>
      <p className={'ContentTopAd-BlockDetectText'}>
        Наши пользователи сообщают, что блокировщики нарушают работоспособность
        сайта. Рекомендуем отключить. Примеры ошибок:
        пропадают списки аналогичных заданий и сведения об источниках заданий,
        не показываются курсы в разделе «Школа», скрываются решения в разделе
        «Учителю», разъезжается сайт.
      </p>
    </div>
  )
}

export default ContentTopAdBlockDetect
