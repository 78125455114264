import React, { FC } from 'react'
import { cn } from '@bem-react/classname'

import { isDevMode } from 'utils'
import { AdParts } from 'modules/Ads'
import AdZone from 'pages/App/components/Ads/AdZone'

import ContentAd from '../ContentAd'
import ContentBottomAdGoogle from './ContentBottomAdGoogle'
import ContentBottomAdYandex from './ContentBottomAdYandex'
import './ContentBottomAd.scss'

export const cnContentBottomAd = cn('ContentBottomAd')

const ContentBottomAd: FC = () => {
  const isDev = isDevMode()

  return (
    <ContentAd className={cnContentBottomAd()}>
      {isDev ? (
        <>
          <ContentBottomAdGoogle />
          <ContentBottomAdYandex />
        </>
      ) : (
        <AdZone parentClassName={cnContentBottomAd()} part={AdParts.BOTTOM} />
      )}
    </ContentAd>
  )
}

export default ContentBottomAd
