import { combineReducers } from 'redux'
import {
  ActionFunctionAny,
  Action,
  ReduxCompatibleReducer,
  createAction,
  handleActions,
} from 'redux-actions'

import { INewsItemProps } from 'pages/App/components/Sidebar/SidebarInfo/News/NewsItem'
import * as constants from './constants'

export interface IThievesStatusAction {
  type: typeof constants.FETCH_THIEVES
  payload: INewsItemProps | null
}

// Actions
export const fetchThieves: ActionFunctionAny<Action<Array<
  INewsItemProps
> | null>> = createAction(constants.FETCH_THIEVES)

// Reducers
const list: ReduxCompatibleReducer<
  Array<INewsItemProps> | null,
  Array<INewsItemProps> | null
> = handleActions(
  {
    [constants.FETCH_THIEVES]: (_state: Array<INewsItemProps> | null, action) =>
      action.payload,
  },
  null
)

const fetched: ReduxCompatibleReducer<boolean, boolean> = handleActions(
  {
    [constants.FETCH_THIEVES]: () => true,
  },
  false
)

export const initialState: ThievesState = {
  list: null,
  fetched: false,
}

const thieves = combineReducers({
  list,
  fetched,
})

export type ThievesState = ReturnType<typeof thieves>
export default thieves
