import React, { FC, MouseEventHandler, ReactNode } from 'react'
import { cn } from '@bem-react/classname'

import './Link2.scss'

interface ILink2Props {
  /**
   * Дополнительный класс
   */
  className?: string

  /**
   * Адрес ссылки
   *
   */
  href?: string

  /**
   * Обработчик события клика
   */
  onClick?: MouseEventHandler<HTMLAnchorElement>

  /**
   * Содержимое ссылки
   */
  children: ReactNode
}

export const cnLink2 = cn('Link2')

const Link2: FC<ILink2Props> = ({
  className,
  children,
  // @ts-ignore
  view: _view,
  // @ts-ignore
  wrap: _wrap,
  ...props
}) => (
  <a className={cnLink2(null, [className])} {...props}>
    {children}
  </a>
)

export default Link2
