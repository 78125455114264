import React, { useState, useCallback } from 'react'
import { compose } from '@bem-react/core'

import Portal from 'components/Portal'
import DrawerBase, { withViewDefault } from 'components/Drawer'
import ProfileAuth from 'pages/App/components/ProfileAuth'

import ProfileIcon from '../ProfileIcon'

const Drawer = compose(withViewDefault)(DrawerBase)

/**
 * @todo (ilyasidorchik): Сделать архитектуру по БЭМу лучше
 */
const ProfileDrawer = () => {
  const [visible, setVisible] = useState(false)

  const handleOpen = useCallback(() => {
    setVisible(true)
  }, [])

  const handleClose = useCallback(() => {
    setVisible(false)
  }, [])

  return (
    <>
      <ProfileIcon auth={false} selected={visible} handleClick={handleOpen} />

      <Portal>
        <Drawer view="default" visible={visible} onClose={handleClose}>
          <ProfileAuth />
        </Drawer>
      </Portal>
    </>
  )
}

export default ProfileDrawer
