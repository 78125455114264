import axios from 'axios'

import { getApiKey } from 'utils'

export const getGeneralData = async () => {

  return await axios(`/newapi/general${getApiKey()}`, {
    withCredentials: true,
  })
}
