import React, { FC, Dispatch, SetStateAction, useCallback } from 'react'

import { cnChatPopup } from '../../..'

interface IAddContactToggleProps {
  isOpen: Dispatch<SetStateAction<boolean>>
}

const AddContactToggle: FC<IAddContactToggleProps> = ({ isOpen }) => {
  const openAddContactForm = useCallback(() => {
    isOpen(true)
  }, [isOpen])

  return (
    <div
      className={cnChatPopup('AddContactToggle')}
      onClick={openAddContactForm}
    >
      + Добавить пользователя
    </div>
  )
}

export default AddContactToggle
