import {
  ActionFunctionAny,
  Action,
  ReduxCompatibleReducer,
  createAction,
  handleActions,
} from 'redux-actions'

import { INewsItemProps } from 'pages/App/components/Sidebar/SidebarInfo/News/NewsItem'
import * as constants from './constants'

export interface INewsStatusAction {
  type: typeof constants.FETCH_NEWS
  payload: Array<INewsItemProps> | null
}

// Actions
export const fetchNews: ActionFunctionAny<Action<Array<
  INewsItemProps
> | null>> = createAction(constants.FETCH_NEWS)

// Reducers
const news: ReduxCompatibleReducer<
  Array<INewsItemProps> | null,
  Array<INewsItemProps> | null
> = handleActions(
  {
    [constants.FETCH_NEWS]: (_state: Array<INewsItemProps> | null, action) =>
      action.payload,
  },
  null
)

export const initialState = null
export type NewsState = ReturnType<typeof news>
export default news
