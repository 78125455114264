import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { cn } from '@bem-react/classname'

import { selectAuthUser } from 'modules/Auth'

import PinnedTasksButton from './PinnedTasks/Button'
import SpecialChars from './SpecialChars'
import './DeskList.scss'

export const cnDeskList = cn('DeskList')

const DeskList: FC = () => {
  const user = useSelector(selectAuthUser)

  return (
    <div className={cnDeskList()}>
      {user?.pinnedTaskAmount ? <PinnedTasksButton /> : null}

      {!true ? <SpecialChars /> : null}
    </div>
  )
}

export default DeskList
