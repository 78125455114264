import {
  ActionFunctionAny,
  Action,
  ReduxCompatibleReducer,
  createAction,
  handleActions,
} from 'redux-actions'

import { IPage } from 'utils/data/sectionList'
import * as constants from './constants'

export interface ISidebarMenuAction {
  type: typeof constants.FETCH_SIDEBAR_MENU
  payload: Array<IPage> | null
}

// Actions
export const fetchSidebarMenu: ActionFunctionAny<Action<Array<
  IPage
> | null>> = createAction(constants.FETCH_SIDEBAR_MENU)
export const updateSidebarMenuAfterSignIn: ActionFunctionAny<any> = createAction(
  constants.UPDATE_SIDEBAR_MENU_AFTER_SIGN_IN
)
export const updateSidebarMenuAfterSignOut: ActionFunctionAny<any> = createAction(
  constants.UPDATE_SIDEBAR_MENU_AFTER_SIGN_OUT
)

// Reducers
const sidebarMenu: ReduxCompatibleReducer<
  Array<IPage> | null,
  Array<IPage> | null
> = handleActions(
  {
    [constants.FETCH_SIDEBAR_MENU]: (_state: Array<IPage> | null, action) =>
      action.payload,
    [constants.UPDATE_SIDEBAR_MENU_AFTER_SIGN_IN]: (
      _state: Array<IPage> | null
    ) => {
      const menuItemsAuthorized = [
        {
          url: '/user_stat',
          text: 'Моя статистика',
          hint: null,
        },
        {
          url: '/favorite',
          text: 'Избранное',
          hint: null,
        },
      ]
      const newSidebarMenu = [..._state!, ...menuItemsAuthorized]

      return newSidebarMenu
    },
    [constants.UPDATE_SIDEBAR_MENU_AFTER_SIGN_OUT]: (
      _state: Array<IPage> | null
    ) => {
      const authorizedList = ['/user_stat', '/favorite']
      const newSidebarMenu = [..._state!].filter(
        ({ url }: IPage) => !authorizedList.includes(url)
      )

      return newSidebarMenu
    },
  },
  null
)

export const initialState = null
export type sidebarMenuState = ReturnType<typeof sidebarMenu>
export default sidebarMenu
