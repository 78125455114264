import React, { FC, MouseEventHandler } from 'react'
import { cn } from '@bem-react/classname'

import Button from 'components/Button'

import { PasswordInputType } from './PasswordToggler.hooks'
import { PasswordInputTypes } from './PasswordToggler.constants'
import './PasswordToggler.scss'

interface IPasswordTogglerProps {
  type: PasswordInputType
  title: string
  onClick: MouseEventHandler
}

export const cnPasswordToggler = cn('PasswordToggler')

/**
 *
 * @todo (ilyasidorchik) Сделать чистую кнопку
 * и наследоваться от неё
 */
const PasswordToggler: FC<IPasswordTogglerProps> = ({
  type,
  title,
  onClick,
}) => {
  const action = type === PasswordInputTypes.TEXT ? 'close' : ''

  /**
   * Атрибут type нужен, чтобы по клику форма не отправлялась
   */
  return (
    <Button
      className={cnPasswordToggler()}
      type="button"
      title={title}
      aria-label={title}
      handleClick={onClick}
    >
      <div
        className={cnPasswordToggler('Icon', { action })}
        aria-hidden="true"
      />
    </Button>
  )
}

export default PasswordToggler
