export const getOurVariants = (): Array<number> => [
  33594905,
  33594906,
  33594907,
  33594908,
  33594909,
  33594910,
  33594911,
  33594912,
  33594913,
  33594914,
  33594915,
  33594916,
  33594917,
  33594918,
  33594919,
]
