import React from 'react'

import { getDomain } from 'utils'
import OurVariants from './components/OurVariants'
import LarinVariants from './components/LarinVariants'
import Writing from './components/Writing'
import HistoryWriting from './components/HistoryWriting'
import SocialAnthology from './components/SocialAnthology'
import LiteratureWriting from './components/LiteratureWriting'
import Constructor from './components/Constructor'
import TwoColumns from 'components/TwoColumns/TwoColumns'
import TeacherVariant from './components/TeacherVariant'
import CatalogSearch from './components/CatalogSearch'
import { useSelector } from 'react-redux'

const StudentPage = () => {
  const domain = useSelector(getDomain)
  const isMathPro = domain.indexOf('ege.sdamgia') === 0 || domain.includes('math-ege.sdamgia')
  const isCustomTests =
    domain.includes('rus-ege.sdamgia') ||
    domain.includes('rus-oge.sdamgia') ||
    domain.includes('rus9-gve') ||
    domain.includes('rus11_100-gve') ||
    domain.includes('math2') ||
    domain.includes('math3') ||
    domain.includes('math3b') ||
    domain.includes('rus1.') ||
    domain.includes('by1')
  const isHistory = domain.includes('hist-ege.sdamgia')
  const isSocial = domain.includes('soc-ege.sdamgia')
  const isLiterature = domain.includes('lit-ege.sdamgia')
  const noConstructor =
    domain.includes('rus9-gve') || domain.includes('rus11_100-gve') || domain.includes('reshuolymp')
  const noOur = 
    domain.includes('rus9-gve') ||
    domain.includes('rus11_100-gve') ||
    domain.includes('urok') ||
    domain.includes('reshuolymp') ||
    domain.includes('rus1.') ||
    domain.includes('by1')
  const isExam = domain.includes('exam-urok')
  const isOlymp = domain.includes('reshuolymp')
  return (
    <>
      {!noOur && <OurVariants />}

      {isMathPro && <LarinVariants />}
      {isCustomTests && <Writing />}
      {isHistory && <HistoryWriting />}
      {isSocial && <SocialAnthology />}
      {isLiterature && <LiteratureWriting />}

      <TwoColumns>
        <TeacherVariant />
        <CatalogSearch />
      </TwoColumns>

      {!noConstructor && <Constructor />}
      {(isExam || isOlymp) && <Writing />}
    </>
  )
}

export default StudentPage
