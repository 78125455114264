import React, { FC, Fragment } from 'react'
import { useSelector } from 'react-redux'
import { cn } from '@bem-react/classname'

import { IPage } from 'utils/data/sectionList'

//import { isDevOrStandMode } from 'utils'
import { selectSidebarMenu } from 'modules/SidebarMenu'

import 'components/Link/_black/Link_black.scss'
import LinkWrap from 'components/Link/_wrap'
import Fallback from 'components/Fallback'

import { importantPageList } from './SidebarMenu.const'
import './SidebarMenu.scss'
import { selectorIsUAExam } from '../../../../../modules/Exam'

interface ISidebarMenuProps {
  className?: string
}

const cnSidebarMenu = cn('SidebarMenu')

const SidebarMenu: FC<ISidebarMenuProps> = ({ className }) => {
  const sidebarMenu = useSelector(selectSidebarMenu)
  const isUAExam = useSelector(selectorIsUAExam)

  const activeClassName = cnSidebarMenu('Link', { active: true })

  return (
    <nav className={cnSidebarMenu({ fallback: !sidebarMenu }, [className])}>
      {!sidebarMenu &&
        Array(12)
          .fill('')
          .map((item: string, i: number) => (
            <Fallback
              className={cnSidebarMenu('Link', [cnSidebarMenu('Fallback')])}
              key={`${item}-${i}`}
            />
          ))}

      {sidebarMenu?.map(({ url, text, hint }: IPage, i: number) => {
        const important = importantPageList.includes(url)

        // Можно отрефакторить :-)
        const sdamgiaLink = i === 0 && !isUAExam && (
          <LinkWrap
            className={cnSidebarMenu('Link', { important, sdamgia: true }, [
              'Link_black',
            ])}
            classNameU={cnSidebarMenu('LinkUnderline', { important }, [
              'Link_black-U',
            ])}
            href={`https://sdamgia.ru/`}
            title={hint!}
            key={i}
          >
            Сдам ГИА
          </LinkWrap>
        )

        return (
          <Fragment key={i}>
            {sdamgiaLink}
            <LinkWrap
              className={cnSidebarMenu('Link', { important }, ['Link_black'])}
              classNameU={cnSidebarMenu('LinkUnderline', { important }, [
                'Link_black-U',
              ])}
              href={`${url}`}
              title={hint!}
              activeClassName={activeClassName}
              //{...clientRoutingProps}
            >
              {text}
            </LinkWrap>
          </Fragment>
        )
      })}
    </nav>
  )
}

export default SidebarMenu
