import React, { FC, useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { selectAuthUser, logout } from 'modules/Auth'
import Link from 'components/Link'
import './ProfileLink.scss'

import signOutIcon from './assets/signOutIcon.png'

declare const window: any

const ProfileLink: FC = () => {
  const user = useSelector(selectAuthUser)
  const dispatch = useDispatch()

  const handleSignOut = useCallback(() => {
    dispatch(logout())
  }, [dispatch])
  useEffect(() => {
    if (user) {
      const params: {
        first_name?: string
        last_name?: string
        email?: string
      } = {}
      params.first_name = user.firstName
      params.last_name = user.lastName
      if (user.username) params.email = user.username
window.setTimeout(function(event) {
      window.ym(window.Ya.Metrika2.counters()[0].id, 'firstPartyParams', params)
}, 3000);
    }
  }, [user])
  return (
    <div className="ProfileLink">
      <div className="ProfileLink-Link">
        <Link className="Link_static" href={`/profile`}>
          {user && user.firstName ? user.firstName : 'Профиль'}
        </Link>
      </div>

      <div className="ProfileLink-Button" onClick={handleSignOut}>
        <img className="ProfileLink-Icon" src={signOutIcon} alt="Выйти" />
      </div>
    </div>
  )
}

export default ProfileLink
