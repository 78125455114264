import { AnyAction, combineReducers } from 'redux'
import {
  ActionFunctionAny,
  Action,
  ReduxCompatibleReducer,
  createAction,
  handleActions,
} from 'redux-actions'

import { ITeacherTest, ITeacherTestGroup, ITeacherTestTheme, IGroupAssign } from './types'
import * as constants from './constants'

/*interface IFetchCommentsActionPayload {
  rightPage: number
  withScrollToComment?: boolean
}
export interface IFetchCommentsAction {
  type: typeof constants.FETCH_COMMENTS
  payload: IFetchCommentsActionPayload | undefined
}
export interface IFetchNewCommentsAction {
  type: typeof constants.FETCH_NEW_COMMENTS
  payload: IFetchCommentsActionPayload
}*/



export interface IFetchTestsAction {
    type: typeof constants.FETCH_TESTS
    payload: undefined
  }

  export interface IFetchTestAction {
    type: typeof constants.FETCH_TEST
    payload: number
  }

// Actions

export const fetchTests: ActionFunctionAny<AnyAction> = createAction(constants.FETCH_TESTS)
export const fetchTest: ActionFunctionAny<Action<number>> = createAction(constants.FETCH_TEST)
export const initTests: ActionFunctionAny<Action<Array<ITeacherTest> | null>> = createAction(constants.INIT_TESTS)
export const initTest: ActionFunctionAny<Action<ITeacherTest>> = createAction(constants.INIT_TEST)
export const initGroups: ActionFunctionAny<Action<Array<ITeacherTestGroup> | null>> = createAction(constants.INIT_GROUPS)
export const initThemes: ActionFunctionAny<Action<Array<ITeacherTestTheme> | null>> = createAction(constants.INIT_THEMES)
export const initTheme: ActionFunctionAny<Action<ITeacherTestTheme>> = createAction(constants.INIT_THEME)
export const initAssigns: ActionFunctionAny<Action<IGroupAssign>> = createAction(constants.INIT_ASSIGNS)
export const markAllCheckTest: ActionFunctionAny<AnyAction> = createAction(constants.MARK_ALL_CHECK_TEST)

//export const setTests: ActionFunctionAny<Action<Array<ITeacherTest> | null>> = createAction(constants.SET_TESTS)
export const setTest: ActionFunctionAny<Action<ITeacherTest>> = createAction(constants.SET_TEST)
//export const setThemes: ActionFunctionAny<Action<Array<ITeacherTestTheme> | null>> = createAction(constants.SET_THEMES)
export const setTheme: ActionFunctionAny<Action<ITeacherTestTheme>> = createAction(constants.SET_THEME)

export const removeTest: ActionFunctionAny<Action<number>> = createAction(constants.REMOVE_TEST)
export const removeTestDone: ActionFunctionAny<Action<number>> = createAction(constants.REMOVE_TEST_DONE)
export const removeTheme: ActionFunctionAny<Action<number>> = createAction(constants.REMOVE_THEME)
export const setAssign: ActionFunctionAny<Action<{theme_id:number, group_id:number, selected:boolean}>> = createAction(constants.SET_ASSIGN)
export const newTheme: ActionFunctionAny<Action<string>> = createAction(constants.NEW_THEME)

export const setOrder: ActionFunctionAny<Action<string>> = createAction(constants.SET_ORDER)
export const setError: ActionFunctionAny<Action<string>> = createAction(constants.SET_ERROR)



// Reducers
const testsInitalState = Array<ITeacherTest>()
type TestPayload = ITeacherTest|ITeacherTest[]|number

const tests: ReduxCompatibleReducer<
Array<ITeacherTest>,
TestPayload> = function(state, action) {
  let _state = state || testsInitalState
  switch (action.type) {
    case constants.INIT_TESTS: {
      return action.payload as ITeacherTest[]
    }
    case constants.INIT_TEST:
    case constants.SET_TEST:  {
      let nstate = [..._state!];
      let ntest = action.payload as ITeacherTest
      for (let i = 0; i < nstate.length; i++){
        if (nstate[i].id === ntest.id){
          nstate[i] = ntest
        }
      }
      return nstate
    }
    case constants.REMOVE_TEST_DONE: {
      return _state.filter((i)=>i.id !== action.payload)
    }
  }
  return _state
}

type ThemePayload = ITeacherTestTheme|ITeacherTestTheme[]|number
const themesInitalState = Array<ITeacherTestTheme>()
const themes: ReduxCompatibleReducer<
    Array<ITeacherTestTheme>,
    ThemePayload> = function(state, action){
      let _state = state || themesInitalState
      switch (action.type) {
        case constants.INIT_THEMES: {
          return action.payload as ITeacherTestTheme[]
        }
        case constants.SET_THEME:
        case constants.INIT_THEME: {
          let nstate = [..._state!]
          let ntheme = action.payload as ITeacherTestTheme
          for (let i = 0; i < nstate.length; i++){
            if (nstate[i].id === ntheme.id){
              nstate[i] = {...ntheme}
              return nstate
            }
          }
          nstate.push(ntheme)
          return nstate
        }
        case constants.REMOVE_THEME: {
          return _state.filter((i)=>i.id !== action.payload)
        }
      }
      return _state
    }




const groupsInitalState = Array<ITeacherTestGroup>()
const groups: ReduxCompatibleReducer<
    Array<ITeacherTestGroup>,
    Array<ITeacherTestGroup>
> = handleActions(
    {
        [constants.INIT_GROUPS]: (
            _state: Array<ITeacherTestGroup>,
            action
        ) => action.payload
    },
    groupsInitalState
)


const orderInitalState = 'id'
const order: ReduxCompatibleReducer<string, string> = handleActions(
    {
        [constants.SET_ORDER]: (
          _state: string,
          action
        ) => action.payload
    },
    orderInitalState
)

const errorInitalState = 'Идет загрузка'
const error: ReduxCompatibleReducer<string, string> = handleActions(
    {
        [constants.SET_ERROR]: (
          _state: string,
          action
        ) => action.payload
    },
    errorInitalState
)

const groupAssignInitalState = {} as IGroupAssign
const groupAssign: ReduxCompatibleReducer<IGroupAssign, IGroupAssign|{theme_id:number, group_id:number, selected: boolean}> = function(state, action) {
  let _state = state || groupAssignInitalState
  switch (action.type) {
    case constants.INIT_ASSIGNS: {
      return action.payload as IGroupAssign
    }
    case constants.SET_ASSIGN: {
      let payload = action.payload as {theme_id:number, group_id:number, selected: boolean}
      let nstate = {..._state!}
      if (payload.selected){
        if (nstate[payload.theme_id] === undefined){
          nstate[payload.theme_id] = []
        }
        nstate[payload.theme_id] = [...nstate[payload.theme_id], payload.group_id]
      } else {
        nstate[payload.theme_id] = nstate[payload.theme_id].filter((i) => i !== payload.group_id)
      }
      
      return nstate
    }
  }
  return _state
}


export const initialState: TeacherTestState = {
    tests: testsInitalState,
    themes: themesInitalState,
    groups: groupsInitalState,
    order: orderInitalState,
    groupAssign: groupAssignInitalState,
    error: errorInitalState
}

const teacherTests = combineReducers({
  tests,
  themes,
  groups,
  order,
  groupAssign,
  error
})

export type TeacherTestState = ReturnType<typeof teacherTests>
export default teacherTests
