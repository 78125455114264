import React, { useCallback } from 'react'
import { withBemMod } from '@bem-react/core'

import { formatHTML } from 'utils'
import { ITextProps, cnText } from '../Text'
import 'components/Link/Link.scss'
import { hyphenatedText } from '../../../utils/hyphenatedText'

export interface ITextHtmlProps {
  /**
   * Рендер ХТМЛа
   */
  html?: boolean
}

/**
 * Модификатор, отвечающий за рендеринг ХТМЛа
 * @param {ITextHtmlProps} props
 */
export const withHtml = withBemMod<ITextHtmlProps, ITextProps>(
  cnText(),
  {
    html: true,
  },
  (WrappedComponent) => (props) => {
    const { children } = props

    const customProps = { ...props }
    delete customProps.children

    const createMarkup = useCallback(
      () => ({
        __html: children ? formatHTML(hyphenatedText(children.toString())) : '',
      }),
      [children]
    )

    return (
      <WrappedComponent
        {...customProps}
        dangerouslySetInnerHTML={createMarkup()}
      />
    )
  }
)
