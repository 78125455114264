import React from 'react'
import { useSelector } from 'react-redux'
import { cn } from '@bem-react/classname'

import Message from './Message'
import { selectChatDialog } from 'modules/Chat'
import { useFetchData } from './hooks'

export interface IDialogInfo {
  to: string
  toId: number
  from?: string
}

const Dialog = () => {
  const cnDialog = cn('Dialog')
  const dialog = useSelector(selectChatDialog)

  useFetchData()

  return (
    <div className={cnDialog()}>
      {dialog &&
        dialog.map(({ id, text, from, fromId, date }, i) => (
          <Message
            id={id}
            text={text}
            from={from}
            fromId={fromId}
            date={date}
            key={`${i}${id}`}
          />
        ))}
    </div>
  )
}

export default Dialog
