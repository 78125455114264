import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

export const useHandleClose = () => {
  const history = useNavigate()

  const handleClose = useCallback(() => {
    history('/')
  }, [history])

  return handleClose
}
