import React from 'react'
import { useSelector } from 'react-redux'
import { cn } from '@bem-react/classname'

import { selectAuthStatus } from 'modules/Auth'
import ProfileLink from './ProfileLink'
import ProfileDrawer from './ProfileDrawer'
import './Profile.scss'

const Profile = () => {
  const cnProfile = cn('Profile')
  const auth = useSelector(selectAuthStatus)

  return (
    <div className={cnProfile()}>
      {auth ? <ProfileLink /> : <ProfileDrawer />}
    </div>
  )
}

export default Profile
