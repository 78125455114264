import { COMMENT_NUMBER_PER_PAGE } from 'pages/GuestBookPage/Pagination/Pagination.const'

export const getFirstCommentIndexOnPage = (
  page: number,
  totalPages: number,
  commentNumberOnFirstPage: number = COMMENT_NUMBER_PER_PAGE
): number => {
  const diff = totalPages - page
  let startOffset = 0
  let offset = COMMENT_NUMBER_PER_PAGE

  if (diff > 0 && commentNumberOnFirstPage < COMMENT_NUMBER_PER_PAGE) {
    startOffset = COMMENT_NUMBER_PER_PAGE - commentNumberOnFirstPage
  }

  return diff * offset - startOffset
}
