import React, { FC, memo } from 'react'
import { useSelector } from 'react-redux'
import { cn } from '@bem-react/classname'
import cx from 'classnames'
import { compose } from '@bem-react/core'

import { selectChatDialogOpen } from 'modules/Chat'
import { cnChatPopup } from '../../ChatPopup'
import TextBase, { withHtml } from 'components/Text'
import './Message.scss'

const Text = compose(withHtml)(TextBase)

export interface IMessage {
  id: number
  text: string
  from?: string
  fromId?: number
  date?: string
  type?: 'success'
}

export const cnMessage = cn('Message')

const Message: FC<IMessage> = ({ date, from, fromId, text }) => {
  const dialogOpen = useSelector(selectChatDialogOpen)
  const isAdmin = fromId === 0

  return (
    <div
      className={cx(
        cnMessage(),
        cnChatPopup('ListItem', {
          mode: dialogOpen && 'dialog',
        }),
      )}
    >
      <div className={cnMessage('Date')}>{date}</div>
      <span className={cnMessage('Person')}>{from}</span>

      <Text className={cnMessage('Text')} html={isAdmin} linkClick={!isAdmin}>{text}</Text>

    </div>
  )
}

export default memo(Message)
