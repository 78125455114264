import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { fetchExam } from 'modules/Exam'
import { fetchAuthStatusSuccess, fetchAuthUserSuccess } from 'modules/Auth'
import { fetchSubject } from 'modules/Subject'
import { fetchSubjectMenu } from 'modules/SubjectMenu'
import { fetchOurVariants } from 'modules/OurVariants'
import { fetchLarinVariants } from 'modules/LarinVariants'
import { fetchWriting } from 'modules/Writing'
import {
  fetchTestConstructor,
  isTestConstructorWithTitleLinks,
  setTestConstructorParts,
} from 'modules/TestConstructor'
import { fetchSidebarMenu } from 'modules/SidebarMenu'
import { fetchAds } from 'modules/Ads'
import { fetchBots } from 'modules/Bots'
import { fetchNews } from 'modules/News'
import { fetchThieves } from 'modules/Thieves'
import { fetchApps } from 'modules/Apps'
import { getGeneralData } from '../api'
import { getPath, getExamName, formatSubject } from 'utils'
import {
  getOurVariants,
  getSubjectList,
  getSectionList,
  getNewsList,
} from 'utils/data'
import { loadTopicsList } from 'components/ConstructorForm/ConstructorForm.utils'

export const useGeneralData = () => {

  // const domain = getDomain()
  // const examName = getExamName(domain)
  //
  // dispatch(fetchExam({ name: examName, domain }))
  const dispatch = useDispatch()
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getGeneralData()
        const {
          user,
          subject,
          subjectList,
          ourVariants,
          larinVariants,
          constructor,
          constructorWithTitleLinks,
          constructorParts,
          sidebarMenu,
          ads,
          bots,
          news,
          thieves,
          apps,
          hostname,
          includeJS
        } = response.data

        if (includeJS){
          const scripts = [];
          const scripts_obj = document.getElementsByTagName('script')
          for (let i = 0; i < scripts_obj.length; i++){
              const srcobj = scripts_obj[i].attributes.getNamedItem('src')
              if (srcobj) scripts.push(srcobj.nodeValue)
          }
          for (let i = 0; i < includeJS.length; i++){
              if (scripts.indexOf(includeJS[i]) !== -1){
                continue
              }
              const sel = document.createElement('script')
              sel.src = includeJS[i]
              document.head.appendChild(sel)
          }
        }

        /**
         * @todo Оставить writing, когда переименуют на бэке
         */
        const writing = response.data.writing || response.data.sochinenie

        // Auth
        if (user) {
          const {
            id,
            name: firstName,
            sname: lastName,
            pinnedTaskAmount,
            username,
            city,
          } = user

          dispatch(fetchAuthStatusSuccess(true))
          dispatch(
            fetchAuthUserSuccess({
              id,
              firstName,
              lastName,
              pinnedTaskAmount,
              username,
              city,
            })
          )
        }

        if (hostname) {
          const domain = hostname
          const examName = getExamName(domain)
          dispatch(fetchExam({ name: examName, domain }))
        }

        // Main column
        dispatch(fetchAds(ads || null))
        const subjectFormatted = formatSubject(subject)
        dispatch(fetchSubject(subjectFormatted || null))
        dispatch(fetchSubjectMenu(subjectList ? subjectList : getSubjectList()))
        dispatch(fetchOurVariants(ourVariants ? ourVariants : getOurVariants()))
        dispatch(isTestConstructorWithTitleLinks(constructorWithTitleLinks))
        const testConstructor = loadTopicsList(hostname + getPath()) || constructor || null
        dispatch(fetchTestConstructor(testConstructor))
        dispatch(setTestConstructorParts(constructorParts))

        // Main column (for specific exams)
        dispatch(fetchLarinVariants(larinVariants || null))
        dispatch(fetchWriting(writing || null))

        // Sidebar
        dispatch(fetchSidebarMenu(sidebarMenu ? sidebarMenu : getSectionList()))
        dispatch(fetchBots(bots))
        dispatch(fetchNews(news ? news : getNewsList()))
        dispatch(fetchThieves(thieves))
        dispatch(fetchApps(apps))
      } catch (error) {
        console.error(error)
      }
    }

    fetchData()
  }, [dispatch])
}
