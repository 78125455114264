import { RootState } from "modules";


export const selectTeacherJournal = (state: RootState) =>
  state.teacherJournal

export const selectHoverState = (state: RootState) => 
  state.teacherJournalHover
export const selectIsTestHover = (testId: number) => (state: RootState) =>
  state.teacherJournalHover.hoverTest === testId
export const selectIsUserHover = (userId: number) => (state: RootState) =>
state.teacherJournalHover.hoverUser === userId