import { Dispatch, SetStateAction, useCallback } from 'react'

import { IFriend } from '..'
import { removeContact } from '../api'

export const useHandleRemove = (
  contactList: Array<IFriend> | null,
  setContactList: Dispatch<SetStateAction<IFriend[] | null>>
) => {
  const handleRemove = useCallback(
    async (e) => {
      e.stopPropagation()
      const { id, name } = e.currentTarget.dataset

      const isConfirmed = window.confirm(`Удалить контакт «${name}»?`)
      if (!isConfirmed) return

      const newContactList = [...contactList!]
      const i = newContactList.findIndex(
        (contact) => contact.id === parseInt(id)
      )
      const [removedContact] = newContactList.splice(i, 1)
      setContactList(newContactList)

      try {
        await removeContact(parseInt(id))
      } catch (error) {
        console.error(error)

        const restoredContactList = [...newContactList]
        restoredContactList.splice(i, 0, removedContact)
        setContactList(restoredContactList)
      }
    },
    [contactList, setContactList]
  )

  return handleRemove
}
