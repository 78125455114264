import { ChangeEvent, Dispatch, SetStateAction, useCallback } from 'react'

export const useHandleChange = (
  setEmail: Dispatch<SetStateAction<string>>,
  alertText: string,
  setAlertText: Dispatch<SetStateAction<string>>
) => {
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setEmail(e.target.value)

      if (alertText) {
        setAlertText('')
      }
    },
    [setEmail, alertText, setAlertText]
  )

  return handleChange
}
