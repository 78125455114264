import React, { FC, ReactNode, RefObject } from 'react'
import { cn } from '@bem-react/classname'

import './Section.scss'

interface ISectionProps {
  className?: string

  innerRef?: RefObject<HTMLElement>

  children: ReactNode
}

export const cnSection = cn('Section')

const Section: FC<ISectionProps> = ({ className, innerRef, children }) => (
  <section className={cnSection(null, [className])} ref={innerRef}>
    {children}
  </section>
)

export default Section
