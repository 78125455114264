const KEY = 'extraTopicsHidden'

const loadExtraTopicsHidden = (): boolean | null => {
  const value = localStorage.getItem(KEY)

  if (!value) return null

  return value === 'true'
}

const saveExtraTopicsHidden = (value: boolean) => {
  localStorage.setItem(KEY, value.toString())
}

export { loadExtraTopicsHidden, saveExtraTopicsHidden }
