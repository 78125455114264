/**
 * Список страниц,
 * на которые можно перейти
 * без перезагрузки страницы.
 * Будет пополняться
 */
const clientRoutingLinksTeacher = [
    '/teacher/test', '/teacher/pupil', '/teacher/journal', '/teacher/individual-profile'
]
export const clientRoutingLinksSidebar = ['/', '/guestbook', '/prob-catalog']
export const clientRoutingLinksFooter = ['/about', '/staff', '/legal', '/advert']

export const clientRoutingLinks = [...clientRoutingLinksTeacher, ...clientRoutingLinksSidebar, ...clientRoutingLinksFooter]

export const clientRoutingPageListDev = [...clientRoutingLinks, '/teacher']



