import React, { FC } from 'react'

import Input from 'components/Input'

import { cnChangePasswordPopup } from '../..'

const ChangePasswordPopupFakeControl: FC = () => {
  const url = new URL(window.location.href)
  const email = url.searchParams.get('email') || localStorage.getItem('email')

  return (
    <Input
      className={cnChangePasswordPopup('FormInput', { email: true })}
      type="email"
      name="email"
      id="email-for-new-password"
      value={email || ''}
      autoComplete="username"
    />
  )
}

export default ChangePasswordPopupFakeControl
