import { IUser } from '.'
import { RootState } from 'modules'

export const selectAuthLoading = (state: RootState): boolean =>
  state.auth.loading

export const selectAuthStatus = (state: RootState): boolean => state.auth.status

export const selectAuthUser = (state: RootState): IUser | null =>
  state.auth.user
