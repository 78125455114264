import React, { FC, MouseEvent } from 'react'
import { useSelector } from 'react-redux'
import { compose } from '@bem-react/core'

import { cnTeacherVariant } from '..'
import { selectExam, selectorIsUAExam } from 'modules/Exam'
import { redirectToTest } from '../utils'
import ButtonBase, { withViewSecondary } from 'components/Button'
import './TeacherVariant-Alert.scss'

const Button = compose(withViewSecondary)(ButtonBase)

export type ErrorType = 'NO' | 'NOT_FOUND' | 'NOT_TEACHER' | 'ERROR'

interface IAlertProps {
  errorType: ErrorType
  id?: number
}

const Alert: FC<IAlertProps> = ({ errorType, id }) => {
  const exam = useSelector(selectExam)
  const isUAExam = useSelector(selectorIsUAExam)

  if (errorType === 'NOT_FOUND') {
    return <div className={cnTeacherVariant('Alert', [
      'Form-Alert',
      'Form-Alert_view_action',
    ])}>{isUAExam ? `Такого варіанту поки що немає` : `Такого варианта пока нет`}</div>
  }

  if (errorType === 'NOT_TEACHER') {
    const handleClick = (e: MouseEvent) => {
      e.preventDefault()

      redirectToTest(exam?.domain, id!)
    }

    return (
      <div
        className={cnTeacherVariant('Alert', [
          'Form-Alert',
          'Form-Alert_view_action',
        ])}
      >
        <div>
          <p className={cnTeacherVariant('AlertText', { mobile: true })}>
            {isUAExam ? `Такий варіант є, але його склав не вчитель` : `Такой вариант есть, но его составил не учитель`}
          </p>
          <p className={cnTeacherVariant('AlertText', { desktop: true })}>
            {isUAExam ? `Такий варіант є,` : `Такой вариант есть,`}
            <br />
            {isUAExam ? `але його склав не вчитель` : `но его составил не учитель`}
          </p>
        </div>

        <div>
          <Button
            className={cnTeacherVariant('AlertButton')}
            view="secondary"
            handleClick={handleClick}
          >
            Перейти
          </Button>
        </div>
      </div>
    )
  }

  if (errorType === 'ERROR') {
    return <div className={cnTeacherVariant('Alert', [
      'Form-Alert',
      'Form-Alert_view_action',
    ])}>{isUAExam ? `Вибачте, спробуйте ще раз` : `Извините, попробуйте еще раз`}</div>
  }

  return <div className="Form-Alert" />
}

export default Alert
