import { takeLatest, put } from 'redux-saga/effects'

import { getExamTitle } from 'utils'
import { fetchExam, fetchExamFullData } from './duck'

function* fetchExamWatcher() {
  yield takeLatest(fetchExam, fetchExamFlow)
}

function* fetchExamFlow(action: any) {
  const { name, domain } = action.payload
  const title = getExamTitle(name)

  yield put(
    fetchExamFullData({
      title,
      name,
      domain,
    })
  )
}

export default function* sagas() {
  yield fetchExamWatcher()
}
