import {
  ActionFunctionAny,
  Action,
  ReduxCompatibleReducer,
  createAction,
  handleActions,
} from 'redux-actions'

import { ISubject } from 'utils/data/subjectList'
import * as constants from './constants'

export interface ISubjectMenuAction {
  type: typeof constants.FETCH_SUBJECT_MENU
  payload: ISubject | null
}

// Actions
export const fetchSubjectMenu: ActionFunctionAny<Action<Array<
  ISubject
> | null>> = createAction(constants.FETCH_SUBJECT_MENU)

// Reducers
const subjectMenu: ReduxCompatibleReducer<
  Array<ISubject> | null,
  Array<ISubject> | null
> = handleActions(
  {
    [constants.FETCH_SUBJECT_MENU]: (_state: Array<ISubject> | null, action) =>
      action.payload,
  },
  null
)

export const initialState = null
export type subjectMenuState = ReturnType<typeof subjectMenu>
export default subjectMenu
