import { RootState } from 'modules'

import { IMessage } from 'pages/App/components/Chat/ChatPopup/Dialog/Message'
import { ChatDialogState } from './duck'

export const selectChat = (state: RootState): ChatDialogState => state.chat

export const selectChatOpen = (state: RootState): boolean => state.chat.open

export const selectChatDialogInfoTo = (state: RootState): string | null => {
  const dialogInfo = state.chat.dialogInfo

  return dialogInfo && dialogInfo.to
}

export const selectChatDialogInfoToId = (state: RootState): number | null => {
  const dialogInfo = state.chat.dialogInfo

  return dialogInfo && dialogInfo.toId
}

export const selectChatDialog = (state: RootState): Array<IMessage> | null =>
  state.chat.dialog

export const selectChatDialogOpen = (state: RootState): boolean =>
  state.chat.dialogOpen

export const selectChatListEl = (state: RootState): HTMLDivElement | null =>
  state.chat.listEl
