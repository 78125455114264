import React, { FC } from 'react'
import cx from 'classnames'

import Link from 'components/Link'
import 'components/Link/_wrap/Link_wrap.scss'
import LinkWrapU from 'components/Link/_wrap/-U'
import 'components/Link/_white/Link_white.scss'

export interface INewsItemProps {
  type: string
  date: string | null
  href: string
  text: string
  html?: string
  letterCase?: string
}

const NewsItem: FC<INewsItemProps> = ({
  type,
  date,
  href,
  text,
  html,
  letterCase,
}) => {
  return (
    <Link
      className={cx(
        {
          Link_static: type !== 'urgent',
          'Link_white Link_wrap': type === 'urgent',
          Smallcapitals: letterCase === 'upper',
        },
        'News-Item',
        {
          [`News-Item_${type}`]: type !== 'default',
        }
      )}
      href={href}
      target="_blank"
    >
      {type !== 'urgent' && (
        <>
          <div className="News-Date Smallcapitals">{date}</div>
          <div
            className="News-Text"
            dangerouslySetInnerHTML={{ __html: html || text }}
          />
        </>
      )}

      {type === 'urgent' && (
        <LinkWrapU className="Link_white-U">{text}</LinkWrapU>
      )}
    </Link>
  )
}

export default NewsItem
