import React, { FC, useState } from 'react'

import { cnChatPopup } from '../..'
import { useFetchData, useHandleRemove, useOpenDialog } from './hooks'

export interface IFriend {
  id: number
  name: string
}

const ContactList: FC = () => {
  const [contactList, setContactList] = useState<Array<IFriend> | null>(null)

  useFetchData(setContactList)

  const openDialog = useOpenDialog()
  const handleRemove = useHandleRemove(contactList, setContactList)

  return (
    <div className={cnChatPopup('ContactList')}>
      {contactList &&
        contactList?.map(({ id, name }) => (
          <div
            className={cnChatPopup('ListItem')}
            data-id={id}
            data-name={name}
            key={id}
            onClick={openDialog}
          >
            <span className={cnChatPopup('ListItemName')}>{name}</span>

            {/* Чтобы случайно не убирали систему оповещений */}
            {id !== 0 && (
              <div
                className={cnChatPopup('ListItemClose')}
                data-id={id}
                data-name={name}
                onClick={handleRemove}
              >
                ×
              </div>
            )}
          </div>
        ))}
    </div>
  )
}

export default ContactList
