import { withBemMod } from '@bem-react/core'

import { cnInput } from '../Input'
import './Input_size_l.scss'

export interface IInputSizeLProps {
  /**
   * Размер поля
   */
  size?: 'l'
}

/**
 * Модификатор, отвечающий за размер поля
 * @param {IInputSizeLProps} props
 */
export const withSizeL = withBemMod<IInputSizeLProps>(cnInput(), {
  size: 'l',
})
