import React, { Dispatch, FC, SetStateAction, useRef } from 'react'
import { useSelector } from 'react-redux'
import cx from 'classnames'

import { selectTestConstructor } from 'modules/TestConstructor'

import { ISubtopic } from 'components/ConstructorForm/Buttons'
import { ITestTotal, cnConstructorForm } from 'components/ConstructorForm'
import Counter from 'components/Counter'

import { useCheckboxHandler } from './hooks'
import Checkbox from '../../../Checkbox'
import Link from 'components/Link'

interface ISubtopicProps {
  page: string
  topicI: number
  subtopic: ISubtopic
  subtopicI: number
  testTotal: ITestTotal
  setTestTotal: Dispatch<SetStateAction<ITestTotal>>
  part: string
  switchOnPart: (part: string) => void
  switchOffPart: (part: string) => void

  /**
   * Тема в выпадайке
   * 
   * Используется в ЦТ
   */
  isRowTooglerItem: boolean
}

const Subtopic: FC<ISubtopicProps> = ({
  page,
  topicI,
  subtopic,
  subtopicI,
  testTotal,
  setTestTotal,
  part,
  switchOnPart,
  switchOffPart,
  isRowTooglerItem,
}) => {
  const topicsList = useSelector(selectTestConstructor)
  const subtopicLabel = useRef<HTMLLabelElement | null>(null)

  const handleCheckboxChange = useCheckboxHandler(page, subtopicLabel)

  const withCounter = subtopic.value !== null

  const counter = (
    <Counter
      className={cnConstructorForm('Counter')}
      page={page}
      name={subtopic.name || ''}
      index={topicI}
      subtopicIndex={subtopicI}
      value={subtopic.value!}
      list={topicsList ? [...topicsList] : []}
      testTotal={testTotal}
      setTestTotal={setTestTotal}
      part={part}
      switchOnPart={switchOnPart}
      switchOffPart={switchOffPart}
    />
  )

  const label = (
    <label
      className={cx(
        "Link_wrap",
        "ConstructorForm-TopicName",
        {
          "ConstructorForm-TopicName_tooglerItem": isRowTooglerItem
        },
        "Label"
      )}
      // For and id for work in IE 11
      htmlFor={`probtheme${subtopic.id}`}
      ref={subtopicLabel}
    >
      {!withCounter && (
        <div className="ConstructorForm-TopicNumber">
          <input
            type="hidden"
            name={`hasprobtheme${subtopic.id}`}
            value="true"
          />
          <Checkbox
            fakeCheckboxClassName="ConstructorForm-TopicSubCheckbox"
            name={`probtheme${subtopic.id}`}
            value={subtopic.checked}
            id={`probtheme${subtopic.id}`}
            data-topic-i={topicI}
            data-subtopic-i={subtopicI}
            onChange={handleCheckboxChange}
          />
        </div>
      )}

      <div className="ConstructorForm-TopicDesc">
        {subtopic.title}
        &nbsp;·&nbsp;
        <Link
          className="Link_black"
          href={`/test?theme=${subtopic.id}`}
        >
          {subtopic.amount}
          &nbsp;шт.
        </Link>
      </div>
    </label>
  )

  return withCounter ? (
    <div className={cnConstructorForm('Row')}>
      {counter}
      {label}
    </div>
  ) : (
    <>{label}</>
  )
}

export default Subtopic
