import { createClassNameModifier } from '@bem-react/core'

import { cnLink2 } from '../Link2'
import './Link2_selected.scss'

export interface ILink2SelectedProps {
  /**
   * Роль обёртки
   *
   * Увеличивает кликабельную зону ссылки
   * вокруг подчёркнутого текста
   */
  selected?: boolean
}

/**
 * Модификатор, отвечающий за внешний вид кнопки
 * @param {ILink2SelectedProps} props
 */
export const withSelected = createClassNameModifier<ILink2SelectedProps>(
  cnLink2(),
  {
    selected: true,
  }
)
