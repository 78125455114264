import { RefObject, Dispatch, SetStateAction } from 'react'

import { shake } from 'utils'

export const showFailureAnimation = (
  inputRef: RefObject<HTMLInputElement>,
  isLoading: Dispatch<SetStateAction<boolean>>
) => {
  shake(inputRef)
  inputRef.current?.focus()
  isLoading(false)
}
