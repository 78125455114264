import { withBemMod } from '@bem-react/core'

import { cnDrawer } from '../Drawer.const'
import './Drawer_view_default.scss'

export interface IDrawerViewDefaultProps {
  view?: 'default'
}

export const withViewDefault = withBemMod<IDrawerViewDefaultProps>(cnDrawer(), {
  view: 'default',
})
