import axios from 'axios'


import { getApiKey } from '../../../utils'
import { ICatalog, ICatType, IStats } from '../types'

 const API_KEY = getApiKey()
const ENDPONT = 'catalog'

interface IGenericResponse {
    status?: number,
    error?: string,
}
interface ICatTypeResponse extends IGenericResponse{
    types?: ICatType[]
}
interface ICatalogResponse extends IGenericResponse{
    catalog?: ICatalog
}

interface IUserStatsResponse extends IGenericResponse{
    stats?: IStats
}


export const doFetchTypes = async () => {
    try {
        const resp = await axios.post(
            `/newapi/${ENDPONT}/types${API_KEY}`,
            {},
            {
            params: {},
            withCredentials: true,
            }
        )
        if (resp) {
            const res: ICatTypeResponse = resp.data
            if (res.status !== 0) {
                window.alert('Ошибка при загрузке ')
                console.error(res.error)
            }
            return Promise.resolve(res)
        } else {
            window.alert('Ошибка при загрузке')
            console.error(`Error with /${ENDPONT}/`)
        }
    } catch (e) {
        window.alert('Ошибка')
    }
    return Promise.reject()
}

export const doFetchCatalog = async(type: string, name: string) => {
    try {
        const resp = await axios.post(
            `/newapi/${ENDPONT}/catalog${API_KEY}`,
            {type, name},
            {
            params: {},
            withCredentials: true,
            }
        )
        if (resp) {
            const res: ICatalogResponse = resp.data
            if (res.status !== 0) {
                window.alert('Ошибка при загрузке ')
                console.error(res.error)
            }
            return Promise.resolve(res.catalog!)
        } else {
            window.alert('Ошибка при загрузке')
            console.error(`Error with /${ENDPONT}/`)
        }
    } catch (e) {
        window.alert('Ошибка')
    }
    return Promise.reject()
}

export const doFetchUserStats = async(type: string, name: string) => {
    try {
        const resp = await axios.post(
            `/newapi/${ENDPONT}/stats${API_KEY}`,
            {type, name},
            {
            params: {},
            withCredentials: true,
            }
        )
        if (resp) {
            const res: IUserStatsResponse = resp.data
            if (res.status !== 0) {
                window.alert('Ошибка при загрузке ')
                console.error(res.error)
            }
            return Promise.resolve(res.stats!)
        } else {
            window.alert('Ошибка при загрузке')
            console.error(`Error with /${ENDPONT}/`)
        }
    } catch (e) {
        window.alert('Ошибка')
    }
    return Promise.reject()
}

export const saveOrders = async(type: string, catalog: string, orders: {[key: number]: number}) => {
    try {
        const resp = await axios.post(
            `/newapi/${ENDPONT}/order${API_KEY}`,
            {type, catalog, orders},
            {
            params: {},
            withCredentials: true,
            }
        )
        if (resp) {
            const res: IUserStatsResponse = resp.data
            if (res.status !== 0) {
                window.alert('Ошибка при загрузке ')
                console.error(res.error)
            }
            return Promise.resolve(res.stats!)
        } else {
            window.alert('Ошибка при загрузке')
            console.error(`Error with /${ENDPONT}/`)
        }
    } catch (e) {
        window.alert('Ошибка')
    }
    return Promise.reject() 
}