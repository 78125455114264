export const CHANGE_PASSWORD_POPUP_TITLE = {rus: `Восстановление пароля`, ua: `Відновлення паролю`}

export const ChangePasswordPopupAlert = {
  EMPTY_FORM_ERROR: {rus: 'Придумайте и повторите новый пароль', ua: `Придумайте та повторіть новий пароль`},
  PASSWORDS_DONT_MATCH_ERROR: {rus: 'Пароли не совпадают', ua: `Паролі не співпадають`},
  SERVER_ERROR: {rus: 'По этой ссылке пароль уже был изменен', ua: `За цим посиланням пароль вже було змінено`},
  SUCCESS: {rus: '✓ Вы успешно поменяли пароль', ua: `✓ Ви успішно змінили пароль`},
}




