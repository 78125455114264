import React, { FC, ReactNode } from 'react'
import { cn } from '@bem-react/classname'

import { OnClose } from 'components/Modal'
import Button from 'components/Button'

import './DeskCard.scss'

const cnDeskCard = cn('DeskCard')

interface IDeskCardProps {
  className?: string
  open?: boolean
  toggleVisibility: OnClose
  children: ReactNode
}

const DeskCard: FC<IDeskCardProps> = ({
  className,
  open,
  toggleVisibility,
  children,
}) => (
  <Button
    className={cnDeskCard({ open, favourite: true }, [className])}
    handleClick={toggleVisibility}
  >
    {children}
  </Button>
)

export default DeskCard
