import {
  ActionFunctionAny,
  Action,
  ReduxCompatibleReducer,
  createAction,
  handleActions,
} from 'redux-actions'

import * as constants from './constants'

export type AdsType = {
  [part in AdParts]: string
}

export enum AdParts {
  TOP = 'top',
  BOTTOM = 'bottom',
  LEFT = 'left',
  LEFT_TOP = 'leftTop',
}

export interface AdsTypeStatusAction {
  type: typeof constants.FETCH_ADS
  payload: AdsType | null
}

// Actions
export const fetchAds: ActionFunctionAny<Action<AdsType | null>> = createAction(
  constants.FETCH_ADS
)

// Reducers
const ads: ReduxCompatibleReducer<
  AdsType | null,
  AdsType | null
> = handleActions(
  {
    [constants.FETCH_ADS]: (_state: AdsType | null, action) => action.payload,
  },
  null
)

export const initialState = null
export type AdsState = ReturnType<typeof ads>
export default ads
