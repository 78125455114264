import { useSelector } from 'react-redux'

import { IExam, selectExam } from 'modules/Exam'
import { selectSubject } from 'modules/Subject'
import { getFitSubjectName } from 'utils'
import { ExamList } from '..'

export const useExamList = (): Array<IExam> => {
  const exam = useSelector(selectExam)
  const subject = useSelector(selectSubject)
  const list: Array<IExam> = []

  ExamList.forEach((item) => {
    let listItem = item

    const isOgeForEge = exam?.name === 'ege' && item.name === 'oge'
    const isEgeForOge = exam?.name === 'oge' && item.name === 'ege'
    if (isOgeForEge || isEgeForOge) {
      const subjectName = getFitSubjectName(subject!)
      const domain = `${subjectName}-${item.domain}`

      listItem = {
        ...item,
        domain,
      }
    }

    list.push(listItem)
  })

  const restExamList = list.filter(({ name }) => name !== exam?.name)

  return restExamList
}
