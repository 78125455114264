import axios from 'axios'

import { isTestMode, getApiKey } from 'utils'

export interface IChangePasswordParams {
  uid: string | null
  token: string | null
  sign: string | null
  passwordNew: string
}

export interface IChangePasswordResult {
  success: boolean
}

const API_KEY = getApiKey()
const ENDPONT = 'password'

export const changePassword = async ({
  uid,
  token,
  sign,
  passwordNew,
}: IChangePasswordParams): Promise<boolean | void> => {
  try {
    const response = await axios.post(
      `/newapi/${ENDPONT}${API_KEY}`,
      {
        uid,
        token,
        sign,
        password: passwordNew,
      },
      { withCredentials: true }
    )

    if (response) {
      const result: IChangePasswordResult = response.data

      if (result.success) {
        return Promise.resolve()
      } else {
        console.error(`Error with /${ENDPONT}/`)
        return Promise.reject()
      }
    }
  } catch (error) {
    if (!isTestMode) {
      console.error(error)
    }
  }

  return Promise.reject()
}
