import {
  ActionFunctionAny,
  ReduxCompatibleReducer,
  createAction,
  handleActions,
} from 'redux-actions'

import * as constants from './constants'
import { IStatIndividualProfileFromBackEnd } from './types'

import { combineReducers } from 'redux'

export interface IIndividualProfile extends IStatIndividualProfileFromBackEnd {
  isAllTestStat: boolean
  isTeacherTestStat: boolean
  isTeacherTestStatWithoutLastMonth: boolean
}

// Action Interfaces
export interface IFetchStatIndividualProfileAction {
  type: typeof constants.FETCH_STAT
  payload: null
}

export interface ISetStatIndividualProfileAction {
  type: typeof constants.SET_STAT_DATA
  payload: IStatIndividualProfileFromBackEnd
}

// Actions
export const fetchStat: ActionFunctionAny<any> = createAction(
  constants.FETCH_STAT
)

export const setStat: ActionFunctionAny<ISetStatIndividualProfileAction> = createAction(
  constants.SET_STAT_DATA
)

export const setIsAllTestStat: ActionFunctionAny<any> = createAction(
  constants.SET_IS_ALL_TEST_STAT
)

export const setIsTeacherTestStat: ActionFunctionAny<any> = createAction(
  constants.SET_IS_TEACHER_TEST_STAT
)

export const setTeacherTestStatWithoutLastMonth: ActionFunctionAny<any> = createAction(
  constants.SET_IS_TEACHER_TEST_STAT_WITHOUT_LAST_MONTH
)

// Reducers
const dataInitialState = null
const data: ReduxCompatibleReducer<
  IStatIndividualProfileFromBackEnd | null,
  IStatIndividualProfileFromBackEnd | null
> = handleActions(
  {
    /*[constants.FETCH_STAT]: (
      _state: IStatIndividualProfileFromBackEnd | null,
      action
    ) =>
      action.payload,*/

    [constants.SET_STAT_DATA]: (
      _state: IStatIndividualProfileFromBackEnd | null,
      action
    ) => action.payload,
  },
  dataInitialState
)

const isAllTestStat: ReduxCompatibleReducer<boolean, boolean> = handleActions(
  {
    [constants.SET_IS_ALL_TEST_STAT]: (_state: boolean, action) =>
      action.payload,
  },
  true
)

const isTeacherTestStat: ReduxCompatibleReducer<
  boolean,
  boolean
> = handleActions(
  {
    [constants.SET_IS_TEACHER_TEST_STAT]: (_state: boolean, action) =>
      action.payload,
  },
  true
)

const isTeacherTestStatWithoutLastMonth: ReduxCompatibleReducer<
  boolean,
  boolean
> = handleActions(
  {
    [constants.SET_IS_TEACHER_TEST_STAT_WITHOUT_LAST_MONTH]: (
      _state: boolean,
      action
    ) => action.payload,
  },
  true
)

export const initialState: individualProfileState = {
  data: dataInitialState,
  isAllTestStat: true,
  isTeacherTestStat: true,
  isTeacherTestStatWithoutLastMonth: true,
}

const individualProfile = combineReducers({
  data,
  isAllTestStat,
  isTeacherTestStat,
  isTeacherTestStatWithoutLastMonth,
})

export type individualProfileState = ReturnType<typeof individualProfile>
export default individualProfile
