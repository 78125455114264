import { useSelector } from 'react-redux'

import { selectExam } from 'modules/Exam'
import { selectSubject } from 'modules/Subject'

const EnlargedExamList = ['vpr']
const EnlargedSubjectList = ['math5', 'bio6', 'bio7', 'bio8', 'geo6']

export const useTopicType = (isExtraPart: boolean): string => {
  let topicType = 'default'

  const exam = useSelector(selectExam)
  const subject = useSelector(selectSubject)

  const withEnlargedTopicNumber =
    EnlargedExamList.includes(exam?.name || '') &&
    EnlargedSubjectList.includes(subject?.name || '')

  if (isExtraPart) {
    topicType = 'extra'
  }
  if (withEnlargedTopicNumber) {
    topicType = 'enlarged'
  }

  return topicType
}
