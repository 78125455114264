import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { FormAlert } from 'components/FormAlert/FormAlert.bundle'

import { ChangePasswordPopupAlert, cnChangePasswordPopup } from '../..'
import './ChangePasswordPopup-FormAlert.scss'
import { selectorIsUAExam } from 'modules/Exam/selectors'

interface IChangePasswordPopupAlertProps {
  errorText: string
  isSubmitSuccessful: boolean
}

const ChangePasswordPopupFormAlert: FC<IChangePasswordPopupAlertProps> = ({
  errorText,
  isSubmitSuccessful,
}) => {
  const isUAExam = useSelector(selectorIsUAExam)
  const successText = isUAExam ? ChangePasswordPopupAlert.SUCCESS.ua : ChangePasswordPopupAlert.SUCCESS.rus
  const view = isSubmitSuccessful ? 'success' : 'error'
  const text = isSubmitSuccessful ? successText : errorText

  return (
    <FormAlert className={cnChangePasswordPopup('FormAlert')} view={view}>
      {text}
    </FormAlert>
  )
}

export default ChangePasswordPopupFormAlert
