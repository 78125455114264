import {
  ActionFunctionAny,
  Action,
  ReduxCompatibleReducer,
  createAction,
  handleActions,
} from 'redux-actions'

import * as constants from './constants'

export interface IWritingStatusAction {
  type: typeof constants.FETCH_WRITING
  payload: string | null
}

// Actions
export const fetchWriting: ActionFunctionAny<Action<
  string | null
>> = createAction(constants.FETCH_WRITING)

// Reducers
const writing: ReduxCompatibleReducer<
  string | null,
  string | null
> = handleActions(
  {
    [constants.FETCH_WRITING]: (_state: string | null, action) =>
      action.payload,
  },
  null
)

export const initialState = null
export type writingState = ReturnType<typeof writing>
export default writing
