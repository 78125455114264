import { ISubject } from './data'

export const getFitSubjectName = ({ name = 'math' }: ISubject): string => {
  switch (name) {
    case 'mathb':
      return 'math'

    case 'ruso':
      return 'rus'

    case 'alg':
      return 'math'
    case 'geom':
      return 'math'
    case 'analys':
      return 'math'
    case 'exam':
      return 'math'

    default:
      return name || 'math'
  }
}
