import React, { FC, ReactNode } from 'react'
import { cn } from '@bem-react/classname'
import cx from 'classnames'

import Link from 'components/Link'
import Fallback from 'components/Fallback'
import './VariantLink.scss'
import { useSelector } from 'react-redux'
import { selectorIsUAExam } from '../../modules/Exam'

export interface IVariantLinkProps {
  className?: string
  to?: string
  title?: string
  fallback?: boolean
  children?: ReactNode
  'data-wordClassName'?: string
}

const VariantLink: FC<IVariantLinkProps> = (props) => {
  const { className, to = '', title, fallback = false, children } = props
  const dataWordClassName = props['data-wordClassName']
  const variantLink = cn('VariantLink')
  const isUAExam = useSelector(selectorIsUAExam)

  return fallback ? (
    <Fallback className={cx(variantLink(), className)} />
  ) : (
    <Link className={cx(variantLink(), className)} href={to} title={title}>
      {!Number.isNaN(Number(children)) && dataWordClassName && (
        <span className={dataWordClassName}>{isUAExam ? `Варіант` : `Вариант`}&nbsp;</span>
      )}
      {children}
    </Link>
  )
}

export default VariantLink
