import axios from 'axios'



import { getApiKey, isTestMode } from '../../../utils'
import { IGroups, IGroupSel, IPortals, ITeacherMark, ITest, ITestRaw, ITests, IUserResult, IUserResults, IUsers } from '../types'


const API_KEY = getApiKey()
const ENDPONT = 'journal'



export const doFetchGroups = async () => {
    try {
      const response = await axios.get(
        `/newapi/${ENDPONT}/groups${API_KEY}`,
        {
          params: {},
          withCredentials: true,
        }
      )
  
      if (response) {
        const result: {portals?: IPortals, groups?: IGroups, error?:string} = response.data
        if (result) {
          return Promise.resolve(result)
        } else {
          console.error(`Error with /${ENDPONT}/groups`)
          return Promise.reject()
        }
      }
    } catch (error) {
      if (!isTestMode) {
        console.error(error)
      }
    }
    return {portals: [] as IPortals, groups: [] as IGroups}
}

export const doFetchJournal = async(action: IGroupSel) => {
  try {
    const response = await axios.post(
      `/newapi/${ENDPONT}/journal${API_KEY}`,
      action
      ,
      {
        params: {},
        withCredentials: true,
      }
    )

    if (response) {
      const result: {tests: ITests, results: IUserResults, users: IUsers} = response.data
      if (result) {
        return Promise.resolve(result)
      } else {
        console.error(`Error with /${ENDPONT}/journal`)
        return Promise.reject()
      }
    }
  } catch (error) {
    if (!isTestMode) {
      console.error(error)
    }
  }
  return {tests: [] as ITests, results: [] as IUserResults, users: [] as IUsers} 
}

export const doFetchTest = async(action: ITest) => {
  try {
    const response = await axios.get(
      `/newapi/${ENDPONT}/test${API_KEY}?id=${action.id}&portal=${action.portal}&subject=${action.subject}`,
      {
        params: {},
        withCredentials: true,
      }
    )

    if (response) {
      const result: ITestRaw = response.data
      if (result) {
        return Promise.resolve(result)
      } else {
        console.error(`Error with /${ENDPONT}/journal`)
        return Promise.reject()
      }
    }
  } catch (error) {
    if (!isTestMode) {
      console.error(error)
    }
  }
  return Promise.reject()
}

export const doSetTeacherMark = async(mark: ITeacherMark, portal:string, subject:string) => {
  try {
    const response = await axios.post(
      `/newapi/${ENDPONT}/setmark${API_KEY}`,
      {...mark, portal, subject},
      {
        params: {},
        withCredentials: true,
      }
    )
    if (response) {
      const result: {status: number} = response.data
      if (result && result.status === 0) {
        return Promise.resolve()
      }
    }
  } catch (error) {
    if (!isTestMode) {
      console.error(error)
    }
  }
  console.error(`Error with /${ENDPONT}/journal`)
  return Promise.reject()
}

export const doDeleteResult = async(result: IUserResult) => {
  try {
    const response = await axios.post(
      `/newapi/${ENDPONT}/delete_result${API_KEY}`,
      {id: result.id, portal: result.portal, subject: result.subject},
      {
        params: {},
        withCredentials: true,
      }
    )
    if (response) {
      const result: {status: number} = response.data
      if (result && result.status === 0) {
        return Promise.resolve()
      }
    }
  } catch (error) {
    if (!isTestMode) {
      console.error(error)
    }
  }
  console.error(`Error with /${ENDPONT}/journal`)
  return Promise.reject()
}

