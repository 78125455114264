const STORE_KEY = 'TEACHERJOURNAL'

export const FETCH_GROUPS = `${STORE_KEY}/FETCH_GROUPS`
export const FETCH_JOURNAL = `${STORE_KEY}/FETCH_JOURNAL`

export const INIT_PORTALS = `${STORE_KEY}/INIT_PORTALS`
export const APPEND_USERS = `${STORE_KEY}/APPEND_USERS`
export const APPEND_RESULTS = `${STORE_KEY}/APPEND_RESULTS`
export const APPEND_GROUPS = `${STORE_KEY}/APPEND_GROUPS`

export const SET_ERROR = `${STORE_KEY}/SET_ERROR`
export const SET_GROUP = `${STORE_KEY}/SET_GROUP`
export const SET_SORT = `${STORE_KEY}/SET_SORT`
export const SET_MEAN_FROM = `${STORE_KEY}/SET_MEAN_FROM`
export const SET_MEAN_TO = `${STORE_KEY}/SET_MEAN_TO`

export const SET_GROUP_LOADED = `${STORE_KEY}/SET_GROUP_LOADED`
export const SET_GROUP_MEMBERS = `${STORE_KEY}/SET_GROUP_MEMBERS`

export const APPEND_TESTS = `${STORE_KEY}/APPEND_TESTS`
export const TOGGLE_TEST = `${STORE_KEY}/TOGGLE_TEST`

export const INIT_TEACHER_MARKS = `${STORE_KEY}/INIT_TEACHER_MARKS`
export const SET_TEACHER_MARK = `${STORE_KEY}/SET_TEACHER_MARK`
export const DO_SET_TEACHER_MARK = `${STORE_KEY}/DO_SET_TEACHER_MARK`

export const UPDATE_TEST = `${STORE_KEY}/UPDATE_TEST`

export const DELETE_RESULT = `${STORE_KEY}/DELETE_RESULT`
export const SET_HOVER_TEST = `${STORE_KEY}/SET_HOVER_TEST`
export const SET_HOVER_USER = `${STORE_KEY}/SET_HOVER_USER`
export const TOGGLE_SHOWMARK = `${STORE_KEY}/SET_SHOW_MARK`
