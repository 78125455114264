import React from 'react'
import { useSelector } from 'react-redux'
import { cn } from '@bem-react/classname'
import cx from 'classnames'

import { selectThiefList, selectThiefFetchedStatus } from 'modules/Thieves'
import NewsItem, { INewsItemProps } from '../News/NewsItem'
import Fallback from 'components/Fallback'

const Thieves = () => {
  const news = cn('News')
  const data = useSelector(selectThiefList)
  const dataFetched = useSelector(selectThiefFetchedStatus)

  const hidden = (!data || !data?.length) && dataFetched

  return (
    <div className={news('Thieves', { hidden })}>
      <h2 className="SidebarInfo-Title">Чужое не брать!</h2>

      {!data &&
        Array(2)
          .fill('')
          .map((item: string, i: number) => (
            <Fallback className={cx(news('Item'), news('Fallback'))} key={i} />
          ))}

      {data &&
        data.map(
          (
            { type, date, text, href, letterCase }: INewsItemProps,
            i: number
          ) => (
            <NewsItem
              type={type}
              date={date}
              text={text}
              href={href}
              letterCase={letterCase}
              key={i}
            />
          )
        )}
    </div>
  )
}

export default Thieves
