const STORE_KEY = 'TEACHERPUPIL'

export const FETCH_PUPILS = `${STORE_KEY}/FETCH_PUPILS`

export const INIT_PUPILS = `${STORE_KEY}/INIT_PUPILS`
export const INIT_SITES = `${STORE_KEY}/INIT_SITES`
export const INIT_GROUPS = `${STORE_KEY}/INIT_GROUPS`
export const INIT_THEMES = `${STORE_KEY}/INIT_THEMES`
export const INIT_ASSIGNS = `${STORE_KEY}/INIT_ASSIGNS`

export const SET_GROUP = `${STORE_KEY}/SET_GROUP`

export const REMOVE_GROUP = `${STORE_KEY}/REMOVE_GROUP`
export const REMOVE_PUPIL = `${STORE_KEY}/REMOVE_PUPIL`
export const REMOVE_PUPIL_DONE = `${STORE_KEY}/REMOVE_PUPIL_DONE`
export const SET_ASSIGN = `${STORE_KEY}/SET_ASSIGN`
export const NEW_GROUP = `${STORE_KEY}/NEW_GROUP`
export const MOVE_GROUP = `${STORE_KEY}/MOVE_GROUP`
export const ADD_PUPIL =  `${STORE_KEY}/ADD_PUPIL`
export const TRY_PUPIL_ADD = `${STORE_KEY}/TRY_PUPIL_ADD`
export const PUPIL_RENAME = `${STORE_KEY}/PUPIL_RENAME`
export const PUPIL_MERGE = `${STORE_KEY}/PUPIL_MERGE`

export const SET_ORDER = `${STORE_KEY}/SET_ORDER`
export const SET_ERROR = `${STORE_KEY}/SET_ERROR`