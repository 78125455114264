import { isSearchEngineMode } from './'
import { RootState } from 'modules'

export const getDomain = (state: RootState): string => {
  const location = window.location
  const initialValue = 'ege.sdamgia.ru'
  let domain = initialValue
  const exam = state.exam

  if (exam) {
    domain = exam.domain!
    // const url = new URL(location.href)
    // domain = url.searchParams.get('domain') || initialValue
  } else if (isSearchEngineMode()) {
    const url = new URL(location.href)
    domain = url.searchParams.get('text') || initialValue
  } else {
    domain = location.hostname
  }

  return domain
}
