import axios from 'axios'
import { ITeacherGroupAssigns } from '..'


import { getApiKey, isTestMode } from '../../../utils'
import { ITeacherTest, ITeacherTestTheme, ITeacherTestGroup} from '../types'

 const API_KEY = getApiKey()
const ENDPONT = 'teacher/tests'

export interface IGetTestsResult {
  tests?: Array<ITeacherTest>
  themes?: Array<ITeacherTestTheme>
  groups?: Array<ITeacherTestGroup>
  groupAssign?: {[key: number]: number},
  status?: number,
  error?: string,
}

export interface IGetTestResult {
  test?: ITeacherTest,
  status?: number,
  error?: string,
}

export interface IGenericResponse {
  status?: number,
  error?: string,
}

export const getTests = async () => {
  try {
    const response = await axios.get(
      `/newapi/${ENDPONT}${API_KEY}`,
      {
        params: {},
        withCredentials: true,
      }
    )

    if (response) {
      const result: IGetTestsResult = response.data

      if (result) {
        return Promise.resolve(result)
      } else {
        console.error(`Error with /${ENDPONT}/`)
        return Promise.reject()
      }
    }
  } catch (error) {
    if (!isTestMode) {
      console.error(error)
    }
  }
}

export const getTest = async (test_id:number) => {
  try {
    const response = await axios.post(
      `/newapi/${ENDPONT}${API_KEY}`,
      {
        id: test_id
      },
      {
        withCredentials: true,
      }
    )

    if (response) {
      const result: IGetTestResult = response.data

      if (result) {
        return Promise.resolve(result)
      } else {
        console.error(`Error with /${ENDPONT}/`)
        return Promise.reject()
      }
    }
  } catch (error) {
    if (!isTestMode) {
      console.error(error)
    }
  }
}


export const doPutTheme = async (theme: ITeacherTestTheme) => {
  try {const resp = await axios.put(
    `/newapi/${ENDPONT}${API_KEY}`,
    {theme: theme},
    {
      params: {},
      withCredentials: true,
    }
  )
  if (resp) {
    const res: ITeacherTestTheme = resp.data
    return Promise.resolve(res)
  } else {
    console.error(`Error with /${ENDPONT}/`)
    return Promise.reject()
  }
} catch (e) {
  window.alert('Ошибка')
}
}
export const doPatchTest = async (test: ITeacherTest) => {
  try {  const resp = await axios.patch(
    `/newapi/${ENDPONT}${API_KEY}`,
    {test: test},
    {
      params: {},
      withCredentials: true,
    }
  )
  if (resp) {
    const res: IGenericResponse = resp.data
    if (res.status !== 0) {
      window.alert('Ошибка при сохранении ')
    }
    return Promise.resolve(res)
  } else {
    window.alert('Ошибка при сохранении')
    console.error(`Error with /${ENDPONT}/`)
    return Promise.reject()
  }
} catch (e) {
  window.alert('Ошибка')
}
}
export const doPatchTheme = async (theme: ITeacherTestTheme) => {
  try {  const resp = await axios.patch(
    `/newapi/${ENDPONT}${API_KEY}`,
    {theme: theme},
    {
      params: {},
      withCredentials: true,
    }
  )
  if (resp) {
    const res: IGenericResponse = resp.data
    if (res.status !== 0) {
      window.alert('Ошибка при сохранении')
    }
    return Promise.resolve(res)
  } else {
    window.alert('Ошибка при сохранении')
    console.error(`Error with /${ENDPONT}/`)
    return Promise.reject()
  }
} catch (e) {
  window.alert('Ошибка')
}
}
export const doPatchAssigns = async (assigns: ITeacherGroupAssigns) => {
  try {  const resp = await axios.patch(
    `/newapi/${ENDPONT}${API_KEY}`,
    {assigns: assigns},
    {
      params: {},
      withCredentials: true,
    }
  )
  if (resp) {
    const res: IGenericResponse = resp.data
    if (res.status !== 0) {
      window.alert('Ошибка при сохранении')
    }
    return Promise.resolve(res)
  } else {
    window.alert('Ошибка при сохранении')
    console.error(`Error with /${ENDPONT}/`)
    return Promise.reject()
  }
} catch (e) {
  window.alert('Ошибка')
}
}
export const doDeleteTheme = async (theme_id: number) => {
  try {  const resp = await axios.delete(
    `/newapi/${ENDPONT}${API_KEY}` + (API_KEY.indexOf('?') === -1?'?theme_id=':'&theme_id=') + theme_id,
    {
      params: {},
      withCredentials: true,
    }
  )
  if (resp) {
    const res: IGenericResponse = resp.data
    if (res.status !== 0) {
      window.alert('Ошибка при сохранении')
    }
    return Promise.resolve(res)
  } else {
    window.alert('Ошибка при сохранении')
    console.error(`Error with /${ENDPONT}/`)
    return Promise.reject()
  }
} catch (e) {
    window.alert('Ошибка')
}
}

export const markCheckAllTest = async () => {
  try {  const resp = await axios.get(
    `/teacher?a=markallall`,
    {
      params: {},
      withCredentials: true,
    }
  )
    if (resp) {
      return Promise.resolve(true)
    } else {
      return Promise.reject()
    }
  } catch (e) {
    window.alert('Ошибка')
  }
}
