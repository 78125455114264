import React from 'react'
import { cn } from '@bem-react/classname'

import Section from 'components/Section'
import Link from 'components/Link'
import './LiteratureWriting.scss'

const LiteratureWriting = () => {
  const cnLiteratureWriting = cn('LiteratureWriting')
  const links = [
    {
      title: 'Материалы для сочинения по литературе',
      url: 'https://lit-ege.sdamgia.ru/page/lit_soch',
    },
  ]

  return (
    <Section className={cnLiteratureWriting()}>
      {links.map(({ title, url }, i) => (
        <Link
          className={cnLiteratureWriting('Link', ['Link_static'])}
          href={url}
          key={i.toString()}
        >
          {title}
        </Link>
      ))}
    </Section>
  )
}

export default LiteratureWriting
