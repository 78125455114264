import React, { FC } from 'react'
import cx from 'classnames'

import { cnConstructorForm } from 'components/ConstructorForm'
import { usePartName } from './PartName.hooks'

interface IPartNameProps {
  part: string
  handleExtraPartNameClick: () => void
}

const PartName: FC<IPartNameProps> = ({ part, handleExtraPartNameClick }) => {
  const partName = usePartName(part)

  const isExtraPart = part === 'extra'

  return (
    <div
      className={cx(cnConstructorForm('Row', { label: true }), {
        'Link Link_pseudo Link_wrap': isExtraPart,
      })}
      onClick={isExtraPart ? handleExtraPartNameClick : undefined}
    >
      <div className={cnConstructorForm('Topic')}>
        {isExtraPart ? (
          <u className="Link-U Link_pseudo-U Link_wrap-U">{partName}</u>
        ) : (
          <>{partName}</>
        )}
      </div>
    </div>
  )
}

export default PartName
