import React, { FC, useState, useEffect } from 'react'
import { cn } from '@bem-react/classname'

import { ALERT_FETCH_INTERVAL } from 'constants/chat'
import AlertAuth from '../AlertAuth'

const AlertListAuth: FC = () => {
  const cnAlertListAuth = cn('AlertListAuth')
  const [visible, isVisible] = useState(false)

  useEffect(() => {
    const interval = setInterval(() => {
      isVisible(false)
      isVisible(true)
    }, ALERT_FETCH_INTERVAL)

    return () => clearInterval(interval)
  }, [])

  return <div className={cnAlertListAuth()}>{visible && <AlertAuth />}</div>
}

export default AlertListAuth
