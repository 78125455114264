import React from 'react'
import { cn } from '@bem-react/classname'

import AddContact from './AddContact'
import ContactList from './ContactList'

const Contacts = () => {
  const cnContacts = cn('Contacts')

  return (
    <div className={cnContacts()}>
      <AddContact />

      <ContactList />
    </div>
  )
}

export default Contacts
