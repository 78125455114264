import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import { selectAuthStatus } from 'modules/Auth'
import ProfileAuth from 'pages/App/components/ProfileAuth'
import ProfileLink from './ProfileLink'

interface IProfileWidgetProps {
  className?: string
}

const ProfileWidget: FC<IProfileWidgetProps> = ({ className }) => {
  const auth = useSelector(selectAuthStatus)

  return (
    <div className={className}>{auth ? <ProfileLink /> : <ProfileAuth />}</div>
  )
}

export default ProfileWidget
