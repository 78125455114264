import React, { FC, ReactNode, MouseEventHandler } from 'react'
import { cn } from '@bem-react/classname'
import cx from 'classnames'

import './MobileMenu.scss'

interface IMobileMenuProps {
  className?: string
  onClick?: MouseEventHandler<HTMLButtonElement>
  children: ReactNode
}

const MobileMenu: FC<IMobileMenuProps> = ({ className, onClick, children }) => {
  const mobileMenu = cn('MobileMenu')

  return (
    <nav className={cx(mobileMenu(), className)} onClick={onClick}>
      <span className="TextCropped">{children}</span>
    </nav>
  )
}

export default MobileMenu
