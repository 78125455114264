import React from 'react'
import { cn } from '@bem-react/classname'

import './Spinner.scss'

import websiteLogo from './assets/websiteLogo.svg'

const Spinner = () => {
  const displayName = cn('Spinner')

  return (
    <div className={displayName()}>
      <div className={displayName('Panel')}>
        <img
          className={displayName('Logo')}
          src={websiteLogo}
          alt="Крутящийся логотип Сдам ГИА"
        />
        <div className={displayName('Text')}>Загрузка...</div>
      </div>
    </div>
  )
}

export default Spinner
