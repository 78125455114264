import React, { FC, ReactNode } from 'react'
import { cn } from '@bem-react/classname'

import './AlertList.scss'

interface IAlertListProps {
  children: ReactNode
}

const AlertList: FC<IAlertListProps> = ({ children }) => {
  const cnAlertList = cn('AlertList')

  return <div className={cnAlertList()}>{children}</div>
}

export default AlertList
