import { Dispatch, SetStateAction, useCallback } from 'react'

import { removePinnedTask } from '../api'

export const useHandleRemove = (
  data: Array<number> | null,
  setData: Dispatch<SetStateAction<Array<number> | null>>
) => {
  const handleRemove = useCallback(
    async (e) => {
      const { i, id } = e.currentTarget.dataset
      const newData = [...data!]

      const [removedItem] = newData.splice(i, 1)

      setData(newData)

      try {
        await removePinnedTask(id)
        console.info('Redirect to parent')
      } catch (error) {
        const oldData = [...data!]

        oldData.splice(i, 1, removedItem)

        setData(oldData)
        console.error(error)
      }
    },
    [data, setData]
  )

  return handleRemove
}
