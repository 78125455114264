import axios from 'axios'

import { getApiKey } from 'utils'

export const getPinnedTasks = async (): Promise<Array<number>> => {

  const response = await axios(
    `/newapi/pinned-tasks${getApiKey()}`,
    {
      withCredentials: true,
    }
  )

  return response.data
}
