import React, { FC } from 'react'

import { cnChangePasswordPopup } from '../..'
import ChangePasswordPopupFakeControl from '../FakeControl'
import ChangePasswordPopupControl, {
  IChangePasswordPopupControl,
} from '../Control'
import './ChangePasswordPopup-Fieldset.scss'

interface IChangePasswordPopupFieldsetProps {
  inputList: Array<IChangePasswordPopupControl>
}

const ChangePasswordPopupFieldset: FC<IChangePasswordPopupFieldsetProps> = ({
  inputList,
}) => (
  <fieldset className={cnChangePasswordPopup('Fieldset')}>
    <ChangePasswordPopupFakeControl />

    {inputList.map((props) => (
      <ChangePasswordPopupControl {...props} key={props.name} />
    ))}
  </fieldset>
)

export default ChangePasswordPopupFieldset
