import axios from 'axios'



import { getApiKey, isTestMode } from '../../../utils'
import { ITestRaw } from '../types'


const API_KEY = getApiKey()
const ENDPONT = 'test'


export const doFetchTest = async(test_id: number) => {
    try {
      const response = await axios.get(
        `/newapi/${ENDPONT}${API_KEY}?id=${test_id}`,
        {
          params: {},
          withCredentials: true,
        }
      )
  
      if (response) {
        const result: ITestRaw = response.data
        if (result) {
          return Promise.resolve(result)
        } else {
          console.error(`Error with /${ENDPONT}/test`)
          return Promise.reject()
        }
      }
    } catch (error) {
      if (!isTestMode) {
        console.error(error)
      }
    }
    return Promise.reject()
  }