import React, { FC, ElementType, ReactNode } from 'react'
import { cn } from '@bem-react/classname'

import './Title.scss'

export interface ITitleProps {
  /**
   * Дополнительный класс
   */
  className?: string

  /**
   * Идентификатор
   */
  id?: string

  /**
   * Уровень заголовка
   * @default '2'
   */
  level?: string

  /**
   * Хтмл-атрибут для рендера заголовка
   * @default 'h2'
   */
  as?: ElementType

  /**
   * Заголовок
   */
  children: ReactNode
}

export const cnTitle = cn('Title')

/**
 * Компонент для создания заголовка
 * @param {ITitleProps} props
 */
const Title: FC<ITitleProps> = ({
  className,
  level: _level,
  as: Component = 'h2',
  ...props
}) => <Component className={cnTitle(null, [className])} {...props} />

export default Title
