import { withBemMod } from '@bem-react/core'

import { cnLink2 } from '../Link2'
import './Link2_wrap.scss'

export interface ILink2WrapProps {
  /**
   * Роль обёртки
   *
   * Увеличивает кликабельную зону ссылки
   * вокруг подчёркнутого текста
   */
  children?: any
  wrap?: boolean
}

/**
 * Модификатор, отвечающий за внешний вид кнопки
 * @param {ILink2WrapProps} props
 */
export const withWrap = withBemMod<ILink2WrapProps>(
  cnLink2(),
  {
    wrap: true,
  },
  (WrappedComponent) => ({ children, ...props }) => (
    <WrappedComponent {...props}>
      <u className={cnLink2('U', { wrap: true })}>{children}</u>
    </WrappedComponent>
  )
)
