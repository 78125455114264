import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import { AdParts, selectAds, getAdsPart } from 'modules/Ads'
// import { useHtmlSet } from './AdZone.hooks'
import InnerHTML from 'dangerously-set-html-content'

interface IAdZoneProps {
  parentClassName: string
  part: AdParts
}

const AdZone: FC<IAdZoneProps> = ({ parentClassName, part }) => {
  const cnAdZone = `${parentClassName}`
  const ads = useSelector(selectAds)
  const adsPart = getAdsPart(ads, part)

  // useHtmlSet(cnAdZone, adsPart!)

  return <InnerHTML className={cnAdZone} html={adsPart!} />
}

export default AdZone
