import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { cn } from '@bem-react/classname'

import { selectBotList, selectBotFetchedStatus } from 'modules/Bots'
import './Bots.scss'

import vkBotImg from './assets/vkBotImg.png'
import telegramBotImg from './assets/telegramBotImg.png'
import Fallback from 'components/Fallback'

export interface IBot {
  url: string
  source: string
}

const Bots: FC = () => {
  const bots = cn('Bots')
  const botList = useSelector(selectBotList)
  const botsFetched = useSelector(selectBotFetchedStatus)

  const hidden = !botList && botsFetched

  return (
    <div className={bots({ hidden })}>
      <h2 className="SidebarInfo-Title">Боты</h2>

      <div className={bots('List')}>
        {!botList &&
          Array(2)
            .fill('')
            .map((item: string, i: number) => (
              <Fallback className={bots('Item')} key={i} />
            ))}

        {botList &&
          botList.map(({ url, source }: IBot, i: number) => {
            const title =
              source === 'vk' ? 'Наш бот Вконтакте' : 'Наш бот в Телеграме'
            const img = i % 2 === 0 ? vkBotImg : telegramBotImg

            return (
              <a
                className={bots('Item')}
                href={url}
                rel="noopener noreferrer"
                title={title}
                key={url}
              >
                <img src={img} alt={title} />
              </a>
            )
          })}
      </div>
    </div>
  )
}

export default Bots
