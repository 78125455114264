import { UseFormRegister, UseFormGetValues, FieldValues } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useMobileVersion } from 'hooks'
import { usePasswordInputAttributes } from 'components/PasswordToggler'

import { IChangePasswordPopupControl } from '../Control'
import { ChangePasswordPopupAlert } from '../..'
import { selectorIsUAExam } from 'modules/Exam/selectors'

export const useInputList = (
  register: UseFormRegister<FieldValues>,
  getValues: UseFormGetValues<FieldValues>
) => {
  const isMobile = useMobileVersion()
  const size = isMobile ? 'l' : undefined
  const { type, title, toggleVisibility } = usePasswordInputAttributes()
  const isUAExam = useSelector(selectorIsUAExam)

  const passwordNewName = 'new-password'
  const passwordConfirmName = 'confirm-password'

  const passwordNewRegister = register!(passwordNewName, {
    required: isUAExam ? ChangePasswordPopupAlert.EMPTY_FORM_ERROR.ua : ChangePasswordPopupAlert.EMPTY_FORM_ERROR.rus,
  })
  const passwordConfirmRegister = register!(passwordConfirmName, {
    required: isUAExam ? ChangePasswordPopupAlert.EMPTY_FORM_ERROR.ua : ChangePasswordPopupAlert.EMPTY_FORM_ERROR.rus,
    validate: {
      matchesPreviousPassword: (passwordConfirm) => {
        const { 'new-password': passwordNew } = getValues()
        const isMatch = passwordNew === passwordConfirm

        if (!isMatch) {
          return isUAExam ? ChangePasswordPopupAlert.PASSWORDS_DONT_MATCH_ERROR.ua : ChangePasswordPopupAlert.PASSWORDS_DONT_MATCH_ERROR.rus
        }
      },
    },
  })

  const inputList: Array<IChangePasswordPopupControl> = [
    {
      label: isUAExam ? `Новий пароль` : 'Новый пароль',
      title,
      type,
      name: passwordNewName,
      size,
      autoFocus: true,
      toggleVisibility,
      register: passwordNewRegister,
    },
    {
      label: isUAExam ? `Новий пароль ще раз` : 'Новый пароль еще раз',
      title,
      type,
      name: passwordConfirmName,
      size,
      toggleVisibility,
      register: passwordConfirmRegister,
    },
  ]

  return inputList
}
