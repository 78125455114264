import { useSelector } from 'react-redux'

import { selectExam } from 'modules/Exam'

export const usePartName = (part: string) => {
  let partName = null

  const exam = useSelector(selectExam)

  switch (part) {
    case 'test':
      partName = 'Выбор ответа'
      break
    case 'short':
      partName = 'Краткий ответ'
      break
    case 'detailed':
      partName = 'Развернутый ответ'
      break
    case 'extra':
      partName = exam!.name === 'urok'?"Дополнительные главы курса":`Задания, не входящие в ${exam!.title} этого года`
      break
    default:
      partName = null
      break
  }

  return partName
}
