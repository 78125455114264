import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
import 'focus-visible'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'

import createStore from './store'
import App from './pages/App'
import './index.scss'

const store = createStore()

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>,
  document.querySelector('.Root')
)
