import { combineReducers } from 'redux'
import {
  ActionFunctionAny,
  ReduxCompatibleReducer,
  createAction,
  handleActions,
} from 'redux-actions'

import { IDialogInfo } from 'pages/App/components/Chat/ChatPopup/Dialog'
import { IMessage } from 'pages/App/components/Chat/ChatPopup/Dialog/Message'

import * as constants from './constants'

// Action Interfaces
export interface IOpenChatAction {
  type: typeof constants.OPEN_CHAT
}
export interface ICloseChatAction {
  type: typeof constants.OPEN_CHAT
}

export interface ISetChatDialogInfoAction {
  type: typeof constants.SET_CHAT_DIALOG_INFO
  payload: IDialogInfo
}
export interface IResetChatDialogInfoAction {
  type: typeof constants.RESET_CHAT_DIALOG_INFO
}

export interface IFetchChatDialogAction {
  type: typeof constants.FETCH_CHAT_DIALOG
  payload: number
}
export interface ISetChatDialogAction {
  type: typeof constants.SET_CHAT_DIALOG
  payload: Array<IMessage>
}
export interface IUpdateChatDialogAction {
  type: typeof constants.UPDATE_CHAT_DIALOG
  payload: IMessage
}
export interface IResetChatDialogAction {
  type: typeof constants.RESET_CHAT_DIALOG
}

export interface IOpenChatDialogAction {
  type: typeof constants.OPEN_CHAT_DIALOG
  payload: IDialogInfo
}
export interface ICloseChatDialogAction {
  type: typeof constants.CLOSE_CHAT_DIALOG
}

export interface ISetChatDialogOpenAction {
  type: typeof constants.SET_CHAT_DIALOG_OPEN
  payload: boolean
}

export interface ISetChatListElAction {
  type: typeof constants.SET_CHAT_LIST_EL
}

export interface ISendChatMessageAction {
  type: typeof constants.SEND_CHAT_MESSAGE
  payload: IMessage
}

export interface IFetchUnreadMessagesAction {
  type: typeof constants.FETCH_UNREAD_MESSAGES
  payload: Array<IMessage> | null
}
export interface ISetUnreadMessagesAction {
  type: typeof constants.SET_UNREAD_MESSAGES
  payload: Array<IMessage> | null
}
export interface IDeleteUnreadMessageApiAction {
  type: typeof constants.DELETE_UNREAD_MESSAGE_API
  payload: number
}
export interface IDeleteUnreadMessagesStoreAction {
  type: typeof constants.DELETE_UNREAD_MESSAGE_STORE
  payload: number
}

// Actions
export const openChat: ActionFunctionAny<IOpenChatAction> = createAction(
  constants.OPEN_CHAT
)
export const closeChat: ActionFunctionAny<ICloseChatAction> = createAction(
  constants.CLOSE_CHAT
)

export const setChatDialogInfo: ActionFunctionAny<ISetChatDialogInfoAction> = createAction(
  constants.SET_CHAT_DIALOG_INFO
)
export const resetChatDialogInfo: ActionFunctionAny<IResetChatDialogInfoAction> = createAction(
  constants.RESET_CHAT_DIALOG_INFO
)

export const fetchChatDialog: ActionFunctionAny<IFetchChatDialogAction> = createAction(
  constants.FETCH_CHAT_DIALOG
)
export const setChatDialog: ActionFunctionAny<ISetChatDialogAction> = createAction(
  constants.SET_CHAT_DIALOG
)
export const updateChatDialog: ActionFunctionAny<IUpdateChatDialogAction> = createAction(
  constants.UPDATE_CHAT_DIALOG
)
export const resetChatDialog: ActionFunctionAny<IResetChatDialogAction> = createAction(
  constants.RESET_CHAT_DIALOG
)

export const openChatDialog: ActionFunctionAny<IOpenChatDialogAction> = createAction(
  constants.OPEN_CHAT_DIALOG
)
export const closeChatDialog: ActionFunctionAny<ICloseChatDialogAction> = createAction(
  constants.CLOSE_CHAT_DIALOG
)

export const setChatDialogOpen: ActionFunctionAny<ISetChatDialogOpenAction> = createAction(
  constants.SET_CHAT_DIALOG_OPEN
)

export const setChatListEl: ActionFunctionAny<ISetChatListElAction> = createAction(
  constants.SET_CHAT_LIST_EL
)

export const sendChatMessage: ActionFunctionAny<ISendChatMessageAction> = createAction(
  constants.SEND_CHAT_MESSAGE
)

export const fetchUnreadMessages: ActionFunctionAny<IFetchUnreadMessagesAction> = createAction(
  constants.FETCH_UNREAD_MESSAGES
)
export const setUnreadMessages: ActionFunctionAny<ISetUnreadMessagesAction> = createAction(
  constants.SET_UNREAD_MESSAGES
)
export const deleteUnreadMessageApi: ActionFunctionAny<IDeleteUnreadMessageApiAction> = createAction(
  constants.DELETE_UNREAD_MESSAGE_API
)
export const deleteUnreadMessageStore: ActionFunctionAny<IDeleteUnreadMessagesStoreAction> = createAction(
  constants.DELETE_UNREAD_MESSAGE_STORE
)

// Reducers
const openInitialState = false
const open: ReduxCompatibleReducer<boolean, boolean> = handleActions(
  {
    [constants.OPEN_CHAT]: () => true,
    [constants.CLOSE_CHAT]: () => openInitialState,
  },
  openInitialState
)

const dialogInfoInitialState = null
const dialogInfo: ReduxCompatibleReducer<
  IDialogInfo | null,
  IDialogInfo | null
> = handleActions(
  {
    [constants.SET_CHAT_DIALOG_INFO]: (_state: IDialogInfo | null, action) =>
      action.payload,
    [constants.RESET_CHAT_DIALOG_INFO]: () => dialogInfoInitialState,
  },
  dialogInfoInitialState
)

const dialogInitialState = null
const dialog = (
  _state: Array<IMessage> | null = dialogInitialState,
  action: any
) => {
  switch (action.type) {
    case constants.SET_CHAT_DIALOG:
      return action.payload

    case constants.UPDATE_CHAT_DIALOG:
      const newState = _state ? [..._state] : []
      newState.push(action.payload)

      return newState

    case constants.RESET_CHAT_DIALOG:
      return null

    default:
      return _state
  }
}

const dialogOpenInitialState = false
const dialogOpen: ReduxCompatibleReducer<boolean, boolean> = handleActions(
  {
    [constants.SET_CHAT_DIALOG_OPEN]: (_state: boolean, action) =>
      action.payload,
  },
  dialogOpenInitialState
)

const listElInitialState = null
const listEl: ReduxCompatibleReducer<
  HTMLDivElement | null,
  HTMLDivElement | null
> = handleActions(
  {
    [constants.SET_CHAT_LIST_EL]: (_state: HTMLDivElement | null, action) =>
      action.payload,
  },
  listElInitialState
)

const unreadMessagesInitialState = null
const unreadMessages: ReduxCompatibleReducer<
  Array<IMessage> | null,
  Array<IMessage> | null
> = handleActions(
  {
    [constants.SET_UNREAD_MESSAGES]: (
      _state: Array<IMessage> | null,
      action
    ) => {
      /**
       * Сообщение от фронта, например об успешной смене пароля
       * Иначе пропадает при обновлении данных
       * @todo (ilyasidorchik) Отрефакторить
       */
      if (_state) {
        const firstMessage = _state[0]

        if (firstMessage?.id === -1) {
          return [firstMessage, ...action.payload!]
        }
      }

      return action.payload
    },
    [constants.DELETE_UNREAD_MESSAGE_STORE]: (
      _state: Array<IMessage> | null,
      action: any
    ) => {
      if (_state) {
        let newListMessages = _state.filter(mess => mess.id !== action.payload)
        return [...newListMessages]
      }
      return action.payload
    },
  },
  unreadMessagesInitialState
)

export const initialState: ChatDialogState = {
  open: openInitialState,
  dialogInfo: dialogInfoInitialState,
  dialog: dialogInitialState,
  dialogOpen: dialogOpenInitialState,
  listEl: listElInitialState,
  unreadMessages: unreadMessagesInitialState,
}

const chatDialog = combineReducers({
  open,
  dialogInfo,
  dialog,
  dialogOpen,
  listEl,
  unreadMessages,
})

export type ChatDialogState = ReturnType<typeof chatDialog>
export default chatDialog
