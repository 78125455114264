import { CatalogState } from "./duck";
import { IStat, ITheme } from "./types";

export const getError = (state: CatalogState) => state.error

export const getType = (state: CatalogState) => {
    let curtype = state.catalogTypes[0];
    for (let i=0; i < state.catalogTypes.length; i++){
        if (state.catalogTypes[i].name === state.activeType){
            curtype = state.catalogTypes[i]
        }
    }
    return curtype
}
export const getStats = (state: CatalogState) => {
    const catType = getType(state)
    const cat = getCatalog(state)

    if (state.userStats.hasOwnProperty(catType.name) 
        && cat
        && state.userStats[catType.name].hasOwnProperty(cat.name) 
        ){
        return state.userStats[catType.name][cat.name]
    }
    return
}

export const getStat = (state: CatalogState, theme: ITheme) => {
    const catType = getType(state)
    const cat = getCatalog(state)
    if (!state.userStats.hasOwnProperty(catType.name) 
        || !cat
        || !state.userStats[catType.name].hasOwnProperty(cat.name) ){
        return
    }
    if (theme.id && state.userStats[catType.name][cat.name].hasOwnProperty(theme.id)){
        return state.userStats[catType.name][cat.name][theme.id]
    }
    let res = {total: 0, right: 0, wrong: 0, total_a: 0, right_a: 0, wrong_a: 0} as IStat
    if (!theme.children) {
        return
    }
    for (let subcat of theme.children){
        const subres = getStat(state, subcat)
        if (!subres){
            continue
        }
        res.total += subres.total
        res.right += subres.right
        res.wrong += subres.wrong
        res.total_a! += subres.total_a || 0
        res.right_a! += subres.right_a || 0
        res.wrong_a! += subres.wrong_a || 0
    }
    return res
}

export const getCatalog = (state: CatalogState) => {
    let curtype = getType(state)
    if (curtype === undefined) {
        return
    }
    let curcat = curtype.catalogs[0]
    for (let i=0; i < curtype.catalogs.length; i++){
        if (curtype.catalogs[i].name === state.activeCatalog){
            curcat = curtype.catalogs[i]
        }
    }
    return curcat
}

export const haveTheory = (themes: ITheme[]) => {
    for (let theme of themes){
        if (theme.theory){
            return true
        }
        if (theme.children){
            const children = haveTheory(theme.children)
            if (children){
                return true
            }
        }
    }
    return false
}

export const splitdot = (oldname: string) => {
    oldname = oldname.replace('&shy;', '');
    const match = oldname.match(/^[\d.]+/)
    if (match){
        const num = match[0]
        const name = oldname.slice(num.length).trim()
        return {num, name}
    }
    return {num: undefined, name: oldname}
}
export const maxnumlen = (themes: ITheme[]) => {
    let maxlen = 0
    for (let theme of themes){
        const data = splitdot(theme.title)
        if (data.num && data.num.length * 8.5 - (data.num.match(/\./) || []).length * 3.7 > maxlen){
            maxlen = data.num.length * 8.5 - (data.num.match(/\./) || []).length * 3.7
        }
    }
    return maxlen
}
export const haveEmpty = (state: CatalogState) => {
    const cat = getCatalog(state)
    if (!cat) return false
    const haveE = (catalog: ITheme[]) => {
        for (let i = 0; i < catalog.length; i++){
            if ((catalog[i].children === undefined || catalog[i].children?.length === 0) && catalog[i].count === 0) return true
            if (catalog[i].children !== undefined){
                let chil = haveE(catalog[i].children as ITheme[])
                if (chil) return true
            }
        }
        return false
    }
    return haveE(cat.themes)
}
