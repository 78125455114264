import React, { FC, ReactNode } from 'react'

import Link from 'components/Link'

interface IOurVariantsArchiveLinkProps {
  children: ReactNode
}

const OurVariantsArchiveLink: FC<IOurVariantsArchiveLinkProps> = ({
  children,
}) => (
  <Link className="Link_static" to="/archive">
    {children}
  </Link>
)

export default OurVariantsArchiveLink
