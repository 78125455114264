import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { ALERT_FETCH_INTERVAL } from 'constants/chat'
import { fetchUnreadMessages } from 'modules/Chat'

export const useFetchData = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchUnreadMessages())

    const interval = setInterval(() => {
      dispatch(fetchUnreadMessages())
    }, ALERT_FETCH_INTERVAL)

    return () => {
      clearInterval(interval)
    }
  }, [dispatch])
}
