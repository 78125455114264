import { RootState } from 'modules'
import { ITopic } from 'components/ConstructorForm/Buttons'

export const selectTestConstructor = (state: RootState): Array<ITopic> | null =>
  state.testConstructor.data

export const selectTestConstructorWithTitleLinks = (
  state: RootState
): boolean => state.testConstructor.withTitleLinks

export const selectTestConstructorParts = (state: RootState): Array<string> =>
  state.testConstructor.parts
