import React, { FC, useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { IMessage } from 'pages/App/components/Chat/ChatPopup/Dialog/Message'
import { deleteUnreadMessageApi, openChat } from 'modules/Chat'
import { useOpenDialog } from 'pages/App/components/Chat/ChatPopup/Contacts/ContactList/hooks'
import { cnAlertListChat } from '..'
import Alert from 'components/Alert'
import './AlertListChat-Alert.scss'

interface IChatAlertProps extends IMessage {
  i: number,
  closeAllMessages: boolean,
  hideAllMessages: boolean,
}

const ChatAlert: FC<IChatAlertProps> = ({
  i,
  id,
  text,
  from,
  fromId,
  date,
  type,
  closeAllMessages,
  hideAllMessages,
}) => {
  const dispatch = useDispatch()

  const isNotLast = i !== 0
  const isAdmin = fromId === 0

  const handleOpenDialog = useOpenDialog()
  const extraClickFunction = useCallback(
    (e) => {
      dispatch(deleteUnreadMessageApi(id))
      dispatch(openChat())

      handleOpenDialog(e)
    },
    [dispatch, handleOpenDialog, id]
  )

  // const extraCloseFunction = useCallback(async (e) => {
  //   const { alertId: id } = e.currentTarget.dataset
  //
  //   await markMessageRead(parseInt(id))
  // }, [])

  return (
    <Alert
      className={cnAlertListChat('Alert', {
        view: isNotLast && 'stack',
        clickable: !isAdmin,
        type: type,
      })}
      id={id}
      fromId={fromId}
      title={from}
      text={text}
      date={date}
      extraClickFunction={isAdmin ? undefined : extraClickFunction}
      key={id}
      closeAllMessages={closeAllMessages}
      hideAllMessages={hideAllMessages}
    />
  )
}

export default ChatAlert
