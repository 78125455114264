import { CommentsState } from './duck'

export const getCommentsData = (comments: CommentsState) => comments.data

export const getCommentsCount = (comments: CommentsState) => comments.count
export const getCommentsCountOnFirstPage = (comments: CommentsState) =>
  comments.countOnFirstPage

export const getCommentsRightPage = (comments: CommentsState) =>
  comments.rightPage

export const getCommentsPreviousPagePositionMap = (comments: CommentsState) =>
  comments.previousPagePositionMap
export const getCommentsCurrentPagePosition = (comments: CommentsState) =>
  comments.currentPagePosition
export const getCommentsNextPagePosition = (comments: CommentsState) =>
  comments.nextPagePosition

export const getCommentsTotalPages = (comments: CommentsState) =>
  comments.totalPages

export const getCommentsFetchedPages = (comments: CommentsState) =>
  comments.fetchedPages
