import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { openChatDialog } from 'modules/Chat'

export const useOpenDialog = () => {
  const dispatch = useDispatch()

  const openDialog = useCallback(
    (e) => {
      const { name: to, id: toId } = e.currentTarget.dataset

      dispatch(
        openChatDialog({
          to,
          toId: parseInt(toId),
        })
      )
    },
    [dispatch]
  )

  return openDialog
}
