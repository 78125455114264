import axios from 'axios'
import { getApiKey, isTestMode } from 'utils'
import { IStatIndividualProfileFromBackEnd } from '../types'

const API_KEY = getApiKey()
const ENDPOINT = 'pupil_stats'

export const getStatIndividualProfile = async () => {
  try {
    const response = await axios({
      method: 'get',
      url: `/${ENDPOINT}${API_KEY}`,
      params: {
        ajax: 1,
        a: 'get_data',
      },
      withCredentials: true,
    })

    if (response) {
      const result: IStatIndividualProfileFromBackEnd = response.data

      if (result) {
        return Promise.resolve(result)
      } else {
        console.error(`Error with /${ENDPOINT}/`)
        return Promise.reject()
      }
    }
  } catch (error) {
    if (!isTestMode) {
      console.error(error)
    }
  }
}
