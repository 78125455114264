//import { cn } from '@bem-react/classname'
import React, { FC, useEffect } from 'react'
import Text from 'components/Text'
import './AdBlockBlockMessage.scss'

interface IAdBlockBlockMessageProps {
  reasonAdblock: string
}

const AdBlockBlockMessage: FC<IAdBlockBlockMessageProps> = ({reasonAdblock}) => {
  //const AdBlockBlockMessage = cn('AdBlockBlockMessage')

  useEffect(() => {
    document.cookie = 'Ochop9=1'
  }, [])

  useEffect(() => {
    document.body.setAttribute('data-theme', 'light');
  }, []);

  return (
    <div className={'AdBlockBlockMessage-Wrap'}>
    <div className={'AdBlockBlockMessage-BlockDetect'}>
      <h3 className={'AdBlockBlockMessage-BlockDetectTitle'} style={{textAlign: 'center'}}>
        Отключите блокировщик рекламы
      </h3>
      
        <Text className={'AdBlockBlockMessage-BlockDetectText'}>Наши пользователи сообщают, что блокировщики нарушают работоспособность
        сайта (пропадают списки аналогичных заданий и сведения об источниках заданий, 
        рассыпается папка избранного, не показываются курсы в разделе «Школа», 
        скрываются решения в разделе «Учителю», разъезжается сайт и др.).</Text>
      
      <p className={'AdBlockBlockMessage-BlockDetectText'} style={{textAlign: 'center'}}>
        Вы можете отключить блокировщик для нашего сайта.
      </p>
      <p className={'AdBlockBlockMessage-BlockDetectText'} style={{textAlign: 'center'}}>
        Это окно автоматически закроется через 20 секунд <span style={{opacity:'0.3'}}>(Тех. инф.: {reasonAdblock})</span>
      </p>
    </div>
    </div>
  )
}

export default AdBlockBlockMessage
