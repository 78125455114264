import { call, fork, put, takeLatest } from "redux-saga/effects";
import { doFetchTest } from "./api";
import { fetchTest, setError, setOptions, setProbs } from "./duck";
import { IOptions, ITestRaw } from "./types";

function* fetchTestWatcher() { yield takeLatest(fetchTest.type, fetchTestFlow) }

function* fetchTestFlow(action: ReturnType<typeof fetchTest>){
    const test: ITestRaw = yield call(doFetchTest, action.payload)
    yield put(setError(test.error?test.error:''))
    if (test.success === false) {
        return
    }
    yield put(setProbs(test.tasks!))
    yield put(setOptions({owner: test.owner, public: test.public, info: test.info} as IOptions))
}


export default function* sagas() {
    yield fork(fetchTestWatcher)
}
  