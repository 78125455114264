import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import cx from 'classnames'

import { selectNews } from 'modules/News'
import 'components/Link/_white/Link_white.scss'
import LinkWrap from 'components/Link/_wrap'
import Fallback from 'components/Fallback'

import { cnSidebarInfo } from '..'
import NewsItem, { INewsItemProps } from './NewsItem'
import './News.scss'
import { getDomain } from '../../../../../../utils'
import { selectorIsUAExam } from '../../../../../../modules/Exam'

interface INewsProps {
  className?: string
}

const News: FC<INewsProps> = ({ className }) => {
  const news = useSelector(selectNews)
  const domain = useSelector(getDomain)
  let isMNT = domain.includes('nmt') || domain.includes('zno')
  const isUAExam = useSelector(selectorIsUAExam)

  return (
    <div className={cx('News', className)}>
      <div className="News-List">
        <LinkWrap
          className={cnSidebarInfo('Title', { view: 'link' }, ['Link_static'])}
          classNameU="Link_static-U"
          href={!isMNT ? "https://sdamgia.ru/" : ''}
        >
          {isUAExam ? `Новини` : `Новости`}
        </LinkWrap>

        {!news &&
          Array(5)
            .fill('')
            .map((item: string, i: number) => (
              <Fallback className="News-Item News-Fallback" key={i} />
            ))}

        {news &&
          news.map(
            ({ type, date, href, text, html }: INewsItemProps, i: number) => (
              <NewsItem
                type={type}
                date={date}
                text={text}
                html={html}
                href={href}
                key={i}
              />
            )
          )}
      </div>
    </div>
  )
}

export default News
