import React, { FC, ReactNode } from 'react'
import { cn } from '@bem-react/classname'

import './ContentAd.scss'

interface IContentTopAdProps {
  className?: string
  children: ReactNode
}

export const cnContentAd = cn('ContentAd')

const ContentAd: FC<IContentTopAdProps> = ({ className, children }) => (
  <div className={cnContentAd(null, [className])}>{children}</div>
)

export default ContentAd
