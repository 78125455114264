import { TeacherTestState } from './duck'
import { ITeacherTest } from './types'

type SortFunction = (arg1: ITeacherTest, arg2: ITeacherTest) => number
interface Sorter {
    [key: string]: SortFunction;
}
const sorters:Sorter = {
    'id': (a:ITeacherTest, b:ITeacherTest) => b.id - a.id,
    'public': (a:ITeacherTest, b:ITeacherTest) => a.public - b.public,
    'title': (a:ITeacherTest, b:ITeacherTest) => a.title === ''?1:b.title===''?-1:a.title > b.title ? 1: -1,
    'fromDate': (a:ITeacherTest, b:ITeacherTest) => a.fromDate === null?1:b.fromDate===null?-1:a.fromDate > b.fromDate ? -1: 1,
    'toDate': (a:ITeacherTest, b:ITeacherTest) => a.toDate === null?1:b.toDate===null?-1:a.toDate > b.toDate ? -1: 1,
    'creationDate': (a:ITeacherTest, b:ITeacherTest) => a.creationDate > b.creationDate ? -1: 1,
    'count': (a:ITeacherTest, b:ITeacherTest) => b.count - a.count,
}
export const getTestsData = (comments: TeacherTestState) => comments.tests
export const getThemesData = (comments: TeacherTestState) => comments.themes.sort((a, b) => a.name > b.name? 1:-1)
export const getGroups = (comments: TeacherTestState) => comments.groups
export const getWOTheme = function (state: TeacherTestState) {
    let wtheme = Array<number>()
    wtheme = state.themes.reduce((prev, curr) => [...prev, ...curr.members!], Array<number>());
    return state.tests.filter((test) => !wtheme.includes(test.id)).sort(sorters[state.order])
}
export const getTests = (state: TeacherTestState, members: Array<number>) => state.tests.filter((test) => members.includes(test.id)).sort(sorters[state.order])
export const getTest = function (state: TeacherTestState, id: number) {
    let tests = state.tests.filter((test) => test.id === id)
    if (tests.length) {
        return tests[0]
    } else {
        return null
    }
}
export const getOrder = (comments: TeacherTestState) => comments.order
export const getTheme = function (state: TeacherTestState, id: number) {
    let tests = state.themes.filter((test) => test.id === id)
    if (tests.length) {
        return tests[0]
    } else {
        return null
    }
}
export const getGroupAssigns = function (state: TeacherTestState, id: number) {
    if (state.groupAssign[id] !== undefined){
        return state.groupAssign[id]
    } else {
        return []
    }   
}