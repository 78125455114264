import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { ClassNameFormatter } from '@bem-react/classname'

import { selectExam } from 'modules/Exam'
import ButtonVk from 'components/ButtonVk'

interface ISocialMediaProps {
  cnFormatter: ClassNameFormatter
}

const SocialMedia: FC<ISocialMediaProps> = ({ cnFormatter }) => {
  const exam = useSelector(selectExam)

  const urlExam = exam?.name === 'oge' ? `oge` : `ege`
  const text = ['oge', 'ege'].includes(exam?.name!)
    ? 'Наша группа'
    : 'Группа РЕШУ ЕГЭ'
  const url = `https://vk.com/reshu${urlExam}`
  if (exam?.name === 'nmt' || exam?.name === 'zno') {
    return <div></div>
  }

  return (
    <div className={cnFormatter('SocialMedia')}>
      <ButtonVk className={cnFormatter('ButtonVk')} url={url}>
        {text}
      </ButtonVk>
    </div>
  )
}

export default SocialMedia
