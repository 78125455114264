import React from 'react'
import { withBemMod } from '@bem-react/core'

import { ITextProps, cnText } from '../Text'
import './Text_weight_bold.scss'

export interface ITextWeightBoldProps {
  /**
   * Насыщенность текста
   */
  weight?: 'bold'
}

/**
 * Модификатор, отвечающий за насыщенность текста
 * @param {ITextWeightBoldProps} props
 */
export const withWeightBold = withBemMod<ITextWeightBoldProps, ITextProps>(
  cnText(),
  {
    weight: 'bold',
  },
  (WrappedComponent) => ({ ...props }) => <WrappedComponent {...props} as="b" />
)
