import React, { useState } from 'react'
import { cn } from '@bem-react/classname'

import Text from 'components/Text'
import DeskCard from 'pages/App/components/DeskCard'

import './SpecialChars.scss'

const cnSpecialChars = cn('SpecialChars')

const SpecialChars = () => {
  const [open, isOpen] = useState(false)

  const toggle = () => {
    isOpen(!open)
  }

  return (
    <>
      <DeskCard
        className={cnSpecialChars('DescCard')}
        open={open}
        toggleVisibility={toggle}
      >
        Спецсимволы
      </DeskCard>

      {open && (
        <Text className={cnSpecialChars('ContentCard')}>
          «» · ° − (⁎)
          <br /> &amp;nbsp;—
          <br />
          <br />
          &amp;#769; — ударе́ние
          <br />
          &amp;thinsp;
          <br /> é è ê ô ù â à
        </Text>
      )}
    </>
  )
}

export default SpecialChars
