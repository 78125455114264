//import { FETCH_AUTH_STATUS_SUCCESS } from "modules/Auth"
import { Action } from "redux-actions"
import { call, fork, put, select, takeEvery, takeLatest } from "redux-saga/effects"
import { doDeleteResult, doFetchGroups, doFetchJournal, doFetchTest, doSetTeacherMark } from "./api"
import { appendGroups, appendResults, appendTests, appendUsers, deleteResult, fetchGroups, initPortals, initTeacherMarks, setError, setGroup, setGroupMembers, setLoaded, setTeacherMark, TeacherJournalState, updateTest } from "./duck"
import { getGroup } from "./getters"
import { selectTeacherJournal } from "./selectors"
import { IGroups, IGroupSel, IPortals, ITeacherMark, ITest, ITestRaw, IUserResultRaw, IUsers, sanitizeResult, sanitizeTest } from "./types"

function* fetchPupilsWatcher() { yield takeLatest(fetchGroups.type, fetchGroupsFlow) }

function* putGroupWatcher()  { yield takeEvery(setGroup.type, fetchJournalFlow) }
function* updateTestWatcher() {yield takeEvery(updateTest.type, fetchTest)}
function* setTeacherMarkWatcher() {yield takeEvery(setTeacherMark.type, saveMark)}
function* deleteResultWatcher() {yield takeEvery(deleteResult.type, deleteResultA)}

function* deleteResultA(action: ReturnType<typeof deleteResult>){
    yield call(doDeleteResult, action.payload)
}

function* saveMark(action: ReturnType<typeof setTeacherMark>){
    const state: TeacherJournalState = yield select(selectTeacherJournal)
    yield call(doSetTeacherMark, action.payload, state.options.selectedGroup?.portal!, state.options.selectedGroup?.subject!)
}

function* fetchGroupsFlow(action: Action<null>) {
    const {portals, groups, error}: {portals: IPortals, groups: IGroups, error: string} = yield call(doFetchGroups)
    if (error){
        yield put(setError(error))
    } else {
        yield put(initPortals(portals))
        yield put(appendGroups(groups))
        yield put(setError(''))  
    }
}

function* fetchTest(action: Action<ITest>) {
    const test: ITestRaw = yield call(doFetchTest, action.payload)
    yield put(appendTests([sanitizeTest(test)]))
}

function* fetchJournalFlow(action: Action<IGroupSel|undefined>) {
    if (action.payload === undefined){
        return
    }
    const data: TeacherJournalState = yield select(selectTeacherJournal)
    const group = getGroup(data)
    if (group.loaded){
        return
    }
    const {results, users, tests, teacherMarks}: {results: IUserResultRaw[], users: IUsers, tests: ITestRaw[], teacherMarks: ITeacherMark[]} = yield call(doFetchJournal, action.payload)
    yield put(appendResults(results.map((i)=>sanitizeResult(i))))
    yield put(appendTests(tests.map((i)=>sanitizeTest(i))))
    yield put(appendUsers(users))
    yield put(setGroupMembers({group: action.payload, members: users.map((i)=>i.id)}))
    yield put(initTeacherMarks(teacherMarks))
    yield put(setLoaded(action.payload))
}

export default function* sagas() {
    yield fork(fetchPupilsWatcher)
    yield fork(putGroupWatcher)
    yield fork(updateTestWatcher)
    yield fork(setTeacherMarkWatcher)
    yield fork(deleteResultWatcher)
}
  