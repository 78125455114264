import React, { FC, ReactNode } from 'react'
import { cn } from '@bem-react/classname'

export const cnFormAlert = cn('FormAlert')

interface IFormAlert {
  className?: string
  children: ReactNode
}

const FormAlert: FC<IFormAlert> = ({ className, children }) => (
  <div className={cnFormAlert(null, [className])} role="alert">
    {children}
  </div>
)

export default FormAlert
