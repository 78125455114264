import React, { FC } from 'react'
import { cn } from '@bem-react/classname'

import { isDevMode } from 'utils'
import { AdParts } from 'modules/Ads'
import AdZone from 'pages/App/components/Ads/AdZone'

import SidebarBottomAdYandex from './SidebarBottomAdYandex'
import SidebarBottomAdGoogle from './SidebarBottomAdGoogle'

export const cnSidebarBottomAd = cn('SidebarBottomAd')

const SidebarBottomAd: FC = () => {
  const cnSidebarBottomAd = cn('SidebarBottomAd')
  const isDev = isDevMode()

  return (
    <div className={cnSidebarBottomAd()}>
      {isDev ? (
        <>
          <SidebarBottomAdYandex />
          <SidebarBottomAdGoogle />
        </>
      ) : (
        <AdZone parentClassName={cnSidebarBottomAd()} part={AdParts.LEFT} />
      )}
    </div>
  )
}

export default SidebarBottomAd
